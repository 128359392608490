import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

import wall1 from "../../images/metamask_wallet.png";
import wall2 from "../../images/connect_wallet.png";
import { Scrollbars } from 'react-custom-scrollbars';



class Leveragemodal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            leverageModal: true,

        };
    }



    render() {


        const { leverageModal } = this.state


        return (
            <Modal className="wallet-modal" show={leverageModal} centered size="sm">
                <Modal.Header className="pb-0">
                    <div>
                        <h3 className="sec-head ">Add Leverage</h3>
                    </div>
                    <button type="button" class="close" onClick={() => this.props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

                </Modal.Header>
                <Modal.Body className="select-wallet pt-0">
                    <Scrollbars className="modal_scroll_sm" style={{ height: 100 }}>
                        <form className="dashboard_form pad_right_das mt-4 mb-4">
                            <label>Multiplier:</label>
                            <div className="input-group mb-3 input_grp_dash mt-2">
                                <input type="text" className="form-control border_full" placeholder="Enter Multiplier" aria-label="multiplier" aria-describedby="basic-addon2" />

                            </div>

                            <label>Management Fee:</label>
                            <div className="input-group mb-3 input_grp_dash mt-2">
                                <input type="password" className="form-control border_full" placeholder="Enter Management Fee" aria-label="managementfee" aria-describedby="basic-addon2" />

                            </div>
                            <div className='text-center mt-5'>
                                <button className="get-started-btn text-uppercase">
                                    Add</button>
                            </div>
                        </form>
                    </Scrollbars>

                </Modal.Body>
            </Modal>
        )
    }

}


export default Leveragemodal