import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import { Modal } from 'react-bootstrap';

import modalimg from "../images/anouncement.png";


import { Scrollbars } from 'react-custom-scrollbars';



const Announcementmodal2 = (props) => {

    const [announcementModal2, setAnnouncementModal2] = useState(true)

    return (
        <div>


            <div id="announcemodal2" className="wallet-modal" show={announcementModal2} centered size="lg" dialogClassName={!announcementModal2 ? "modal-dialog  modal-90w dark-bg fade modal_announcement modal-lg modal-dialog-centered" : "modal-dialog  modal-lg modal-dialog-centered modal-90w dark-bg showfull modal_announcement"}>
                <Modal.Header className="pb-0">
                    <button type="button" className="close" onClick={() => props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
                </Modal.Header>
                <Modal.Body className="select-wallet pt-0 pb-3 px-0">
                    <Scrollbars className="modal_scroll" style={{ height: 100 }}>
                        <div className='pr-2 pt-2 pb-2'>
                            {/* <div className='text-center'>
                                    <img src={modalimg} className="img-fluid modal_img" />
                                </div>
                                <div className='text-center mt-3'>
                                    <button className="get-started-btn btn_head mb-4 ml-3">
                                        Cookies</button>
                                </div> */}
                            <div className='px-3 px-md-5'>
                                <p className='announcement_text announcement_text1'>Welcome to cheeseburgerdefi.com! In order to provide a more relevant experience for you, we use cookies to enable some website functionality. Cookies help us see which articles most interest you; allow you to easily share articles on social media; permit us to deliver content, jobs and ads tailored to your interests and locations; and provide many other site benefits. For more information, please review our <a href='/'>Cookies Policy</a> and <a href='/'> Privacy Statement.</a>  </p>
                                {/* <p className='announcement_text announcement_text1'>Ut enim ad minim veniam, laboris nisi ut aliquip ex ea commodo consequat. </p> */}
                            </div>

                            <div>

                            </div>

                            {/* <div className='d-flex mb-2' style={{ justifyContent:"center" }}>
                                            <button type="button" className='btn btn_red mr-3 accept' onClick={() => this.props.onDismiss()} aria-hidden="true">Accept</button>
                                            <button type="button" className='btn btn_red mr-3' onClick={() => this.props.onDismiss()} aria-hidden="true">Reject</button>
                                        </div> */}
                            <div className='d-flex mb-0' style={{ justifyContent: "space-evenly" }}>
                                <p><span className='acceptclick' onClick={() => props.onDismiss()} aria-hidden="true">Accept</span> <span className='rejectclick' onClick={() => props.onDismiss()} aria-hidden="true">Reject</span></p>
                                {/* <p className='acceptclick' onClick={() => this.props.onDismiss()} aria-hidden="true">Accept</p>
                                    <p className='rejectclick' onClick={() => this.props.onDismiss()} aria-hidden="true">Reject</p> */}
                            </div>
                        </div>
                    </Scrollbars>
                </Modal.Body>

            </div>


        </div>
    )
}



export default Announcementmodal2