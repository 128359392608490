
import React, { Component } from 'react';
import { useContext, useEffect, useState } from "react";
import { Modal } from 'react-bootstrap';

import modalimg from "../../images/doll.png";
import usdt from "../../images/usdt.png"

import { Scrollbars } from 'react-custom-scrollbars';

class Summarymodal extends Component
{
    constructor(props) {
        super(props);
        this.state = {          
            summaryModal: true
           
        };
    }


    componentDidMount(){
        console.log("this.propsde",this.props);
    }
    render() {
        
  
        const {summaryModal} = this.state

        
      return (
<Modal className="wallet-modal" show={summaryModal} centered size="sm" dialogClassName= {!summaryModal ? "modal-90w dark-bg fade " : "modal-90w dark-bg showfull"}>
                        <Modal.Header className="pb-0">
                          
                            <button type="button" className="close depositcoinclose" onClick={() => this.props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

                        </Modal.Header>
                        <Modal.Body className="select-wallet pt-0 pb-5 px-0">
                        <Scrollbars className="modal_scroll" style={{ height: 100 }}>
                            <div className='pr-2'>
                            <div className='text-center'>
                        <img src={modalimg} className="img-fluid modal_img" />
                        </div>
                        <div className='text-center mt-3'>
                        <button className="get-started-btn btn_head mb-4 ml-3">
                        Summary</button>
                        </div>
                        <form className='px-3 px-md-3 mt-4 pb-5'>
                            <div className='row'>
                                <div className='col-7'>
                            <label className='text_yellow text-left'>Market Price</label>

                                </div>
                                <div className='col-5 pl-0'>
                            <label className='text-left text-white'>328.72 per WBNB</label>
                            </div>
                            <div className='col-7'>
                            <label className='text_yellow text-left'>Commitment</label>

                                </div>
                                <div className='col-5 pl-0'>
                            <label className='text-left text-white'>10</label>
                            </div>
                            <div className='col-7'>
                            <label className='text_yellow text-left'>Liquidation Fee</label>

                                </div>
                                <div className='col-5 pl-0'>
                            <label className='text-left text-white'>1.93 BUSD</label>
                            </div>
                            </div>
                            <hr className='hr_grey my-3'/>

                            <div className='row'>
                                <div className='col-7'>
                            <label className='text_yellow text-left'>Total Size</label>

                                </div>
                                <div className='col-5 pl-0'>
                            <label className='text-left text-white'>16.13 per WBNB</label>
                            </div>
                            <div className='col-7'>
                            <label className='text_yellow text-left'>Pool Supply</label>

                                </div>
                                <div className='col-5 pl-0'>
                            <label className='text-left text-white'>10585.675 BUSD</label>
                            </div>
                            <div className='col-7'>
                            <label className='text_yellow text-left'>Leverage</label>

                                </div>
                                <div className='col-5 pl-0'>
                            <label className='text-left text-white'>2x</label>
                            </div>
                            </div>
                            <hr className='hr_grey my-3'/>


                            <div className='row'>
                                <div className='col-7'>
                            <label className='text_yellow text-left'>Stop Loss (Leveraged)</label>

                                </div>
                                <div className='col-5 pl-0'>
                            <label className='text-left text-white'>0%</label>
                            </div>
                            <div className='col-7'>
                            <label className='text_yellow text-left'>Take Profit (Leveraged)</label>

                                </div>
                                <div className='col-5 pl-0'>
                            <label className='text-left text-white'>0%</label>
                            </div>
                            <div className='col-7'>
                            <label className='text_yellow text-left'>Entry Price</label>

                                </div>
                                <div className='col-5 pl-0'>
                            <label className='text-left text-white'>330.04</label>
                            </div>
                            <div className='col-7'>
                            <label className='text_yellow text-left'>Est. Liquidation Price</label>

                                </div>
                                <div className='col-5 pl-0'>
                            <label className='text-left text-white'>197.83</label>
                            </div>
                            <div className='col-7'>
                            <label className='text_yellow text-left'>Est. Price Impact</label>

                                </div>
                                <div className='col-5 pl-0'>
                            <label className='text-left text-white'>0%</label>
                            </div>
                            </div>
                            <hr className='hr_grey my-3'/>
                            <div className='row'>
                                <div className='col-7'>
                            <label className='text_yellow text-left'>Fees</label>

                                </div>
                                <div className='col-5 pl-0'>
                            <label className='text-left text-white'>0.03</label>
                            </div>
                            </div>

                            <div className='alert_box mt-3'>
                                <p className='mb-0'>Please note that when opening this position, additional fees from the CheeseBurger Defi. AMM can occur within the transaction and deduct from the total position, which will reflect in the starting PnL.</p>
                            </div>

                            <div className='text-center mt-4'>
                            <button className='get-started-btn text-uppercase ml-3' type="button">
                                    Confirm and Open Position</button>
                            </div>
                            <div className='text-center mt-3'>
                                <a href="#" target="_blank" className='btn_trans_forgot'>Mistake found? Revise input now.</a>
                                </div>
                          
                           
                         
                        </form>
                        </div>
                           </Scrollbars>
                        </Modal.Body>
                    </Modal>
      )
    }

}


export default Summarymodal