// Packages
import axios from "axios";

// Files
import {Back_Route} from "../../config/env";
import { ORDER_PLACING,WALLETS ,OPEN_ORDERS,CANCEL_ORDERS, POSITION_ORDERS, WALLET_ADDRESS} from "../../redux/action";

// Lib
import { toastAlert } from "../../lib/toast";

export const getAssetData = async (payload, dispatch) => {
    try {
        const getData = await axios.post(`${Back_Route}/user/assets`, payload);
        console.log('getAssetData', getData)
        dispatch({
            type: WALLETS,
            payload: getData.data.data,
        })
        return getData.data.data
    } catch (e) {
        console.log("getAssetData_err", e)
    }
}


export const getPerpatualPairsData = async()=> {
    try {
        const getData = await axios.get(`${Back_Route}/user/getPerpatualPairs`);
        console.log('getPerpatualPairsData',getData)
        return getData.data.data
    } catch (e) {
        console.log("getAssetData_err",e)
    }
}

export const createUserWallet = async (payload) => {
    try {
        const getData = await axios.post(`${Back_Route}/user/createWallet`, payload);
        console.log('createUserWallet', getData)
        return getData.data.data
    } catch (e) {
        console.log("createUserWallet_err", e)
    }
}

/** Placing the orders in MARKET AND LIMIT */
export const orderPlacing = async (payload, dispatch) => {
    try {
        const placeOrder = await axios.post(`${Back_Route}/user/orderPlacing`, payload);
        if (placeOrder.status == 200) {
            console.log(placeOrder, "orderPlacing")
            dispatch({
                type: ORDER_PLACING,
                payload: placeOrder.data,
            });
            if (payload.ordertype == 'limit') {
                // toastAlert('success', placeOrder.data.message)
            }
            console.log('marketOrderPlacing', placeOrder.data)
            return placeOrder.data
        }
        console.log('placeOrder_0987657', placeOrder);
        return placeOrder
    } catch (e) {
        toastAlert('error', e.response.data.message)
        console.log("marketOrderPlacing_err", e)
        return e.response.data
    }
}

/** Place the Market orders after Metamask call succeed */
export const marketPlacing = async (payload, dispatch) => {
    try {
        console.log("Place the Market orders after Metamask call succeed", payload)
        const placeOrder = await axios.post(`${Back_Route}/user/marketPlacing`, payload);
        if (placeOrder.status == 200) {
            console.log(placeOrder, "orderPlacing")
            dispatch({
                type: ORDER_PLACING,
                payload: placeOrder.data,
            })
            if (placeOrder.data.message != "Orders Updated") {
                toastAlert('success', placeOrder.data.message)
            }
            console.log('marketOrderPlacing', placeOrder.data)
            return placeOrder.data
        }
        console.log('placeOrder_0987657', placeOrder);
        return placeOrder
    } catch (e) {
        toastAlert('error', e.response.data.message)
        console.log("marketOrderPlacing_err", e)
    }
}

export const getOpenOrder = async (payload, dispatch) => {
    try {
        const ordersData = await axios.post(`${Back_Route}/user/getOrderList`, payload);

        if (ordersData.status == 200) {
            dispatch({
                type: OPEN_ORDERS,
                payload: ordersData.data,
            })
            console.log('getOpenOrder', ordersData)
            return ordersData.data
        } else {
            dispatch({
                type: OPEN_ORDERS,
                payload: ordersData.data
            })
        }
    } catch (e) {
        console.log("getOpenOrder_err", e)
    }
}

export const cancelOrder = async (payload, dispatch) => {
    try {
        const cancel = await axios.post(`${Back_Route}/user/cancelOrder`, payload)
            .then((res) => {
                dispatch({
                    type: CANCEL_ORDERS,
                    payload: res.data,
                })
                console.log("cancelOrdercancelOrdercancelOrder_cancel", res)
                toastAlert('success', res.data.message)
                return res.data.data
            })
            .catch(err => {
                dispatch({
                    type: CANCEL_ORDERS,
                    payload: err.response.data
                })
                toastAlert('error', err.response.data)
            }
            );
    } catch (e) {
        console.log("cancelOrder_err", e)
    }
}

/** Get Close orderLists */
export const closeOrder = async (payload) => {
    try {
        const closedPosition = await axios.post(`${Back_Route}/user/closeOrder`, payload);
        console.log("closedPosition_old",closedPosition)
        if (closedPosition.status == 200) {
            // toastAlert('success', closedPosition.data.message)
            return closedPosition.data
        }
    } catch (e) {
        toastAlert('error', e.response.data.message)
        console.log("closeOrder_err", e)
        return e.response.data
    }
}

export const getPositionOpenOrder = async (payload, dispatch) => {
    try {
        const ordersData = await axios.post(`${Back_Route}/user/getPositionOrderList`, payload);
        console.log('getPositionOpenOrder', ordersData)

        if (ordersData.status == 200) {
            dispatch({
                type: POSITION_ORDERS,
                payload: ordersData.data,
            })
            return ordersData.data
        } else {
            dispatch({
                type: OPEN_ORDERS,
                payload: ordersData.data
            })
        }
    } catch (e) {
        console.log("getPositionOpenOrder_err", e)
    }
}

export const getHistoryData = async (payload) => {
    try {
        const historysData = await axios.post(`${Back_Route}/user/getHistory`, payload);
        console.log('historysData', historysData)

        if (historysData.status == 200) {
            // dispatch({
            //     type: POSITION_ORDERS,
            //     payload: ordersData.data,
            // })
            return historysData.data
        } else {
            // dispatch({
            //     type: OPEN_ORDERS,
            //     payload: ordersData.data
            // })
            return false
        }
    } catch (e) {
        console.log("getHistoryData_err", e)
    }
}

/** Close The Position Orders after contract call succeed*/
export const closePositionOrder = async (payload) => {
    try {
        const closePosition = await axios.post(`${Back_Route}/user/positionCloseOrder`, payload);
        if (closePosition.status == 200) {
            toastAlert('success', closePosition.data.message)
        }
    } catch (e) {
        toastAlert('error', e.response.data.message)
        console.log("closePositionOrder_err", e)
    }
}



export const getOrderBook = async (payload) => {
    try {
        const orderBook = await axios.post(`${Back_Route}/user/orderBook`, payload);
        if (orderBook.status == 200) {
            return orderBook.data
        }
    } catch (e) {
        console.log("getOrderBook_err", e)
    }
}
