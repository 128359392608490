import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import { Modal, Table, Dropdown, Form } from 'react-bootstrap';

import modalimg from "../../images/doll.png";
import usdt from "../../images/usdt.png"

import { Scrollbars } from 'react-custom-scrollbars';
import { updatePair } from '../action/admin';






const Viewmodal = (props) => {

    const [viewModal, SetviewModal] = useState(true)
    const [leverage, Setleverage] = useState(0)
    const [fee, Setfee] = useState(0)
    const [slippage, SetSlippage] = useState(0)

    useEffect(() => {
        Setleverage(props?.data?.leverage)
        Setfee(props?.data?.maintananceMargin)
        SetSlippage(props?.data?.slippage)
    }, [props])

    const onsubmit = async () => {
        const data = {
            leverage: leverage,
            maintananceMargin: fee,
            slippage: slippage,
            id: props?.data?._id
        }
        const update = await updatePair(data);
        if(update){
            props.onDismiss()
        }
    }

    return (
        <div className='did1'>
            <Modal left className="orderbook-modal" show={viewModal} centered size="lg" dialogClassName={!viewModal ? "modal-90w modal-lg orderbookleft left dark-bg fade" : "modal-90w modal-lg orderbookleft left dark-bg showfull"}>
                <Modal.Header className="pb-0">

                    <button type="button" className="close" onClick={() => props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

                </Modal.Header>
                <Modal.Body className="select-wallet orderbook pt-5 pb-5 px-0">
                    <Scrollbars className="modal_scroll" style={{ height: 100 }}>
                        <div className='pr-2'>

                            <div className='text-center mt-3'>
                                <button className="get-started-btn text-uppercase btn_head mb-4">
                                    View</button>
                                <p className='orderbook-p'>Lorem Ipsum is simply dummy text of the printing</p>
                            </div>

                            <Form className='longs'>
                                <p class="yeelo_text_albel mb-3">Leverage (X)</p>
                                <Form.Group className="mb-3 form1" controlId="formBasicEmail">
                                    <Form.Control type="text" placeholder="Enter Leverage" value={leverage} onChange={(e) => { Setleverage(e.target.value) }} />
                                </Form.Group>

                                <p class="yeelo_text_albel mb-3">Commitment (%)</p>
                                <Form.Group className="mb-3 form1" controlId="formBasicEmail">
                                    <Form.Control type="text" placeholder="Enter Commitment" value={fee} onChange={(e) => { Setfee(e.target.value) }} />
                                </Form.Group>

                                <p class="yeelo_text_albel mb-3">Slippage Tolerance (%)</p>
                                <Form.Group className="mb-3 form1" controlId="formBasicEmail">
                                    <Form.Control type="text" placeholder="Enter Slippage Tolerance" value={slippage} onChange={(e) => { SetSlippage(e.target.value) }} />
                                </Form.Group>

                                <div className='text-center mt-3'>
                                    <button type="button" className="calculate btn btn-warning" onClick={() => { onsubmit() }}>Submit</button>
                                </div>
                            </Form>

                        </div>
                    </Scrollbars>
                </Modal.Body>
            </Modal>



        </div>
    )
}

// }


export default Viewmodal