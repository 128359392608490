import React, { Component, useState } from 'react';
import { Modal } from 'react-bootstrap';

import wall1 from "../../images/metamask_wallet.png";
import wall2 from "../../images/connect_wallet.png";

import { Scrollbars } from 'react-custom-scrollbars';
import { editPoolDetails } from '../action/admin';

const PoolModel = (props) => {
    const [featureModal, setFeatureModal] = useState(true);
    const [address, setAddress] = useState(props?.data?.address);
    console.log('props?.address', props.data);


    const editPool = async () => {
        try {
            const edit = await editPoolDetails({ chainid: props.data.chainId, address: address });
            if (edit.status) {
                props.onDismiss()
            }
        } catch (e) {
            console.log("editPoolDetails_err", e);
        }
    }

    return (
        <Modal className="wallet-modal" show={featureModal} centered size="sm" id="feature_modal">
            <Modal.Header className="pb-0">
                <div>
                    <h3 className="sec-head ">Edit PoolInfo</h3>
                </div>
                <button type="button" class="close" onClick={() => props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

            </Modal.Header>
            <Modal.Body className="select-wallet pt-0">
                <div className='modal_scrollerer'>
                    <div className="dashboard_form mt-4 mb-4 pad_right_das">
                        <label>Pool Address:</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">
                            <input type="text" className="form-control border_full" placeholder="Enter Feature Title" value={address} onChange={(e) => {setAddress(e.target.value)}} aria-label="featuretitle" aria-describedby="basic-addon2" />
                        </div>

                        {/* <label>Feature Description:</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">
                            <textarea rows="5" className="form-control border_full" placeholder="Enter Feature Description" aria-label="featuredescription" aria-describedby="basic-addon2" />

                        </div> */}

                        {/* <label>Add Image:</label>
                        <div className="custom-file mt-2">
                            <input type="file" className="custom-file-input" id="customFile" />
                            <label className="custom-file-label" for="customFile">Choose file</label>
                        </div> */}

                        <div className='text-center mt-5 pb-3'>
                            <button className="get-started-btn text-uppercase" onClick={()=> { editPool()}}>
                                update</button>
                        </div>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    )
}


export default PoolModel