import axios from "axios";
import * as config from '../config/env'



export const priceConversion = async(currency,convertcurrency) => {
    try {
        const value = await axios.get(`https://min-api.cryptocompare.com/data/price?fsym=${currency}&tsyms=${convertcurrency}&api_key=${config.CRYPTOCOMPARE}`);
        // console.log("priceConversion_value",value, '--', currency,convertcurrency)
        if(value.data?.[`${convertcurrency}`]){
        //  console.log("marketPriceUSD : ",value.data["USDT"])
         return value.data[`${convertcurrency}`]
        }
    } catch (err) {
        console.log(err, "priceConversion__err")
    }
}


export const liquidityCalculation = async (type, price, maintMargin, leverage) => {
    try {
        let liquidityPrice = 0;
        if (type == 'long') {
            // liquidityPrice = price - (parseFloat(price) * parseFloat(margin) * parseFloat(leverage))
            liquidityPrice = parseFloat(price) - ((parseFloat(price) * (parseFloat(maintMargin) / 100)) / parseFloat(leverage))
        } else {
            // liquidityPrice = price + (parseFloat(price) * parseFloat(margin) * parseFloat(leverage))
            liquidityPrice = parseFloat(price) + ((parseFloat(price) * (parseFloat(maintMargin) / 100)) / parseFloat(leverage))
        }
        // console.log("liquidityCalculation", type, price, maintMargin, leverage, { liquidityPrice: liquidityPrice })
        return liquidityPrice
    } catch (err) {
        console.log(err, "liquidityCalculation__err")
    }
}