import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import '../css/footer.css';
import footerlogo from "../images/logo.png"


const Footer = (props) => 
{ 
   
		return (
           <div>
              
   <footer className="footer bg-blue">
      <a id="button">
         <img src="images/arrow-up.svg" alt="" />
      </a>
      <div className="footer-top">
         <div className="container">
            <div className="row">
               <div className="col-12 mb-4">
               

                  <div className="social-link">
                     <ul>
                        <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                        <li><a href="#"><i className="fa fa-instagram"></i></a></li>
                        <li><a href="#"><i className="fa fa-paper-plane"></i></a></li>
                        <li><a href="#"><i className="fa fa-twitter"></i></a></li>

                     </ul>
                  </div>

               </div>
            </div>
         </div>
      </div>
      <div className="footer-bottom">
         <div className="container">
            <div className="footer-bottom-inner pb-5">
               <div className="row">
                  <div className="col-12">
                     <div className="copyright-text text-center">
                        <p className="m-0">© Copyright 2022. All Rights Reserved.</p>
                     </div>
                  </div>
                 
               </div>
            </div>
         </div>
      </div>
   </footer>
           </div>
        );
    }

export default Footer