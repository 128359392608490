import React, { Component, useContext, useEffect, useState } from 'react';
import '../../css/styles.css';
import { Link, withRouter } from 'react-router-dom';
import { Col, Row, Container, Nav, Dropdown, Tabs, Tab, Table, Button } from 'react-bootstrap';
// import Diamond from "../../images/diamond.PNG";
import Diamond from "../../images/binance.png";
import io from "socket.io-client";
import { SOCKEt_URL } from '../../config/env'
import { connect, useSelector } from "react-redux";
import { getOpenOrder, cancelOrder } from '../action/user';
import PropTypes from "prop-types";
import toast, { Toaster } from 'react-hot-toast';
import SocketContext from '../context/SocketContext';
import { useDispatch } from 'react-redux';


const Pending = () => {
  const dispatch = useDispatch()

  const {wallets} = useSelector(state => state.Auth)
  const socketContext = useContext(SocketContext)
  const [OrderData, setOrderData] = useState([])
  const [isProfit, setIsprofit] = useState(true);

  useEffect(() => {
    if (wallets && wallets.length > 0) {
      getorder()
      socketContext.socket.on("openOrder", (data) => {
        setOrderData(data)
      })
    } else {
      setOrderData([])
    }
  }, [wallets])
  useEffect(()=> {
    socketContext.socket.on("openOrder", (data) => {
      setOrderData(data)
    })
  },[])

  
  const getorder = async () => {
    if (localStorage.getItem("accountInfo")) {
      const orders = await getOpenOrder({ walletAddress: localStorage.getItem("accountInfo")},dispatch);
      // console.log("getordergetordergetorder", orders)
      setOrderData(orders)
    }
  }  
 
  // componentWillReceiveProps(nextprops) {
  //   // this.socket.emit('CREATEROOM', localStorage.getItem("accountInfo"))
  //   if (nextprops?.Auth?.walletAddress != "") {
  //     this.setState({ walletModal: false });
  //   }
  //   if (nextprops?.trade?.openOrder?.length > 0) {
  //     console.log(nextprops.trade.openOrder, "nextprops")
  //     this.setState({ OrderData: nextprops.trade.openOrder })
  //   }
  //   console.log('cancelOrdercancelOrdercancelOrder',nextprops.trade.cancelOrder);
  //   if(nextprops.trade.cancelOrder.status){
  //       if (nextprops.trade.cancelOrder.status) {
  //           console.log(nextprops, "nextprops")
  //           toast.success(nextprops.trade.cancelOrder.message)
  //       } else {
  //           toast.error(nextprops.trade.cancelOrder.message)
  //       }
  //   }
  // }

  const cancelOrders = async (data) => {
    // console.log(data,'cancel_dateaaaa')
    const cancel = await cancelOrder({ pairId: data.pairId, orderId: data._id},dispatch);
    console.log("cancelOrders_cancel",cancel)
  }

    return (

      <div>
        <div className='tables order_table_new'>
          <Table responsive>
            {/* <thead>
          <tr>
          <th>Contact</th>
            <th>QTY</th>
            <th>Value</th>
            <th>Entry Price</th>
            <th>Liq.Price</th>
            <th>Position margin</th>
            <th>Unrealized P&L (%)</th>
            <th>Daily realized P&L (%)</th>
            <th>TP/SL</th>
            <th>Closed by</th>
          </tr>
        </thead> */}
            <tbody>
              <tr className='colors'>
                <td>Contract</td>
                <td>Quantity</td>
                <td>Price</td>
                <td className='tab_filed_th'>Filed/Remaining</td>
                <td>TP/SL</td>
                <td>Type</td>
                <td>Status</td>
                <td>Order</td>
                <td>Order time</td>
                <td>Action</td>
              </tr>

              {OrderData && OrderData.length > 0 ?
                OrderData.map((val, i) => {
                  // { console.log(val, "OrderData") }
                  return (
                    <tr className='colors1'>
                      <td>
                        {/* <span>
                          <img src={Diamond} className='diamond' alt='diamond' />
                          </span>  */}
                          <span> {val.symbol}</span>
                          </td>
                      <td>{val.quantity.toFixed(8)}</td>
                          <td>
                            {/* <div className='felx_icon_tetx_div'> */}
                      {/* <span className='prof_loss_text'>
                        {
                          isProfit?
                        <i className='fa fa-long-arrow-up' />
                          :
                          <i className='fa fa-long-arrow-down' />

                        }

                        </span> */}

                      <span>{val.price}</span>
                      {/* </div> */}
                      </td>


                      <td>{val.executeQuantity.toFixed(8)}/{val.balanceQuantity.toFixed(8)}</td>
                      <td>{val.takePrice} / {val.stopPrice}</td>
                      <td>{val.type}</td>
                      <td>{val.status}</td>
                      <td>{val.orderId}</td>
                      <td>{new Date(val.createdDate).toLocaleDateString()}</td>
                      <td> <Button variant="outline-warning" onClick={()=> {cancelOrders(val)}}>Cancel</Button>{' '}</td>
                    </tr>
                  )
                })
                :
                <td colSpan={10}>
                <p className='text-center no_font_text'>No orders found</p>
                </td>
              }
              {/* <td><span><img src={Diamond} className='diamond' alt='diamond' /></span> <span> BNB</span></td>
                <td>1x</td>
                <td>$200</td>
                <td>0.00045/2.0.00</td>
                <td>-/-</td>
                <td>Limit</td>
                <td>New</td>
                <td>12345567</td>
                <td>19/4/2023 10:50:7</td>
                <td> <Button variant="outline-warning">Cancel</Button>{' '}</td> */}

            </tbody>
          </Table>
        </div>

      </div>

    );
  }
// Pending.propTypes = {
//   Auth: PropTypes.object.isRequired,
//   trade: PropTypes.object.isRequired,
//   getOpenOrder: PropTypes.func.isRequired,
//   cancelOrder : PropTypes.func.isRequired
// }
// const mapStateToProps = state => ({
//   Auth: state.Auth,
//   trade: state.trade
// });

// export default connect(
//   mapStateToProps,
//   {
//     getOpenOrder,
//     cancelOrder
//   }
// )(withRouter(Pending));
export default Pending