import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Tabs, Tab } from 'react-bootstrap';
import '../../css/styles.css';

import Adminheader from '../Adminheader';
import Adminsidebar from '../Adminsidebar';
import Footer from '../Footer';


import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';

import { data2 } from "./data";
import { addUserWhitelist, getUsersData } from '../action/admin';

const Users = () => {




  const onDismissusers = () => {
    setUsersModal(false)
  }
  const [usersModal, setUsersModal] = useState(true)
  const [usersData, setUsersData] = useState([])

  const addWhistListUser = async (address, status) => {
    try {
      if (address != null && address != undefined && address != "") {
        const add = await addUserWhitelist({ walletAddress: address, status: !status });
        if(add){
          users()
        }
      }
    } catch (e) {
      console.log("addWhistListUser_err", e);
    }
  }


  const columns2 = [
    {
      name: "S.no",
      selector: "id",
      sortable: true,
      cell: (record, index) => index + 1
    },
    {
      name: "Address",
      selector: "walletAddress",
      sortable: true
    },
    {
      name: "Whitelisted",
      selector: "whitelisted",
      sortable: true,
      cell: (record, index) => record?.whitelisted?.toString()?.toUpperCase()
    },
    {
      name: "Action",
      selector: "action",
      sortable: true,
      cell: (records) => {
        return (
          records && records?.whitelisted == false ?
            <button className="get-started-btn text-uppercase" onClick={() => { addWhistListUser(records.walletAddress, records.whitelisted) }}>
              Add</button>
            :
            <button className="get-started-btn text-uppercase" onClick={() => { addWhistListUser(records.walletAddress, records.whitelisted) }}>
              Remove</button>
        )
      }
    }
  ];

  const users = async () => {
    try {
      const getData = await getUsersData();
      console.log("getDatagetDatagetData", getData);
      setUsersData(getData)
    } catch (e) {
      console.log("users", users)
    }
  }

  useEffect(() => {
    users()
  }, [])
  return (
    <div>
      <Adminheader />
      <div className='row main_view mx-0 w-100'>
        <div className='col-1 col_sidebar'>
          <Adminsidebar />
        </div>
        <div className='col-11 main_dash_sec pb-4 col_11_admin'>
          <div className='row'>
            <div className='col-12 col-md-10 col-lg-9 mx-auto'>
              <div className='mt_main_sec text-center'>
                <button type="button" className="get-started-btn btn_head mb-4 ml-3">
                  View Users</button>
              </div>
              <div className='mt_main_sec'>
                <div className='mt-3 pb-5'>
                  <div className='table-responsive'>
                    {
                      usersData && usersData.length > 0 ?
                        <DataTable className='table table_user table_admin mb-0'
                          noHeader
                          sortable={false}
                          columns={columns2}
                          data={usersData}
                          pagination
                        /> : <p className='no_data_texter text-center'>No data</p>
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default Users