
import toast, { Toaster } from 'react-hot-toast';

export const toastAlert = async (errorType, message) => {
    try {
        if (errorType === 'error') {
            toast.error(message,
                {
                    duration: 2000,
                    style: {
                        minWidth: '300px',
                        minHeight: '55px'
                    }
                });
        } else if (errorType === 'success') {
            toast.success(message,
                {
                    duration: 2000,
                    style: {
                        minWidth: '300px',
                        minHeight: '55px'
                    }
                });
        }

    } catch (e) {
        console.log('toastAlert : ', e);
    }
}