// Packages
import axios from "axios";
import { Redirect } from "react-router-dom";

// Files
import { Back_Route } from "../../config/env";

// Lib
import { toastAlert } from "../../lib/toast";



// Update-Pairs
export const updatePair = async (payload) => {
    try {
        const update = await axios.post(`${Back_Route}/admin/updatePair`, payload);
        console.log('updatePairAction', update)
        return update.status == 200 ? true : false
    } catch (e) {
        console.log('updatePair_err', e);
    }
}


// Add-Currency
export const addCurrency = async (payload) => {
    try {
        const add = await axios.post(`${Back_Route}/admin/addCurrency`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        });
        console.log('add_currency_managment', add)
        return add.status == 200 ? true : false
    } catch (e) {
        console.log('updatePair_err', e);
    }
}

// Update-Currency
export const updateCurrency = async (payload) => {
    try {
        const update = await axios.post(`${Back_Route}/admin/updateCurrency`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        });
        console.log('add_currency_managment', update)
        return update.status == 200 ? true : false
    } catch (e) {
        console.log('updateCurrency_err', e);
    }
}

// Trade-History-Data
export const getTradeHistory = async () => {
    try {
        const tradeHistory = await axios.get(`${Back_Route}/admin/getTradeHistory`);
        console.log("tradeHistory", tradeHistory);
        return tradeHistory.status == 200 ? tradeHistory.data.data : []
    } catch (e) {
        console.log('getTradeHistory_err', e);
    }
}


// Currency-Data
export const getCurrency = async () => {
    try {
        const currency = await axios.get(`${Back_Route}/admin/getCurrency`);
        console.log("tradeHistory", currency);
        return currency.status == 200 ? currency.data.data : []
    } catch (e) {
        console.log('getTradeHistory_err', e);
    }
}


//Admin-login
export const adminLogin = async (data) => {
    try {
        const checkLogin = await axios.post(`${Back_Route}/admin/adminLogin`, data);
        console.log("tradeHistory", checkLogin);
        if (checkLogin.status == 200) {
            toastAlert('success', checkLogin.data.message)
            localStorage.setItem('adminLogin',checkLogin.data.data )
            return true
        }
    } catch (e) {
        console.log('adminLogin_err', e);
        toastAlert('error', e.response.data.message)
    }
}
// Currency-option-Data
export const getCurrencyOption = async () => {
    try {
        const currency = await axios.get(`${Back_Route}/admin/getCurrencyoption`);
        console.log("tradeHistory", currency);
        return currency.status == 200 ? currency.data.data : []
    } catch (e) {
        console.log('getTradeHistory_err', e);
    }
}

// Add Pair data
export const AddPair = async (data) => {
    try {
        const AddData = await axios.post(`${Back_Route}/admin/pairAdd`, data);
        console.log("tradeHistory", AddData);
        return {
            status: AddData.data.status,
            message: AddData.data.message
        };
    } catch (e) {
        console.log('getTradeHistory_err', e);
    }
}
export const EditPair = async (data) => {
    try {
        const AddData = await axios.post(`${Back_Route}/admin/PairEdit`, data);
        console.log("tradeHistory", AddData);
        return {
            status: AddData.data.status,
            message: AddData.data.message
        };
    } catch (e) {
        console.log('getTradeHistory_err', e);
    }
}

export const getCms = async (data) => {
    try {
        const getData = await axios.get(`${Back_Route}/admin/getCms/${data}`);
        console.log("getCms_data", getData);
        return getData.status == 200 ? getData.data.data : []
    } catch (e) {
        console.log('getCms_err', e);
    }
}

export const updateCms = async (data) => {
    try {
        const updateData = await axios.post(`${Back_Route}/admin/editCms`, data);
        console.log("updateData_data", updateData);
        return updateData.status == 200 ? updateData.data : []
    } catch (e) {
        console.log('updateCms_err', e);
    }
}

export const getUsersData = async () => {
    try {
        const getData = await axios.get(`${Back_Route}/admin/getUsers`);
        console.log("getUsersData_data", getData);
        return getData.status == 200 ? getData.data.data : []
    } catch (e) {
        console.log("getUsersData_err", e);
    }
}

export const addUserWhitelist = async (data) => {
    try {
        const addWhitelist = await axios.post(`${Back_Route}/admin/addWhiteList`, data);
        console.log("addWhitelist_data", addWhitelist);
        return addWhitelist.status == 200 ? true : false
    } catch (e) {
        console.log("addUserWhitelist_err", e);
    }
}





// DEFI ADMIN APIS
export const getTokens = async () => {
    try {
        const getData = await axios.get(`${Back_Route}/admin/getTokens`);
        console.log("getTokens_data", getData);
        return getData.status == 200 ? getData.data.data : []
    } catch (e) {
        console.log('getTokens_err', e);
    }
}


export const getPools = async () => {
    try {
        const getData = await axios.get(`${Back_Route}/admin/getPools`);
        console.log("getPools_data", getData);
        return getData.status == 200 ? getData.data.data : []
    } catch (e) {
        console.log('getPools_err', e);
    }
}

export const editPoolDetails = async (data) => {
    try {
        const getData = await axios.post(`${Back_Route}/admin/editPools`, data);
        console.log("editPoolDetails_data", getData);
        return getData.status == 200 ? getData.data.data : []
    } catch (e) {
        console.log("editPoolDetails_err", e);
    }
}

export const addTokensDetails = async (data) => {
    try {
        const getData = await axios.post(`${Back_Route}/admin/addTokens`, data);
        console.log("addTokensDetails_data", getData);
        return getData.status == 200 ? getData.data.data : []
    } catch (e) {
        console.log("addTokensDetails_err", e);
    }
}


export const editTokensDetails = async (data) => {
    try {
        const getData = await axios.post(`${Back_Route}/admin/editTokens`, data);
        console.log("editTokensDetails_data", getData);
        return getData.status == 200 ? getData.data.data : []
    } catch (e) {
        console.log("editTokensDetails_err", e);
    }
}


