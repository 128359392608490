import bsc from "../images/bsc.png"
import polygon from "../images/polygon.png"
import bsctestnet from "../images/bsctestnet.png"
import eth from "../images/eth.png"
import ropsten from "../images/ropsten.png"
import avalanche from '../images/Avalanche.png'
// export const RPC_URL = "https://bsc-dataseed1.binance.org/";
// export const CHAIN_ID = 56;

export const RPC_URL = "https://data-seed-prebsc-1-s1.binance.org:8545/";
export const CHAIN_ID = 97;
export const ROUTER = "0x10ED43C718714eb63d5aA57B78B54704E256024E";
export const CRYPTOCOMPARE = '3b3e5c29641550ed35dcf9dffb5d11d7d665a280228be62d328cc9cb91232c29'
// export const CRYPTOCOMPARE = 'f96a35d73dfa66a43ba007c0e75801f48a247e0a3b73bd72d708eb54d4cebea3'
export const ONEDAYINSECONDS = 86400;
export const cryptoPass = "TradeData@123###"
export const SALT = "KRISHNA"
/**Demo-Server */
export const Back_Route = "https://backend-yamada.maticz.in/api";
export const IMG_URL = "https://backend-yamada.maticz.in";
export const SOCKEt_URL = "https://backend-yamada.maticz.in";

// /**Local */
// export const Back_Route = "http://localhost:2002/api";
// export const IMG_URL = "http://localhost:2002";
// export const SOCKEt_URL = "http://localhost:2002";


export const CHAINS = [
    {
        NAME: "BSC",
        RPC_URL: "https://bsc-dataseed1.binance.org/",
        CHAIN_ID: 56,
        IMAGE: bsc,
        // ROUTER: "0x10ED43C718714eb63d5aA57B78B54704E256024E" //pancake
        ROUTER: "0x2a09fba034e26852ff38d5393f3fd15748518791"
    },
    {
        NAME: "BSC-TEST",
        RPC_URL: "https://bsc-testnet.publicnode.com",
        // RPC_URL: "https://sparkling-crimson-pool.bsc-testnet.discover.quiknode.pro/df18f1ec10499d3fcc64dab45f73ed79f2a2d617/",
        CHAIN_ID: 97,
        IMAGE: bsctestnet,
        ROUTER: "0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3" //pancake
        // ROUTER: "0x2a09fba034e26852ff38d5393f3fd15748518791"
    },
    {
        NAME: "POLYGON",
        RPC_URL: "https://polygon-rpc.com",
        CHAIN_ID: 137,
        IMAGE: polygon,
        // ROUTER: "0x10ED43C718714eb63d5aA57B78B54704E256024E" //pancake
        ROUTER: "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff" //polygon mainnet router address 
    },
    {
        NAME: "AVALANCE",
        RPC_URL: "https://api.avax-test.network/ext/bc/C/rpc",
        CHAIN_ID: 43113,
        IMAGE: avalanche,
        // ROUTER: "0x10ED43C718714eb63d5aA57B78B54704E256024E" //pancake
        ROUTER: "0x6428ca3826f67d71B6cAeDeFba58d6f7ad1eeF51" //AVALANCE testnet router address 
    },
    //  {
    //         NAME: "ETH",
    //         RPC_URL:"https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    //         CHAIN_ID:56,
    //         IMAGE: eth,
    //         // ROUTER: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D" //pancake
    //         ROUTER: "0x2a09fba034e26852ff38d5393f3fd15748518791"
    //     },
    //   {
    //         NAME: "ROPSTEN",
    //         RPC_URL:"https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    //         CHAIN_ID:3,
    //         IMAGE: ropsten,
    //         // ROUTER: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D" //pancake
    //         ROUTER: "0x2a09fba034e26852ff38d5393f3fd15748518791"
    //     }
];


export const paramsDataTypes = [
    {
        type: 'order',
        param: [{
            Order: {
                walletAddress: 'address',
                currenypaid: 'address',
                pairId: 'string',
                orderId: 'string',
                positionId: 'string',
                quantity: 'uint256',
                price: 'uint256',
                leverage: 'uint256',
                isbuy: 'bool',
                // positionSide: 'bool',
                // status: 'string',
                ordertype: 'uint8',
                Principal: 'uint256',
                closedId: 'string'
            }
        }, 'string']
    },
    {
        type: 'position',
        param: [{
            position: {
                orderId: 'string',
                positionId: 'string',
                pairId: 'string',
                executeQuantity: 'uint256',
                entryPrice: 'uint256',
                closePrice: 'uint256'
            },
        }, 'string', 'uint256', 'string']
    }
]