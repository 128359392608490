import React, { Component } from 'react';
import { useContext, useEffect, useState } from "react";
import { Modal, Table, Dropdown, Form, Tab, Tabs } from 'react-bootstrap';

import modalimg from "../../images/doll.png";
import usdt from "../../images/usdt.png"

import { Scrollbars } from 'react-custom-scrollbars';

import Pnl from './Longshorttab/pnl';
import Target from './Longshorttab/targetprice';
import Liguidation from './Longshorttab/liquidation';
import Maxprice from './Longshorttab/maxx';
import Openprice from './Longshorttab/openprice';

class Depositmodal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            depositModal11: true,
        };
    }
    componentDidMount() {
        console.log("this.props", this.props);
    }

    render() {


        const { depositModal11 } = this.state


        return (
            <div className='did1'>
                <Modal left className="orderbook-modal" show={depositModal11} centered size="lg" dialogClassName={!depositModal11 ? "modal-90w modal-lg  orderbookleft longshortprice dark-bg fade" : "modal-90w modal-lg orderbookleft longshortprice left dark-bg showfull "}>
                    <Modal.Header className="pb-0">

                        <button type="button" className="close" onClick={() => this.props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

                    </Modal.Header>
                    <Modal.Body className="select-wallet orderbook pt-0 pb-5 px-0">
                        <Scrollbars className="modal_scroll mt-5" style={{ height: 100 }}>
                            <div className='pr-2 mt-3 longshort'>

                                <Tabs
                                    defaultActiveKey="pnl"
                                    id="uncontrolled-tab-example"
                                    className="mb-3 tabs1"
                                >
                                    <Tab eventKey="pnl" title="Pnl" className='nav-link1'>
                                        <Pnl />

                                    </Tab>
                                    <Tab eventKey="profile" title="Target Price" className='nav-link1'>
                                        <Target />

                                    </Tab>
                                    <Tab eventKey="contact" title="Liquidation Price" className='nav-link1'>
                                        <div className='d-flex justify-content-between'>
                                            <div>
                                                <Dropdown>
                                                    <Dropdown.Toggle className='pl-4 pr-4 pt-2 pb-2 mb-4' variant="secondary" id="dropdown-basic">
                                                    Cross
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className='w-100'>
                                                        <Dropdown.Item href="#/action-1">Cross</Dropdown.Item>
                                                        <Dropdown.Item href="#/action-2">Isolated</Dropdown.Item>                                                   
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                  {/* <select class="form-select form-select-sm select2 pl-4 pr-4  mb-3" aria-label=".form-select-sm example">
                                                        <option selected> Cross</option>
                                                        <option value="1">Cross</option>
                                                        <option value="2">Isolated</option>
                                                       
                                                    </select> */}
                                            </div>
                                            <div>
                                                <Dropdown>
                                                    <Dropdown.Toggle className='pl-4 pr-4 pt-2 pb-2 mb-4' variant="secondary" id="dropdown-basic">
                                                    One - way mode
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className='w-100'>
                                                        <Dropdown.Item href="#/action-1">One - way mode</Dropdown.Item>
                                                        <Dropdown.Item href="#/action-2">Hedge Mode</Dropdown.Item>                        
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                  {/* <select class="form-select form-select-sm select2 pl-4 pr-4 mb-3" aria-label=".form-select-sm example">
                                                        <option selected> One - way mode</option>
                                                        <option value="1">One - way mode</option>
                                                        <option value="2">Hedge Mode</option>
                                                        
                                                    </select> */}
                                            </div>
                                        </div>
                                        <Liguidation />

                                    </Tab>
                                    <Tab eventKey="contact1" title="Max Open" className='nav-link1'>
                                        {/* <div className='row'>
                                        <div className='col-md-9 m-auto'> */}
                                        <div className='text-center dropdown1'>
                                                <Dropdown>
                                                    <Dropdown.Toggle className='w-100 pl-4 pr-4 pt-2 pb-2 mb-4' variant="secondary" id="dropdown-basic">
                                                    BTCUSDT
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className='w-100'>
                                                        <Dropdown.Item href="#/action-1">BTCUSDT</Dropdown.Item>
                                                        <Dropdown.Item href="#/action-2">ETHUSDT</Dropdown.Item>
                                                        <Dropdown.Item href="#/action-3">ADAUSDT</Dropdown.Item>
                                                        <Dropdown.Item href="#/action-3">DOGEUSDT</Dropdown.Item>
                                                        <Dropdown.Item href="#/action-3">SOLUSDT</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                  {/* <select class="form-select form-select-sm w-100 select1 mb-3" aria-label=".form-select-sm example">
                                                        <option selected>BTCUSDT</option>
                                                        <option value="1">BTCUSDT</option>
                                                        <option value="2">ETHUSDT</option>
                                                        <option value="3">ADAUSDT</option>
                                                        <option value="4">DOGEUSDT</option>
                                                        <option value="5">SOLUSDT</option>
                                                        <option value="6">XRPUSDT</option>
                                                    </select> */}
                                                </div>
                                            {/* </div>
                                            </div> */}
                                        <Maxprice/>                               
                                    </Tab>
                                    <Tab eventKey="contact11" title="Open Price" className='nav-link1'>
                                    {/* <div className='row'>
                                        <div className='col-md-9 m-auto'> */}
                                        <div className='text-center dropdown1'>
                                                <Dropdown>
                                                    <Dropdown.Toggle className='w-100 pl-4 pr-4 pt-2 pb-2 mb-4' variant="secondary" id="dropdown-basic">
                                                    BTCUSDT
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className='w-100'>
                                                    <Dropdown.Item href="#/action-1">BTCUSDT</Dropdown.Item>
                                                        <Dropdown.Item href="#/action-2">ETHUSDT</Dropdown.Item>
                                                        <Dropdown.Item href="#/action-3">ADAUSDT</Dropdown.Item>
                                                        <Dropdown.Item href="#/action-3">DOGEUSDT</Dropdown.Item>
                                                        <Dropdown.Item href="#/action-3">SOLUSDT</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                   {/* <select class="form-select form-select-sm w-100 select1 mb-3" aria-label=".form-select-sm example">
                                                        <option selected>BTCUSDT</option>
                                                        <option value="1">BTCUSDT</option>
                                                        <option value="2">ETHUSDT</option>
                                                        <option value="3">ADAUSDT</option>
                                                        <option value="4">DOGEUSDT</option>
                                                        <option value="5">SOLUSDT</option>
                                                        <option value="6">XRPUSDT</option>
                                                    </select> */}
                                                </div>
                                            {/* </div>
                                            </div> */}
                                        <Openprice/>
                                    </Tab>
                                </Tabs>
                            </div>
                        </Scrollbars>
                    </Modal.Body>
                </Modal>



            </div>
        )
    }

}


export default Depositmodal