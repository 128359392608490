import React, { Component, useEffect, useState } from 'react';
import { Link, useLocation , useNavigate} from 'react-router-dom';

import '../css/footer.css';

import dashboard from "../images/dashboard.png"
import leverage from "../images/leverage.png"
import users from "../images/users_grey.png"
import currency from "../images/currency.png"
import tradehistory from "../images/tradehistory.png"
import pairmanagement from "../images/pairmanagement.png"


import info from "../images/info.png"
import docs from "../images/google-docs.png"
import cms from "../images/cms.png"



const Adminsidebar = () => {
    const location = useLocation();
    const [depositModal, setDepositModel] = useState(false)
    const onDismiss = () => {
        setDepositModel(false);
    }
    useEffect(() => {
        const getToken = localStorage.getItem("adminLogin");
        // console.log("getTokengetTokengetToken", getToken)
        if (getToken == "" || getToken == null || getToken == undefined) {
          window.location.href = window.location.origin + "/adminlogin"
        }
      }, [localStorage.getItem("adminLogin")])

    return (
        <div className='sidebar_sec' id="sidebar_scroll">

            <ul className='sidebar_ul'>
                <li>
                    <Link to="/admindashboard">
                        <div>
                            <span className='icon_bg'>
                                <img src={dashboard} className="icon_sidebar" />
                            </span>
                            <span className='text_side px-2'>
                                Dashboard
                            </span>
                            <span className='text_side'>
                                <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                            </span>
                        </div>
                    </Link>
                </li>
                {/* <li>
                    <Link to="/leverage">
                    <div>
                    <span className='icon_bg'>
                        <img src={leverage} className="icon_sidebar" />
                    </span>
                    <span className='text_side px-2'>
                        Leverage
                    </span>
                    <span className='text_side'>
                    <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                    </span>
                    </div>
                    </Link>
                </li> */}
                <li>
                    <Link to="/currency">
                        <div>
                            <span className='icon_bg'>
                                <img src={currency} className="icon_sidebar" />
                            </span>
                            <span className='text_side px-2'>
                                Currency
                            </span>
                            <span className='text_side'>
                                <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                            </span>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link to="/tradeHistory">
                        <div>
                            <span className='icon_bg'>
                                <img src={tradehistory} className="icon_sidebar" />
                            </span>
                            <span className='text_side px-2'>
                                Trade History
                            </span>
                            <span className='text_side'>
                                <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                            </span>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link to="/adminpairmanagment">
                        <div>
                            <span className='icon_bg'>
                                <img src={pairmanagement} className="icon_sidebar" />
                            </span>
                            <span className='text_side px-2'>
                                PairManagment
                            </span>
                            <span className='text_side'>
                                <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                            </span>
                        </div>
                    </Link>
                </li>
                {/* <li>
                <Link to="/users">
                    <div>
                    <span className='icon_bg'>
                        <img src={users} className="icon_sidebar" />
                    </span>
                    <span className='text_side px-2'>
                        Users
                    </span>
                    <span className='text_side'>
                    <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                    </span>
                    </div>
                    </Link>
                </li> */}
                {/* <li>
                <Link to="/whitelist"> 
                    <div>
                    <span className='icon_bg'>
                        <img src={docs} className="icon_sidebar" />
                    </span>
                    <span className='text_side px-2'>
                        Whitelist
                    </span>
                    <span className='text_side'>
                    <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                    </span>
                    </div>
                    </Link>
                   
                </li> */}


                <li className='info_li'>
                    <div className='div_first'>
                        <span className='icon_bg'>
                            <img src={cms} className="icon_sidebar" />
                        </span>
                        <span className='text_side px-2'>
                            Defi
                        </span>
                        <span className='text_side'>
                            <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                        </span>
                    </div>
                    <div>
                        <div className='sidebar_inner'>
                            <span className='text_sub px-2'>
                                <Link to="/defiCms"><i className="fa fa-address-card mr-2 icon_admin" aria-hidden="true"></i>CMS</Link>
                                <Link to="/tokenList"><i className="fa fa-newspaper-o mr-2 icon_admin" aria-hidden="true"></i>Token List</Link>
                                <Link to="/pools"><i className="fa fa-newspaper-o mr-2 icon_admin" aria-hidden="true"></i>Pool</Link>

                            </span>

                        </div>
                    </div>

                </li>


                <li className='info_li'>
                    <div className='div_first'>
                        <span className='icon_bg'>
                            <img src={cms} className="icon_sidebar" />
                        </span>
                        <span className='text_side px-2'>
                            Settings
                        </span>
                        <span className='text_side'>
                            <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                        </span>
                    </div>
                    <div>
                        <div className='sidebar_inner sidebar_inner_admin'>
                            <span className='text_sub px-2'>
                                <Link to="/cms"><i className="fa fa-address-card mr-2 icon_admin" aria-hidden="true"></i>Cms</Link>
                                <Link to="/feature"><i className="fa fa-newspaper-o mr-2 icon_admin" aria-hidden="true"></i>Announcement</Link>

                            </span>

                        </div>
                    </div>

                </li>

            </ul>


        </div>
    );
}

export default Adminsidebar