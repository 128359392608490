import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import { Modal } from 'react-bootstrap';

import modalimg from "../../images/doll.png";
import usdt from "../../images/usdt.png"

import { Scrollbars } from 'react-custom-scrollbars';

import Depositcoinmodal from "./Depositcoinmodal";
import Withdrawcoinmodal from "./Withdrawcoinmodal";


const Depositmodal = (props) => {

    const [depositModal, setDepositModal] = useState(true)
    const [depositcoinModal, setDepositcoinModal] = useState(false)
    const [withdrawcoinModal, setWithdrawcoinModal] = useState(false)

    const onDismissdeposit = () => {
        setDepositcoinModal(false)
    }

    const onDismisswithdraw = () => {
        setWithdrawcoinModal(false)
    }

    return (
        <div>
            <Modal className="wallet-modal" show={depositModal} centered size="sm" dialogClassName={!depositModal ? "modal-90w dark-bg fade " : "modal-90w dark-bg showfull"}>
                <Modal.Header className="pb-0">

                    <button type="button" className="close" onClick={() => props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

                </Modal.Header>
                <Modal.Body className="select-wallet pt-0 pb-5 px-0">
                    <Scrollbars className="modal_scroll" style={{ height: 100 }}>
                        <div className='pr-2'>
                            <div className='text-center'>
                                <img src={modalimg} className="img-fluid modal_img" />
                            </div>
                            <div className='text-center mt-3'>
                                <button className="get-started-btn btn_head mb-4 ml-3">
                                    Margin Account Balances</button>
                            </div>
                            <form className='px-3 px-md-5 mt-4 pb-5'>
                                <label className='text_yellow text-uppercase text-left'>USDT Balance</label>
                                <div className="input-group mb-3 input_grp_big">
                                    <input type="text" value="0" className="form-control" placeholder="USDT Balance" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                    <div className="input-group-append">
                                        <span className="input-group-text" id="basic-addon2">
                                            <img src={usdt} className="coin_dd_img" />
                                        </span>
                                    </div>
                                </div>
                                <label className='text_yellow text-uppercase text-left'>USDT Escrow</label>
                                <div className="input-group mb-3 input_grp_big">
                                    <input type="text" value="0" className="form-control" placeholder="USDT Balance" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                    <div className="input-group-append">
                                        <span className="input-group-text" id="basic-addon2">
                                            <img src={usdt} className="coin_dd_img" />
                                        </span>
                                    </div>
                                </div>
                                <div className='row mt-4 justify-content-between'>
                                    <div className='col-6'>
                                        <button type="button" className='btn btn_trans_click p-0' onClick={() => { setDepositcoinModal(true); setDepositModal(false) }}>
                                            <div className='card card_modal'>
                                                <div className='card-body'>
                                                    <div className='icon_bg_white'>
                                                        <i className="fa fa-arrow-up" aria-hidden="true"></i>
                                                    </div>
                                                    <p className='change_color mt-3 mb-0'>Deposit</p>
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                    <div className='col-6 text-right'>
                                        <button type="button" className='btn btn_trans_click p-0' onClick={() => { setWithdrawcoinModal(true); setDepositModal(false) }}>
                                            <div className='card card_modal ml-auto'>
                                                <div className='card-body'>
                                                    <div className='icon_bg_white'>
                                                        <i className="fa fa-arrow-down" aria-hidden="true"></i>
                                                    </div>
                                                    <p className='change_color mt-3 mb-0'>Withdraw</p>
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Scrollbars>
                </Modal.Body>
            </Modal>

            {depositcoinModal && <Depositcoinmodal connect={"string"} onDismiss={() => onDismissdeposit()} />}
            {withdrawcoinModal && <Withdrawcoinmodal connect={"string"} onDismiss={() => onDismisswithdraw()} />}

        </div>
    )
}



export default Depositmodal