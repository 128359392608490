import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Tabs, Tab } from 'react-bootstrap';
import '../../css/styles.css';

import Adminheader from '../Adminheader';
import Adminsidebar from '../Adminsidebar';
import Footer from '../Footer';

import Leveragemodal from "./Leveragemodal";
import CurrencyModel from "./CurrencyModel"
import Announcementmodal2 from "../../components/Announcementmodal2";

import whitelist from "../../images/whitelist.png"
import users from "../../images/users.png"

import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';

import { data } from "./data";
import { getCurrency } from '../action/admin';
import CurrencyEditModel from './CurrencyEditModel';
const Currency = () => {

    const [leverageModal, setLeverageModal] = useState(false)
    const [currency, setCurrency] = useState([])
    const [records, setRecord] = useState([])
    const [editModel, setEditModal] = useState(false)

    const onDismissleverage = () => { setLeverageModal(false) }
    const onDismissEdit = () => { setEditModal(false) }
    // const onDismiss1 = () => { setAnnouncementModal2(false) }
    const columns = [
        {
            name: "S.No",
            selector: "id",
            sortable: true,
            cell : (record, i) => {return i +1}
        },
        // {
        //     name: "Currency",
        //     selector: "currencyName",
        //     sortable: true,
        //     cell : (record) => {return record.currencyName}
        // },
        {
            name: "Currency Symbol",
            selector: "currencySymbol",
            sortable: true,
            cell : (record) => {return record.currencySymbol}
        },
        {
            name: "Action",
            selector: "action",
            sortable: true,
            cell: (record) => (
                <>
                    <button type="button" className="get-started-btn my-2 ml-0"
                        onClick={() => {
                            setRecord(record)
                            setEditModal(true)
                        }}
                    >
                        Edit
                    </button>
                </>
            )

        }
    ];

    useEffect(() => {
        getCurrencyDetails()
    }, [leverageModal,editModel])

    const getCurrencyDetails = async () => {
        const getData = await getCurrency();
        console.log("getCurrencyDetails",getData)
        setCurrency(getData)
    }
    return (
        <div>
            <Adminheader />
            <div className='row main_view mx-0 w-100'>
                <div className='col-1 col_sidebar'>
                    <Adminsidebar />
                </div>
                <div className='col-11 main_dash_sec pb-4 col_11_admin'>
                    <div className='row'>
                        <div className='col-12 col-md-10 col-lg-9 mx-auto'>
                            <div className='mt_main_sec text-center'>
                                <button type="button" className="get-started-btn btn_head mb-4 ml-3">
                                    Currency</button>
                            </div>
                            <div className='mt_main_sec'>
                                <div className='text-right mt-5 mb-5'>
                                    <button className="get-started-btn text-uppercase" onClick={() => {setLeverageModal(true)}}>
                                        Add Currency</button>
                                </div>
                                <div className='mt-3 pb-5'>
                                    <div className='table-responsive'>

                                        <DataTable className='table table_user table_admin mb-0'
                                            noHeader
                                            sortable={false}
                                            columns={columns}
                                            data={currency}
                                            pagination
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {leverageModal && <CurrencyModel connect={"string"} onDismiss={() => { onDismissleverage() }} />}
            {editModel && <CurrencyEditModel connect={"string"} onDismiss={() => { onDismissEdit() }} data = {records}/>}

            {/* {announcementModal2 && <Announcementmodal2 connect={"string"} onDismiss={() => { onDismiss1() }} />} */}

        </div>
    );
}

export default Currency