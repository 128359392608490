import React from "react";
import { Link } from 'react-router-dom';



export const columns = [
    {
        name: "ID",
        selector: "id",
        sortable: true
      },
      {
        name: "Multiplier",
        selector: "multiplier",
        sortable: true
      },         
      {
        name: "Management Fee",
        selector: "managementfee",
        sortable: true
      }
];


export const columns1 = [
  {
      name: "ID",
      selector: "id",
      sortable: true
    },
    {
      name: "User",
      selector: "user",
      sortable: true
    },
    {
      name: "Amount",
      selector: "amount",
      sortable: true
    },
    {
      name: "Status",
      selector: "status",
      sortable: true
    },
    {
      name: "Action",
      selector: "action",
      sortable: true
    }
];

export const columns2 = [
  {
    name: "ID",
    selector: "id",
    sortable: true
  },
  {
    name: "Address",
    selector: "address",
    sortable: true
  },
  {
    name: "Deposited",
    selector: "deposited",
    sortable: true
  },
  {
    name: "Borrowed",
    selector: "borrowed",
    sortable: true
  },
  {
    name: "Positions",
    selector: "positions",
    sortable: true
  }
];

export const columns3 = [
  {
    name: "ID",
    selector: "id",
    sortable: true
  },
  {
    name: "Address",
    selector: "address",
    sortable: true
  },
  {
    name: "Deposited",
    selector: "deposited",
    sortable: true
  },
  {
    name: "Borrowed",
    selector: "borrowed",
    sortable: true
  },
  {
    name: "Positions",
    selector: "positions",
    sortable: true
  }
];





export const data = [
  {
    id: 1,
    id: "34501",
    multiplier: "2x",
    managementfee:"$523525"
   
  },
  {
    id: 2,
    id: "34502",
    multiplier: "2x",
    managementfee:"$523525"
   
  },
  {
    id: 3,
    id: "34503",
    multiplier: "2x",
    managementfee:"$523525"
   
  },
  {
    id: 4,
    id: "34504",
    multiplier: "2x",
    managementfee:"$523525"
   
  },
  {
    id: 5,
    id: "34505",
    multiplier: "2x",
    managementfee:"$523525"
   
  },
  {
    id: 6,
    id: "34506",
    multiplier: "2x",
    managementfee:"$523525"
   
  },
  {
    id: 7,
    id: "34507",
    multiplier: "2x",
    managementfee:"$523525"
   
  },
  {
    id: 8,
    id: "34508",
    multiplier: "2x",
    managementfee:"$523525"
   
  },
  {
    id: 9,
    id: "34509",
    multiplier: "2x",
    managementfee:"$523525"
   
  },
  {
    id: 10,
    id: "34510",
    multiplier: "2x",
    managementfee:"$523525"
   
  },
  {
    id: 11,
    id: "34511",
    multiplier: "2x",
    managementfee:"$523525"
   
  },
  
  
];

export const data1 = [
  {
    id: 1,
    user: "0xwe123..fc678",
    amount: "$2,000",     
    status:<span className="accept_desc">Active</span>,
    action: <button className='btn btn_grey'><i className="fa fa-trash" aria-hidden="true"></i></button>
   
  },
  {
    id: 2,
    user: "0xwe123..fc678",
    amount: "$2,000",     
    status:<span className="reject_desc">Disabled</span>,
    action: <button className='btn btn_grey'><i className="fa fa-trash" aria-hidden="true"></i></button>    
   
  },
  {
    id: 3,
    user: "0xwe123..fc678",
    amount: "$2,000",     
    status:<span className="accept_desc">Active</span>,
    action: <button className='btn btn_grey'><i className="fa fa-trash" aria-hidden="true"></i></button>    
   
  },
  {
    id: 4,
    user: "0xwe123..fc678",
    amount: "$2,000",     
    status:<span className="reject_desc">Disabled</span>,
    action: <button className='btn btn_grey'><i className="fa fa-trash" aria-hidden="true"></i></button>    
   
  },
  {
    id: 5,
    user: "0xwe123..fc678",
    amount: "$2,000",     
    status:<span className="accept_desc">Active</span>,
    action: <button className='btn btn_grey'><i className="fa fa-trash" aria-hidden="true"></i></button>
   
  }
  
];

export const data2 = [
    {
      id: 1,
      address: "2xwe3..678rt",
      deposited: "$2,000",  
      borrowed: "$1,000", 
      positions: "10"    
     
    },
    {
      id: 2,
      address: "2xwe3..678rt",
      deposited: "$2,000",  
      borrowed: "$1,000", 
      positions: "10"    
     
    },
    {
      id: 3,
      address: "2xwe3..678rt",
      deposited: "$2,000",  
      borrowed: "$1,000", 
      positions: "10"    
     
    },
  
    
  ];

  export const data3 = [
    {
      id: 1,
      address: "2xwe3..678rt",
      deposited: "$2,000",  
      borrowed: "$1,000", 
      positions: "10"    
     
    },
    {
      id: 2,
      address: "2xwe3..678rt",
      deposited: "$2,000",  
      borrowed: "$1,000", 
      positions: "10"    
     
    },
    {
      id: 3,
      address: "2xwe3..678rt",
      deposited: "$2,000",  
      borrowed: "$1,000", 
      positions: "10"    
     
    },
  
    
  ];


 


