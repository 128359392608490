
export const EXCHANGEMASTERCHIEF = '0x2c2448874c139c5ad6956b0cab4b6fa89fae447d'; 

export const DECIMAL = 18;

export const EXCHANGE_MASTERCHEF_ABI = [
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_salt",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "walletAddress",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "string",
				"name": "orderID",
				"type": "string"
			}
		],
		"name": "OrderPlaced",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "walletAddress",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "string",
				"name": "positionID",
				"type": "string"
			}
		],
		"name": "PostionUpdated",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "walletAddress",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "_profit",
				"type": "uint256"
			}
		],
		"name": "closedPostion",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "bytes",
				"name": "_data",
				"type": "bytes"
			}
		],
		"name": "closePostion",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes",
				"name": "_data",
				"type": "bytes"
			}
		],
		"name": "forcedLiquidate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"name": "isOrderExists",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes",
				"name": "_data",
				"type": "bytes"
			}
		],
		"name": "openOrder",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "orderBook",
		"outputs": [
			{
				"internalType": "address",
				"name": "walletAddress",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "currenypaid",
				"type": "address"
			},
			{
				"internalType": "string",
				"name": "pairId",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "orderId",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "positionId",
				"type": "string"
			},
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "price",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "leverage",
				"type": "uint256"
			},
			{
				"internalType": "bool",
				"name": "isbuy",
				"type": "bool"
			},
			{
				"internalType": "uint8",
				"name": "ordertype",
				"type": "uint8"
			},
			{
				"internalType": "uint256",
				"name": "Principal",
				"type": "uint256"
			},
			{
				"internalType": "string",
				"name": "closedId",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "orderPerUser",
		"outputs": [
			{
				"internalType": "address",
				"name": "walletAddress",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "currenypaid",
				"type": "address"
			},
			{
				"internalType": "string",
				"name": "pairId",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "orderId",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "positionId",
				"type": "string"
			},
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "price",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "leverage",
				"type": "uint256"
			},
			{
				"internalType": "bool",
				"name": "isbuy",
				"type": "bool"
			},
			{
				"internalType": "uint8",
				"name": "ordertype",
				"type": "uint8"
			},
			{
				"internalType": "uint256",
				"name": "Principal",
				"type": "uint256"
			},
			{
				"internalType": "string",
				"name": "closedId",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"name": "orders",
		"outputs": [
			{
				"internalType": "address",
				"name": "walletAddress",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "currenypaid",
				"type": "address"
			},
			{
				"internalType": "string",
				"name": "pairId",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "orderId",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "positionId",
				"type": "string"
			},
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "price",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "leverage",
				"type": "uint256"
			},
			{
				"internalType": "bool",
				"name": "isbuy",
				"type": "bool"
			},
			{
				"internalType": "uint8",
				"name": "ordertype",
				"type": "uint8"
			},
			{
				"internalType": "uint256",
				"name": "Principal",
				"type": "uint256"
			},
			{
				"internalType": "string",
				"name": "closedId",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address payable",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"name": "positions",
		"outputs": [
			{
				"internalType": "string",
				"name": "orderId",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "positionId",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "pairId",
				"type": "string"
			},
			{
				"internalType": "uint256",
				"name": "executeQuantity",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "entryPrice",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "closePrice",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "positionsPerUser",
		"outputs": [
			{
				"internalType": "string",
				"name": "orderId",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "positionId",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "pairId",
				"type": "string"
			},
			{
				"internalType": "uint256",
				"name": "executeQuantity",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "entryPrice",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "closePrice",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address payable",
				"name": "_newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes",
				"name": "_data",
				"type": "bytes"
			}
		],
		"name": "updatePostion",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}
]