import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Tabs, Tab } from 'react-bootstrap';
import '../../css/styles.css';

import Adminheader from '../Adminheader';
import Adminsidebar from '../Adminsidebar';
import Footer from '../Footer';

import TokenListModal from "./TokenListModel";
import TokenListEditModal from './TokenListEditModel'


import whitelist from "../../images/whitelist.png"
import users from "../../images/users.png"
import bur from "../../images/bur.png"


import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';

import { columns3, data3 } from "./data";
import { getTokens } from '../action/admin';

const Tokens = () => {


    const [featureModal, setFeatureModal] = useState(false)
    const [featureModalEdit, setFeatureEditModal] = useState(false)
    const [token, setTokens] = useState([])
    const [record, setrecord] = useState({})

    const onDismissfeature = () => {
        setFeatureModal(false)
    }
    const onDismissfeatureEdit = () => {
        setFeatureEditModal(false)
    }
    const columns3 = [
        {
            name: "Name",
            selector: "name",
            sortable: true
        },
        {
            name: "Symbol",
            selector: "symbol",
            sortable: true
        },
        {
            name: "Decimal",
            selector: "decimals",
            sortable: true
        },
        {
            name: "Address",
            selector: "address",
            sortable: true
        },
        {
            name: "Chain Id",
            selector: "chainId",
            sortable: true
        },
        // {
        //     name: "Type",
        //     selector: "type",
        //     sortable: true,
        //     cell: (record) => { return record.type.toUpperCase() }
        // },
        {
            name: "Action",
            selector: "type",
            sortable: true,
            cell: (record) => {
                return (
                    <button className="get-started-btn text-uppercase" onClick={() => {setrecord(record); setFeatureEditModal(true)}}> Edit</button>
                )
            }
        }
    ];

    useEffect(() => {
        getTokensDetails()
    }, [featureModalEdit, featureModal])

    const getTokensDetails = async () => {
        const getData = await getTokens();
        console.log("getTokensDetails", getData)
        setTokens(getData)
    }

    return (
        <div>

            <Adminheader />
            <div className='row main_view mx-0 w-100'>
                <div className='col-1 col_sidebar'>
                    <Adminsidebar />
                </div>
                <div className='col-11 main_dash_sec pb-4 col_11_admin'>
                    <div className='row'>
                        <div className='col-12 col-md-10 col-lg-9 mx-auto'>
                            <div className='mt_main_sec text-center'>
                                <button type="button" className="get-started-btn btn_head mb-4 ml-3">
                                    Tokens</button>
                            </div>
                            <div className='mt_main_sec'>
                                <div className='text-right mt-5 mb-5'>
                                    <button className="get-started-btn text-uppercase" onClick={() => setFeatureModal(true)}>
                                        Add Tokens</button>
                                </div>
                                <div className='mt-3 pb-5'>
                                    <div className='table-responsive'>
                                        {token && token.length > 0 ? 
                                        <DataTable className='table table_user table_admin mb-0'
                                            noHeader
                                            sortable={false}
                                            columns={columns3}
                                            data={token}
                                            pagination
                                        /> : <p className='no_data_texter text-center'>No data</p> 
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {featureModal && <TokenListModal connect={"string"} onDismiss={() => { onDismissfeature() }} />}
            {featureModalEdit && <TokenListEditModal connect={"string"} onDismiss={() => { onDismissfeatureEdit() }} data={record}/>}

        </div>
    );
}

export default Tokens