import { ORDER_PLACING, OPEN_ORDERS, CANCEL_ORDERS, POSITION_ORDERS } from '../action';

const initialState = {
    orderPlacing: "",
    openOrder: [],
    positionOrder: "",
    cancelOrder: ""
};

const tradeReducer = (state = initialState, action) => {
    switch (action.type) {
        case ORDER_PLACING:
            return { ...state, orderPlacing: action.payload };
            break;
        case OPEN_ORDERS:
            return { ...state, openOrder: action.payload };
            break;
        case CANCEL_ORDERS:
            return { ...state, cancelOrder: action.payload };
            break;
        case POSITION_ORDERS:
            return { ...state, positionOrder: action.payload };
            break;
        default:
            return state;
    }
};


export default tradeReducer