import React, { Component } from 'react';
import { useContext, useEffect, useState } from "react";
import { Modal } from 'react-bootstrap';
import wall1 from "../images/metamask_wallet.png";
import wall2 from "../images/connect_wallet.png";
import wall3 from "../images/wall3.png";
import { MetamaskWallet, WalletConnect } from "../hooks/useWallet"

import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';

const Walletmodal = (props) => {
    const dispatch = useDispatch()
    const [walletModal , setwalletModal] = useState(true)

    const MetamaskConnectWallet = async () => {
        try {
            const connection = await MetamaskWallet(dispatch);
            console.log('this.props_function',props)

            if (connection != undefined) {
               props.onDismiss()
            }
            console.log("connect_connect", connection)
        } catch (e) {
            console.log("MetamaskConnectWallet", e)
        }
    }

    const walletConnect = async () => {
        try {
            const connect = await WalletConnect(dispatch);
            if (connect != undefined) {
                // setwalletModal(false)
               props.onDismiss()
            }
            console.log("connect_connect", connect) 

        } catch (e) {
            console.log("walletConnect", e)
        }
    }
    // componentWillReceiveProps(nextprops){
    //     console.log('walletModel_nextprops',nextprops);
    //     if(nextprops?.Auth?.walletAddress != ""){
    //         this.setState({ walletModal: false });
    //     }
    // }

        return (
            <Modal className="wallet-modal" show={walletModal} centered size="sm">
                <Modal.Header className="pb-0">
                    <div>
                        <h3 className="sec-head ">Connect Wallet</h3>
                    </div>
                    <button type="button" class="close" onClick={() => props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

                </Modal.Header>
                <Modal.Body className="select-wallet text-center pt-0 pb-5 px-0">

                    <div className="wallet-lists">
                        <ul className="mb-0 mt-4">
                            <li onClick={()=> {MetamaskConnectWallet()}}>
                                <div className="img">
                                    <img src={wall1} alt="img" />
                                </div>
                                <div className="wal-option">
                                    <h3 className="side-head-li mb-0"> Metamask</h3>

                                </div>
                            </li>
                            <li onClick={()=> {walletConnect()}}>
                                <div className="img">
                                    <img src={wall2} alt="img" />
                                </div>
                                <div className="wal-option">
                                    <h3 className="side-head-li mb-0">Wallet Connect</h3>
                                </div>
                            </li>

                        </ul>
                        <div className='mod_pad_space mt-4'>
                            <p className='font_16 text_yellow mb-4 font_weight_700'>Haven’t got a crypto wallet yet?</p>
                            {/* <button className='get-started-btn custom_header_burger text-uppercase'>
                                Learn How to Connect</button> */}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

// }


// export default Walletmodal



// Walletmodal.propTypes = {
//     Auth: PropTypes.object.isRequired,
//     MetamaskWallet: PropTypes.object.isRequired,
//     WalletConnect: PropTypes.object.isRequired
// }
// const mapStateToProps = state => ({ 
//     Auth: state.Auth,
// });

// export default connect(mapStateToProps,{
//     MetamaskWallet,
//     WalletConnect
// })(withRouter(Walletmodal));

export default Walletmodal