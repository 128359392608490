const isEmpty = value =>
//  console.log("value",value)
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0);


export const isBoolean = value => (
    (typeof value === 'boolean' && (value === true || value === false)) ||
    (typeof value === 'string' && (value === 'true' || value === 'false'))
)

export const ObjectIsempty = (Obj) => {
    try {
        // let objectArr = Object.values(Obj).filter((val) => { return isEmpty(val) ? true : false })
        let objectArr = Object.values(Obj)

        for(let i=0;i<objectArr.length;i++){
            if(!isEmpty(objectArr[i])){
                return false
            }else{
                return true
            }
        }
        return true
        // if (!isEmpty(objectArr)) {
        //     return false
        // } else {
        //     return true
        // }
    } catch (err) {
        console.log(err, 'ObjectIsempty_err')
    }
}

export default isEmpty