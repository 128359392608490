import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Tabs, Tab } from 'react-bootstrap';
import '../../css/styles.css';

import Adminheader from '../Adminheader';
import Adminsidebar from '../Adminsidebar';
import Footer from '../Footer';




import whitelist from "../../images/whitelist.png"
import users from "../../images/users.png"
import { getUsersData } from '../action/admin';



const Admindashboard = () => {

  const [user, setUsers] = useState([]);
  const [whitelisted, setWhitelisted] = useState([])

  const getUser = async () => {
    try {
      const getData = await getUsersData();
      console.log("getData_getData", getData)
      setUsers(getData);
      setWhitelisted(getData.filter((el)=> {return el.whitelisted == true}));
    } catch (e) {
      console.log("getUser_err", e);
    }
  }

  useEffect(() => {
    getUser()
  }, [])

  return (
    <div>

      <Adminheader />
      <div className='row main_view mx-0 w-100'>
        <div className='col-1 col_sidebar'>
          <Adminsidebar />
        </div>
        <div className='col-11 main_dash_sec pb-4 col_11_admin'>
          <div className='row'>
            <div className='col-12 col-md-10 col-lg-9 mx-auto'>
              <div className='mt_main_sec text-center'>
                <button className="get-started-btn btn_head mb-4 ml-3">
                  Dashboard</button>
              </div>
              <div className='row mt_main_sec pb-5'>
                <div className='col-12 col-md-6 mb-3'>
                  <div className='card inner_card'>
                    <div className='card-body'>
                      <img src={whitelist} className="img-fluid mt-3" />
                      <p className='text-white text-uppercase font_18 mt-4 mb-0'>Whitelisted Users</p>
                      <p className='text-white text-uppercase font_30 mb-0'>{whitelisted.length}+</p>
                      <Link to={{pathname: `/whitelist`, state: {whitelisted}}}  className='text_go' state={whitelisted}>
                        <p className='text-right text-white font_30 mb-0 mt-0'>
                          <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className='col-12 col-md-6 mb-3'>
                  <div className='card inner_card'>
                    <div className='card-body'>
                      <img src={users} className="img-fluid mt-3" />
                      <p className='text-white text-uppercase font_18 mt-4 mb-0'>Users</p>
                      <p className='text-white text-uppercase font_30 mb-0'>{user.length}+</p>
                      <Link to="/users" className='text_go' state={user}>
                        <p className='text-right text-white font_30 mb-0 mt-0'>
                          <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  );
}

export default Admindashboard