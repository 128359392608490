import React, { Component, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import wall1 from "../../images/metamask_wallet.png";
import wall2 from "../../images/connect_wallet.png";
import { Scrollbars } from 'react-custom-scrollbars';
import { updateCurrency } from '../action/admin';
import { IMG_URL } from '../../config/env';
import { toastAlert } from '../../lib/toast';



const CurrencyEditModel = (props) => {

    const [leverageModal, setLeverageModal] = useState(true)
    const [currencySymbol, setCurrencySymbol] = useState('')
    const [currencyName, setCurrencyName] = useState('')
    const [currencyImage, setCurrencyImage] = useState('')
    const [network, setNetwork] = useState('')
    const [tokenType, setTokenType] = useState('')
    const [minABI, setMinABI] = useState('')
    const [currencyType, setCurrencyType] = useState('')
    const [contractAddress, setContractAddress] = useState('')
    const [errror, setError] = useState()
    const [editRecords, setEditRecords] = useState({})

    const editCurrency = async () => {
        const formData = new FormData()
        formData.append("currencyName", currencyName)
        formData.append("file", currencyImage)
        formData.append("minABI", minABI)
        formData.append("previousImage",editRecords?.currencyImage)
        formData.append("id",editRecords?._id)
        const validation = await currencyFieldVaidation()
        console.log("validation_addCur", validation)
        if (validation) {
            const update = await updateCurrency(formData);
            if(update){
                toastAlert('success', 'Currency Updated Successfully');
                props.onDismiss()
            }
            console.log("New_update_currency", update)
        }
    }

    const currencyFieldVaidation = async (data) => {
        try {
            let errors = {}
            if (currencyName == null || currencyName == undefined || currencyName == '') {
                errors.currencyName = "CurrencyName is required"
            }
            if (currencyImage == null || currencyImage == undefined || currencyImage == '') {
                errors.currencyImage = "CurrencyImage is required"
            }
            setError(errors)

            if(Object.keys(errors).length > 0)
            {
                // console.log(document.querySelectorAll(".error_modal_red")[0],"id 0");
                var scrollyoffset = document.querySelectorAll(".error_modal_red")[0].offsetTop - 100;
                // console.log(scrollyoffset,"scrollyoffset")
                // document.getElementById("modal_scroll").scrollToStart({animated: true})
                document.querySelectorAll("#modal_scroll>div")[0].scrollTo(0,scrollyoffset);
            }
            return Object.keys(errors).length > 0 ? false : true
        } catch (e) {
            console.log('currencyFieldVaidation', e);
        }
    }

    useEffect(() => {
        console.log('CurrencyEditModel', props.data);
        setEditRecords(props.data)
        setCurrencyName(props.data.currencyName)
        setCurrencyImage(props.data.currencyImage)
        setMinABI(props.data.minABI)
    }, [props])
    return (
        <Modal className="wallet-modal" show={leverageModal} centered size="sm">
            <Modal.Header className="pb-0">
                <div>
                    <h3 className="sec-head ">Update Currency</h3>
                </div>
                <button type="button" class="close" onClick={() => props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

            </Modal.Header>
            <Modal.Body className="select-wallet pt-0">
                <Scrollbars className="modal_scroll_sm"  
                // ref={ref => {this.scrollView = ref}} 
                style={{ height: 100 }} id="modal_scroll">
                    <form className="dashboard_form pad_right_das mt-4 mb-4">
                       
                        <label>Currency Symbol *</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">
                            <input type="text" className="form-control border_full" placeholder="Enter Currency Symbol" aria-label="multiplier" aria-describedby="basic-addon2" value={editRecords.currencySymbol} disabled onChange={(e)=> {setCurrencySymbol(e.target.value)}}/>
                        </div>
                        <p className='error_modal_red'>{errror?.currencySymbol}</p>


                        <label>Currency Name *</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">
                            <input type="text" className="form-control border_full" placeholder="Enter Currency Name" aria-label="managementfee" value = {currencyName} aria-describedby="basic-addon2" onChange={(e)=> {setCurrencyName(e.target.value)}}/>
                        </div>

                        <p className='error_modal_red'>{errror?.currencyName}</p>


                        <label>Currency Image *</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">

                        <div className="custom-file mt-2">
                                        <input type="file" className="custom-file-input" id="customFile" placeholder="Choose Currency Image" aria-label="managementfee" aria-describedby="basic-addon2" onChange={(e)=> {console.log('fileOnchange',e.target.files[0]); setCurrencyImage(e.target.files[0])}} />
                                        <label className="custom-file-label" for="customFile">{currencyImage == '' ? 'Choose file' : editRecords.currencyImage == "" ? currencyImage.name : editRecords.currencyImage}</label>
                                        {/* {editRecords.currencyImage != "" ? <img src={`${IMG_URL}/assets/currency/${editRecords.currencyImage}`}/> : <img src={currencyImage}/>} */}
                                        </div>

                        </div>
                        <p className='error_modal_red'>{errror?.currencyImage}</p>

                        
                        <label>Network</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">
                            <input type="text" className="form-control border_full" placeholder="Enter Network" aria-label="managementfee" aria-describedby="basic-addon2" disabled value={editRecords.network} onChange={(e)=> {setNetwork(e.target.value)}}/>
                        </div>

                        <label>Currency Type</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">
                            <input type="text" className="form-control border_full" placeholder="Enter Currency Type" aria-label="managementfee" aria-describedby="basic-addon2" disabled value={editRecords.currencyType}  onChange={(e)=> {setCurrencyType(e.target.value)}}/>
                        </div>

                        {
                            editRecords?.currencyType == 'Token' &&
                            <>
                                <label>Token Type</label>
                                <div className="input-group mb-3 input_grp_dash mt-2">
                                    <input type="text" className="form-control border_full" placeholder="Enter Token Type" aria-label="managementfee" aria-describedby="basic-addon2" disabled value={editRecords.tokenType} onChange={(e) => { setTokenType(e.target.value) }} />
                                </div>
                                <label>MIN-ABI</label>
                                <div className="input-group mb-3 input_grp_dash mt-2">
                                    <input type="text" className="form-control border_full" placeholder="Enter Contract ABI" aria-label="managementfee" aria-describedby="basic-addon2" value= {minABI} onChange={(e) => { setMinABI(e.target.value) }} />
                                </div>

                                <label>Contract Address</label>
                                <div className="input-group mb-3 input_grp_dash mt-2">
                                    <input type="text" className="form-control border_full" placeholder="Enter Contract Address" aria-label="managementfee" aria-describedby="basic-addon2" disabled value={editRecords.contractAddress} onChange={(e) => { setContractAddress(e.target.value) }} />
                                </div>
                            </>
                        }

                        <div className='text-center mt-5'>
                            <button type="button" onClick={()=> {editCurrency()}} className="get-started-btn text-uppercase" > 
                            Update</button>
                        </div>
                    </form>
                </Scrollbars>

            </Modal.Body>
        </Modal>
    )
}

export default CurrencyEditModel