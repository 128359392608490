import { WALLET_ADDRESS,WALLETS } from '../action';

const initialState = {
  walletAddress: "",
  wallets:[]
};

const frontReducer = (state = initialState, action) => {
  switch (action.type) {
    case WALLET_ADDRESS:
      return { ...state, walletAddress: action.payload };
      break;
      case WALLETS:
            return { ...state, wallets: action.payload };
            break;
    default:
      return state;
  }
};


export default frontReducer