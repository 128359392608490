
import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import { Modal } from 'react-bootstrap';

import modalimg from "../../images/doll.png";
import eth from "../../images/eth.png"

import { Scrollbars } from 'react-custom-scrollbars';
import { UnStake } from '../../hooks/useUserStaking';

const Withdrawcoinmodal = (props) => {
    const [withdrawcoinModal, setWithdrawcoinModal] = useState(true)
    const [amount, setAmount] = useState(0)

    const withdraw = async ()=> {
        const unstake = await UnStake(localStorage.getItem('accountInfo'),2,amount,18)
    }
    return (
        <Modal className="wallet-modal" show={withdrawcoinModal} centered size="sm" dialogClassName={!withdrawcoinModal ? "modal-90w dark-bg fade " : "modal-90w dark-bg showfull"}>
            <Modal.Header className="pb-0">

                <button type="button" className="close depositcoinclose" onClick={() => props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

            </Modal.Header>
            <Modal.Body className="select-wallet pt-0 pb-5 px-0">
                <Scrollbars className="modal_scroll" style={{ height: 100 }}>
                    <div className='pr-2'>
                        <div className='text-center'>
                            <img src={modalimg} className="img-fluid modal_img" />
                        </div>
                        <div className='text-center mt-3'>
                            <button className="get-started-btn btn_head mb-4 ml-3">
                                Withdraw</button>
                        </div>
                        <form className='px-3 px-md-5 mt-4 pb-5'>
                            <label className='text_yellow text-uppercase text-left'>Max : 0</label>
                            <div className="input-group mb-3 input_grp_big">
                                <input type="text" value={amount} className="form-control" placeholder="USDT Balance" aria-label="Recipient's username" aria-describedby="basic-addon2" onChange={(e)=> {setAmount(e.target.value)}}/>
                                <div className="input-group-append">
                                    <span className="input-group-text" id="basic-addon2">
                                        <img src={eth} className="coin_dd_img" />
                                    </span>
                                </div>
                            </div>

                            <div className='row mt-4 justify-content-between'>
                                <div className='col-6'>
                                    <button className='btn btn_trans_click p-0' disabled>
                                        <div className='card card_modal'>
                                            <div className='card-body'>
                                                <div className='icon_bg_white'>
                                                    <i className="fa fa-thumbs-up" aria-hidden="true"></i>
                                                </div>
                                                <p className='change_color mt-3 mb-0'>Approve</p>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                                <div className='col-6 text-right'>
                                    <button className='btn btn_trans_click p-0' type='button' onClick={()=> {withdraw()}}>
                                        <div className='card card_modal ml-auto'>
                                            <div className='card-body'>
                                                <div className='icon_bg_white'>
                                                    <i className="fa fa-arrow-down" aria-hidden="true"></i>
                                                </div>
                                                <p className='change_color mt-3 mb-0'>Withdraw</p>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Scrollbars>
            </Modal.Body>
        </Modal>
    )
}



export default Withdrawcoinmodal