/** Packages */
import { useWeb3 } from "./useWeb3";
import toast, { Toaster } from 'react-hot-toast';

/** Hooks */
import { ERC20_ABI } from "../config/stakeERC20";
import { MASTERCHEF_ABI , MASTERCHIEF} from "../config/stakeMasterChef";

/** Actions */
import { createStakingTokens } from "../components/action/staking";
import { EXCHANGEMASTERCHIEF } from "../config/exchangeMasterChef";

export const UseERC20 = async (VALUE) => {
    try {
        const web3 = await useWeb3();
        const erc20Contract = new web3.eth.Contract(ERC20_ABI, VALUE);
        // console.log("Token_Erc20", erc20Contract.methods)
        return erc20Contract;
    } catch (e) {
        console.log('UseERC20_err', e);
    }
}


export const UseMasterChef = async () => {
    try {
        const web3 = await useWeb3();
        const contract = new web3.eth.Contract(MASTERCHEF_ABI, MASTERCHIEF);
        // console.log("UseMasterChef:contract------------>", contract.methods)
        return contract;
    } catch (e) {
        console.log('UseMasterChef_err', e);
    }
}


export const UseAddToken = async (account,payload) => {
    try {
        const data = payload
        const createToken = await createStakingTokens(data)
        const contract = await UseMasterChef();
        const addToken = await contract.methods.add().send({ from: account });
    } catch (e) {
        console.log('UseMasterChef_err', e);
    }
}

export const approveToken = async (account, token) => {
    try {
        console.log('approveContract', {account : account, token: token});
        const erc20Contract = await UseERC20(token);
        console.log('erc20contract_tokenInfo',erc20Contract.methods);
        const data = erc20Contract.methods.approve(EXCHANGEMASTERCHIEF, "115792089237316195423570985008687907853269984665640564039457584007913129639935").send({ from: account })
        await toast.promise(data, {
            loading: 'Approving...',
            success: 'Approved Successfully',
            error: 'Try Again',
        }, {
            style: {
                minWidth: '300px',
                minHeight: '55px'
            }
        })
        return data
    } catch (e) {
        console.log('approveContract_err', e);
    }
}

export const checkIsApproved = async (account, token) => {
    try {
        const erc20contract = await UseERC20(token);
        const allow = await erc20contract.methods.allowance(account, MASTERCHIEF).call();
        console.log('checkIsApproved', { account: account, token: token, allow: allow });
        return (parseInt(allow) > 0) ? true : false;
    } catch (e) {
        console.log('checkIsApproved_err', e);
    }
}


