import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Tabs, Tab } from 'react-bootstrap';
import '../../css/styles.css';

import Adminheader from '../Adminheader';
import Adminsidebar from '../Adminsidebar';
import Footer from '../Footer';

import ExchangeCmsModel from "./ExchangeCmsModel";


import whitelist from "../../images/whitelist.png"
import users from "../../images/users.png"
import bur from "../../images/bur.png"


import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';

import { columns3, data3 } from "./data";
import { getCms, getTokens } from '../action/admin';

const ExchangeCms = () => {


    const [featureModal, setFeatureModal] = useState(false)
    const [cms, setCms] = useState([])
    const [editData, setEditData] = useState()

    const onDismissfeature = () => {
        setFeatureModal(false)
    }
    const columns3 = [
        {
            name: "Identifier",
            selector: "identifier",
            sortable: true
        },
        {
            name: "createdAt",
            selector: "createdAt",
            sortable: true
        },
        {
            name: "Page",
            selector: "page",
            sortable: true,
            // cell : (record) => {return record.type.toUpperCase()}
        },
        {
            name: "Action",
            selector: "type",
            sortable: true,
            cell: (record) => {
                return (
                    <button className="get-started-btn text-uppercase" onClick={() => { setEditData(record); setFeatureModal(true) }}>
                        Edit</button>
                )
            }

        }
    ];

    useEffect(() => {
        getCmsDetails()
    }, [])

    const getCmsDetails = async () => {
        const getData = await getCms('exchange');
        console.log("getTokensDetails", getData)
        setCms(getData)
    }

    return (
        <div>

            <Adminheader />
            <div className='row main_view mx-0 w-100'>
                <div className='col-1 col_sidebar'>
                    <Adminsidebar />
                </div>
                <div className='col-11 main_dash_sec pb-4 col_11_admin'>
                    <div className='row'>
                        <div className='col-12 col-md-10 col-lg-9 mx-auto'>
                            <div className='mt_main_sec text-center'>
                                <button type="button" className="get-started-btn btn_head mb-4 ml-3">
                                    CMS</button>
                            </div>
                            <div className='mt_main_sec'>
                                <div className='text-right mt-5 mb-5'>

                                </div>
                                <div className='mt-3 pb-5'>
                                    <div className='table-responsive'>

                                        {
                                            cms && cms.length > 0 ?
                                                <DataTable className='table table_user table_admin mb-0'
                                                    noHeader
                                                    sortable={false}
                                                    columns={columns3}
                                                    data={cms}
                                                    pagination
                                                /> : <p className='no_data_texter text-center'>No data</p> 
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {featureModal && <ExchangeCmsModel connect={"string"} onDismiss={() => { onDismissfeature() }} data={editData} />}

        </div>
    );
}

export default ExchangeCms