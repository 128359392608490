import React, { Component } from 'react';
import { useContext, useEffect, useState } from "react";
import { Modal } from 'react-bootstrap';

import modalimg from "../images/announcement2.png";


import { Scrollbars } from 'react-custom-scrollbars';



class Announcementmodal extends Component
{
    constructor(props) {
        super(props);
        this.state = {          
            depositModal: true         

           
        };
    }
  
    


    render() {
        
  
        const {depositModal} = this.state

        
      return (
          <div>

              
<div id="announcemodal" className="wallet-modal" show={depositModal} centered size="sm" dialogClassName= {!depositModal ? "modal-dialog  modal-90w dark-bg fade modal_announcement modal-sm modal-dialog-centered" : "modal-dialog  modal-sm modal-dialog-centered modal-90w dark-bg showfull modal_announcement"}>
<Modal.Header className="pb-0">
    
    <button type="button" className="close" onClick={() => this.props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

</Modal.Header>
<Modal.Body className="select-wallet pt-0 pb-3 px-0">
<Scrollbars className="modal_scroll" style={{ height: 100 }}>
    <div className='pr-2 pb-3'>
    <div className='text-center'>
<img src={modalimg} className="img-fluid modal_img" />
</div>
<div className='text-center mt-3'>
<button className="get-started-btn btn_head mb-4 ml-3">
Announcement</button>
</div>
<div className='px-3 px-md-4'>
<p className='announcement_text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
<p className='announcement_text'>Ut enim ad minim veniam, laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
</div>
</div>
    </Scrollbars>
</Modal.Body>
             
</div>


</div>
      )
    }

}


export default Announcementmodal