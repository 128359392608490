// import package
import io from 'socket.io-client';

// import lib
import * as config from './env'
import {toastAlert} from '../lib/toast'


const socket = io(config.SOCKEt_URL, { secure: true, transports: ['polling'], jsonp: false, rejectUnauthorized: false });

/** Create-Socket-User */
const createSocketUser = (address) => {
    socket.emit('CREATEROOM', address)
}


/** Disconnect-User */
socket.on('disconnect', (reason) => {
    console.log(reason, 'disconnect_reason', socket.room)
    toastAlert('error', 'Please reload the page')

});
export {
    socket,
    createSocketUser
}