import React, { Component, useState } from 'react';
import { Dropdown, Modal } from 'react-bootstrap';

import wall1 from "../../images/metamask_wallet.png";
import wall2 from "../../images/connect_wallet.png";

import { Scrollbars } from 'react-custom-scrollbars';
import { addTokensDetails } from '../action/admin';
import { toastAlert } from '../../lib/toast';

const
    TokenListModal = (props) => {
        const [featureModal, setFeatureModal] = useState(true)
        const [name, setName] = useState("")
        const [symbol, setSymbol] = useState("")
        const [decimal, setDecimal] = useState("")
        const [address, setAddress] = useState("")
        const [url, setUrl] = useState("")
        const [file, setFile] = useState("")
        const [fileName, setFileName] = useState("")
        const [chainid, setChainid] = useState("")
        const [type, setType] = useState("")
        const [error, setError] = useState({})
        const Types = [
            { name: "Testnet", value: "testnet" },
            { name: "Mainnet", value: "mainnet" }
        ]
        const validation = async () => {
            try {
                let errors = {}
                if (name == '' || name == undefined || name == null) {
                    errors['name'] = "Name Field is required"
                } if (symbol == '' || symbol == undefined || symbol == null) {
                    errors['symbol'] = "Symbol Field is required"
                } if (decimal == '' || decimal == undefined || decimal == null) {
                    errors['decimal'] = "Decimal Field is required"
                } if (address == '' || address == undefined || address == null) {
                    errors['address'] = "Address Field is required"
                } if (url == '' || url == undefined || url == null) {
                    errors['url'] = "Url Field is required"
                } if (file == '' || file == undefined || file == null) {
                    errors['file'] = "File Field is required"
                } if (chainid == '' || chainid == undefined || chainid == null) {
                    errors['chainid'] = "Chainid Field is required"
                } if (type == '' || type == undefined || type == null) {
                    errors['type'] = "Type Field is required"
                }

                setError(errors)
                return Object.keys(errors).length > 0 ? false : true
            } catch (e) {
                console.log("validation_err", e);
            }
        }

        const onSubmit = async () => {
            try {
                const validate = await validation();
                if (validate) {
                    const formData = new FormData();
                    formData.append("name", name)
                    formData.append("symbol", symbol)
                    formData.append("decimal", decimal)
                    formData.append("address", address)
                    formData.append("url", url)
                    formData.append("file", file)
                    formData.append("chainid", chainid)
                    formData.append("type", type)
                    const add = await addTokensDetails(formData);
                    console.log('add_addTokensDetails', add)
                    if (add.status) {
                        toastAlert("success", add.message)
                        props.onDismiss()
                    } else {
                        toastAlert("error", add.message)
                    }
                }

            } catch (e) {
                console.log("onChangeFile_err", e)
            }
        }


        return (
            <Modal className="wallet-modal" show={featureModal} centered size="sm" id="feature_modal">
                <Modal.Header className="pb-0">
                    <div>
                        <h3 className="sec-head ">Add Token</h3>
                    </div>
                    <button type="button" class="close" onClick={() => props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

                </Modal.Header>
                <Modal.Body className="select-wallet pt-0">
                    <Scrollbars className="modal_scroll_sm" style={{ height: 100 }}>
                        <div className="dashboard_form mt-4 mb-4 pad_right_das">
                            <label>Name:</label>
                            <div className="input-group mb-3 input_grp_dash mt-2">
                                <input type="text" className="form-control border_full" placeholder="Enter Name" aria-label="featuretitle" aria-describedby="basic-addon2"
                                    onChange={(e) => { setName(e.target.value) }}
                                />
                                <div className='w-100 ms-1'><span className='text-danger'>{error?.name}</span></div>
                            </div>

                            <label>Symbol:</label>
                            <div className="input-group mb-3 input_grp_dash mt-2">
                                <input type="text" className="form-control border_full" placeholder="Enter Symbol" aria-label="featuretitle" aria-describedby="basic-addon2"
                                    onChange={(e) => { setSymbol(e.target.value) }}
                                />
                                <div className='w-100 ms-1'><span className='text-danger'>{error?.symbol}</span></div>
                            </div>

                            <label>Decimal:</label>
                            <div className="input-group mb-3 input_grp_dash mt-2">
                                <input type="number" className="form-control border_full" placeholder="Enter Decimal" aria-label="featuretitle" aria-describedby="basic-addon2"
                                    onChange={(e) => { setDecimal(e.target.value) }}
                                />
                                <div className='w-100 ms-1'><span className='text-danger'>{error?.decimal}</span></div>
                            </div>

                            <label>Address:</label>
                            <div className="input-group mb-3 input_grp_dash mt-2">
                                <input type="text" className="form-control border_full" placeholder="Enter Address" aria-label="featuretitle" aria-describedby="basic-addon2"
                                    onChange={(e) => { setAddress(e.target.value) }}
                                />
                                <div className='w-100 ms-1'><span className='text-danger'>{error?.address}</span></div>
                            </div>

                            <label>Chain Id:</label>
                            <div className="input-group mb-3 input_grp_dash mt-2">
                                <input type="text" className="form-control border_full" placeholder="Enter Chain-Id" aria-label="featuretitle" aria-describedby="basic-addon2"
                                    onChange={(e) => { setChainid(e.target.value) }}
                                />
                                <div className='w-100 ms-1'><span className='text-danger'>{error?.chainid}</span></div>
                            </div>

                            <label>Type:</label>
                            <div className="input-group mb-3 input_grp_dash mt-2">
                                <Dropdown className='btn_grey_dd dd_posit_sort dd_posit_sort_fullw'>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        <div className='d-flex'>

                                            <span>{type != "" ? type.toUpperCase() : "select type"}</span>
                                            <span className='arrow_bg'><i className="fa fa-angle-down" aria-hidden="true"></i></span>

                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='dd_menu_grey sort_dd_menu_grey sort_dd_menu_grey_full'>

                                        {Types.map((val, i) => {
                                            return (

                                                <Dropdown.Item onClick={() => {
                                                    console.log("data", "dats", val.value)
                                                    setType(val.value)
                                                }}>
                                                    <div className='d-flex px-3'>

                                                        <span>{val.name}</span>

                                                    </div>
                                                </Dropdown.Item>
                                            )
                                        })
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                                <div className='w-100 ms-1'><span className='text-danger'>{error?.type}</span></div>
                            </div>

                            <label>Url:</label>
                            <div className="input-group mb-3 input_grp_dash mt-2">
                                <input type="text" className="form-control border_full" placeholder="Enter Url" aria-label="featuretitle" aria-describedby="basic-addon2"
                                    onChange={(e) => { setUrl(e.target.value) }}
                                />
                                <div className='w-100 ms-1'><span className='text-danger'>{error?.url}</span></div>
                            </div>
                            {console.log("errrrrrrrrrrrrrrrrrr", error)}
                            <label>Image:</label>
                            {/* <div className="custom-file mt-2">
                                <input type="file" className="custom-file-input" id="customFile"
                                    onChange={(e) => {
                                        if (['JPG', 'JPEG', 'PNG'].includes((e.target.files[0].name).split(".")[e.target.files[0].name.split('.').length - 1].toUpperCase())) {
                                            setFile(e.target.files[0])
                                            setFileName(e.target.files[0].name)
                                        } else {
                                            console.log("dfadadsfasd")
                                            setError(prevState => ({
                                                ...prevState,
                                                file: "Invalid file formate",
                                            }))
                                        }
                                    }}
                                />
                                <label className="custom-file-label" for="customFile">{fileName == '' ? "Choose file" : fileName}</label>
                                <div className='w-100 ms-1'><span className='text-danger'>{error?.file}</span></div>
                            </div> */}
                            <div className="input-group mb-3 input_grp_dash mt-2">
                                <input type="text" className="form-control border_full" placeholder="Enter logoURI" aria-label="featuretitle" aria-describedby="basic-addon2"
                                    onChange={(e) => { setFile(e.target.value) }}
                                />
                                <div className='w-100 ms-1'><span className='text-danger'>{error?.url}</span></div>
                            </div>

                            <div className='text-center mt-5 pb-3'>
                                <button className="get-started-btn text-uppercase" onClick={() => onSubmit()}> Add</button>
                            </div>

                        </div>
                    </Scrollbars>

                </Modal.Body>
            </Modal>
        )
    }


export default TokenListModal