/** Packages */
import BigNumber from 'bignumber.js';
import toast, { Toaster } from 'react-hot-toast';

/** Contract_files */
import { UseMasterChef } from "./useUserContract";



export const getPool = async (pid) => {
    try {
        const stakecontract = await UseMasterChef();
        const Pool = await stakecontract.methods.poolInfo(pid).call();
        return Pool;
    } catch (e) {
        console.log('getPool_err', e);
    }
}

export const getPoolLength = async () => {
    try {
        const stakecontract = await UseMasterChef();
        // console.log("stakecontract_stakecontract", stakecontract);
        const length = await stakecontract.methods.poolLength().call();
        // console.log("length_length_lengthlength", length);
        return length;
    } catch (e) {
        console.log('getPoolLength_err', e);

    }
}

export const OnStake = async (account, pid, amount, decimal) => {
    try {
        console.log("OnStake_OnStake", pid, amount, account)
        const contract = await UseMasterChef();
        const convertedAmount = getWholeNumber(amount, decimal);
        console.log("convertedAmount", convertedAmount);
        const data = contract.methods.deposit(pid.toString(), convertedAmount.toString(), account).send({ from: account }).on('transactionHash', async (tx) => {
            localStorage.setItem("HASH", JSON.stringify(tx));
        })

        await toast.promise(data, {
            loading: 'Depositing ...',
            success: 'Deposit Successfully',
            error: 'Try Again',
        }, {
            style: {
                minWidth: '300px',
                minHeight: '55px'
            }
        })
    } catch (error) {
        console.log("error on onstake", error);
    }

}

export const UnStake = async (account, pid, amount, decimal) => {
    try {
        console.log("Unstake", account, pid, amount);
        const contract = await UseMasterChef();
        const convertedAmount = toFixedNumber(amount * 10 ** decimal);
        console.log("convertedAmount", convertedAmount);
        const data = contract.methods.withdraw(pid, convertedAmount.toString()).send({ from: account });
        console.log("data-----", data);
        await toast.promise(data, {
            loading: 'Withdrawing ...',
            success: 'Withdraw Successfully',
            error: 'Try Again',
        }, {
            style: {
                minWidth: '300px',
                minHeight: '55px'
            }
        })
    } catch (e) {
        console.log('UnStake_err', e);
    }
}


/** Calculations */


export const getWholeNumber = (value, decimal) => {
    console.log("decimal_decimal_decimaldecimal", decimal);
    try {
        const balance = new BigNumber(value).times(new BigNumber(10).pow(decimal));
        let finalval = (balance).toNumber();
        finalval = toFixedNumber(finalval);
        return finalval.toString();
    } catch (e) {

    }
}



export const toFixedNumber = (x) => {
    if (Math.abs(x) < 1.0) {
        var e = parseInt(x.toString().split('e-')[1]);
        if (e) {
            x *= Math.pow(10, e - 1);
            x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
        }
    } else {
        var e = parseInt(x.toString().split('+')[1]);
        if (e > 20) {
            e -= 20;
            x /= Math.pow(10, e);
            x += (new Array(e + 1)).join('0');
        }
    }
    return x;
}
