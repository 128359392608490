import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import modalimg from "../../images/doll.png";
import usdt from "../../images/usdt.png";
import { Scrollbars } from "react-custom-scrollbars";
import {
  Col,
  Row,
  Container,
  Nav,
  Dropdown,
  Tabs,
  Tab,
  Table,
  Button,
} from "react-bootstrap";
// import J1 from "../../images/j1.PNG";
// import J2 from "../../images/j2.PNG";
import J1 from "../../images/etherum.png";
import J2 from "../../images/bitcoin.png";
import { getAssetData } from "../action/user";
import { IMG_URL } from "../../config/env";
const Depositmodal3 = (props) => {
  const [depositModal3, setDepositModal3] = useState(true);
  // const [assests, SetAssests] = useState([]);
  const dispatch = useDispatch();
  const assests = useSelector((state) => state.Auth.wallets);

  useEffect(() => {
    // getAssetlist();
  }, []);

  const getAssetlist = async () => {
    const data = await getAssetData(
      { walletAddress: localStorage.getItem("accountInfo") },
      dispatch
    );
    console.log("asset_data", data);
    // SetAssests(data);
  };

  // const assests = [
  //   // {
  //   //   currencySymbol: "BWD",
  //   //   currencyId: {
  //   //     currencyAddress: "BITcoin",
  //   //   },
  //   //   balance: "12133",
  //   // },
  //   // {
  //   //   currencySymbol: "BWD",
  //   //   currencyId: {
  //   //     currencyAddress: "BITcoin",
  //   //   },
  //   //   balance: "12133",
  //   // },
  //   // {
  //   //   currencySymbol: "BWD",
  //   //   currencyId: {
  //   //     currencyAddress: "BITcoin",
  //   //   },
  //   //   balance: "12133",
  //   // },
  //   // {
  //   //   currencySymbol: "BWD",
  //   //   currencyId: {
  //   //     currencyAddress: "BITcoin",
  //   //   },
  //   //   balance: "12133",
  //   // },
  //   // {
  //   //   currencySymbol: "BWD",
  //   //   currencyId: {
  //   //     currencyAddress: "BITcoin",
  //   //   },
  //   //   balance: "12133",
  //   // },
  //   // {
  //   //   currencySymbol: "BWD",
  //   //   currencyId: {
  //   //     currencyAddress: "BITcoin",
  //   //   },
  //   //   balance: "12133",
  //   // },
  //   // {
  //   //   currencySymbol: "BWD",
  //   //   currencyId: {
  //   //     currencyAddress: "BITcoin",
  //   //   },
  //   //   balance: "12133",
  //   // },
  //   // {
  //   //   currencySymbol: "BWD",
  //   //   currencyId: {
  //   //     currencyAddress: "BITcoin",
  //   //   },
  //   //   balance: "12133",
  //   // },
  //   // {
  //   //   currencySymbol: "BWD",
  //   //   currencyId: {
  //   //     currencyAddress: "BITcoin",
  //   //   },
  //   //   balance: "12133",
  //   // },
  //   // {
  //   //   currencySymbol: "BWD",
  //   //   currencyId: {
  //   //     currencyAddress: "BITcoin",
  //   //   },
  //   //   balance: "12133",
  //   // },
  //   // {
  //   //   currencySymbol: "BWD",
  //   //   currencyId: {
  //   //     currencyAddress: "BITcoin",
  //   //   },
  //   //   balance: "12133",
  //   // },
  //   // {
  //   //   currencySymbol: "BWD",
  //   //   currencyId: {
  //   //     currencyAddress: "BITcoin",
  //   //   },
  //   //   balance: "12133",
  //   // },
  //   // {
  //   //   currencySymbol: "BWD",
  //   //   currencyId: {
  //   //     currencyAddress: "BITcoin",
  //   //   },
  //   //   balance: "12133",
  //   // },
  //   // {
  //   //   currencySymbol: "BWD",
  //   //   currencyId: {
  //   //     currencyAddress: "BITcoin",
  //   //   },
  //   //   balance: "12133",
  //   // },
  //   // {
  //   //   currencySymbol: "BWD",
  //   //   currencyId: {
  //   //     currencyAddress: "BITcoin",
  //   //   },
  //   //   balance: "12133",
  //   // },
  //   // {
  //   //   currencySymbol: "BWD",
  //   //   currencyId: {
  //   //     currencyAddress: "BITcoin",
  //   //   },
  //   //   balance: "12133",
  //   // },
  //   // {
  //   //   currencySymbol: "BWD",
  //   //   currencyId: {
  //   //     currencyAddress: "BITcoin",
  //   //   },
  //   //   balance: "12133",
  //   // },
  //   // {
  //   //   currencySymbol: "BWD",
  //   //   currencyId: {
  //   //     currencyAddress: "BITcoin",
  //   //   },
  //   //   balance: "12133",
  //   // },
  //   // {
  //   //   currencySymbol: "BWD",
  //   //   currencyId: {
  //   //     currencyAddress: "BITcoin",
  //   //   },
  //   //   balance: "12133",
  //   // },
  //   // {
  //   //   currencySymbol: "BWD",
  //   //   currencyId: {
  //   //     currencyAddress: "BITcoin",
  //   //   },
  //   //   balance: "12133",
  //   // },
  //   // {
  //   //   currencySymbol: "BWD",
  //   //   currencyId: {
  //   //     currencyAddress: "BITcoin",
  //   //   },
  //   //   balance: "12133",
  //   // },
  //   // {
  //   //   currencySymbol: "BWD",
  //   //   currencyId: {
  //   //     currencyAddress: "BITcoin",
  //   //   },
  //   //   balance: "12133",
  //   // },
  //   // {
  //   //   currencySymbol: "BWD",
  //   //   currencyId: {
  //   //     currencyAddress: "BITcoin",
  //   //   },
  //   //   balance: "12133",
  //   // },
  //   // {
  //   //   currencySymbol: "BWD",
  //   //   currencyId: {
  //   //     currencyAddress: "BITcoin",
  //   //   },
  //   //   balance: "12133",
  //   // },
  // ];
  return (
    <>
      {/* <div className="did1">
        <Modal
          left
          className="orderbook-modal kr_ordermodal assetmodal"
          show={depositModal3}
          centered
          size="xl"
          dialogClassName={
            !depositModal3
              ? "modal-90w modal-xl assetmodalleft left dark-bg fade "
              : "modal-90w assetmodalleft modal-xl left dark-bg showfull"
          }
        >
          <Modal.Header className="pb-0">
            <button
              type="button"
              className="close"
              onClick={() => props.onDismiss()}
            >
              <span aria-hidden="true">×</span>
              <span class="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body className="select-wallet orderbook pt-5 pb-5 px-0">
            <Scrollbars className="modal_scroll" style={{ height: 100 }}>
              <div className="pr-2">
                <div className="text-center mt-3">
                  <button className="get-started-btn custom_header_burger btn_head mb-4">
                    Assets
                  </button>
                  <p className="orderbook-p">
                    Lorem Ipsum is simply dummy text of the printing
                  </p>
                </div>

                <div className="mt-5 mb-5 assettable">
                  <div className="card card_table2 mt-3 we assetwidth">
                    <div className="tables">
                      <Table responsive="xs">
                        <thead>
                          <tr className="assettablehead">
                            <th>SNO.</th>
                            <th>Symbol</th>
                            <th>Currency Address</th>
                            <th>Available Balance</th>
                          </tr>
                        </thead>
                        <tbody>
                          {assests && assests.length > 0 ? (
                            assests.map((value, index) => (
                              <tr className="assettable1">
                                <td>{index + 1}</td>
                                <td>
                                  <span>
                                    <img
                                      src={index == 1 ? J1 : J2}
                                      className="img-fluid etherumimg"
                                    />
                                  </span>{" "}
                                  <span
                                    style={{
                                      color: "#d88c29",
                                      marginLeft: "0px",
                                    }}
                                  >
                                    {" "}
                                    {value.currencySymbol}
                                  </span>
                                </td>
                                <td>{value.currencyId.currencyAddress}</td>
                                <td>{value.balance}</td>
                              </tr>
                            ))
                          ) : (
                            <>
                              <tr className="border-none bg-transparent no_data_fnd_bg">
                                <td colSpan={4}>
                                  <p className="mb-0 p-2 text-center text-white no_data_fnd_txt">
                                    'NO Data Found'{" "}
                                  </p>
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </Scrollbars>
          </Modal.Body>
        </Modal>
      </div> */}

      <div className="did1 asset_modal">
        <div className="orderbook-modal kr_ordermodal assetmodal">
          <div className="select-wallet orderbook pb-3 px-0">
            <Scrollbars
              className={
                assests?.length > 0
                  ? "modal_scroll custom_scroll"
                  : "modal_scroll_cus custom_scroll"
              }
              style={{ height: 200 }}
            >
              <div className="">
                <div className="mt-3 mb-3 assettable">
                  <div className="card card_table2 mt-3 we assetwidth">
                    <div className="tables custom_tabele">
                      <Table responsive>
                        <thead>
                          <tr className="assettablehead">
                            <th>SNO.</th>
                            <th>Symbol</th>
                            <th style={{minWidth:"120px"}}>Currency Address</th>
                            <th style={{minWidth:"120px"}}>Available Balance</th>
                          </tr>
                        </thead>
                        <tbody>
                          {assests && assests.length > 0 ? (
                            assests.map((value, index) => (
                              <tr className="assettable1">
                                <td>{index + 1}</td>
                                <td>
                                  <span>
                                    <img
                                      src={index == 1 ? J1 : J2}
                                      className="img-fluid etherumimg"
                                    />
                                  </span>{" "}
                                  <span
                                    style={{
                                      color: "#d88c29",
                                      marginLeft: "0px",
                                    }}
                                  >
                                    {" "}
                                    {value.currencySymbol}
                                  </span>
                                </td>
                                <td>{value.currencyId.currencyAddress}</td>
                                <td>{value.balance}</td>
                              </tr>
                            ))
                          ) : (
                            <>
                              <tr className="border-none bg-transparent no_data_fnd_bg">
                                <td colSpan={4}>
                                  <p className="mb-0 p-2 text-center text-white no_data_fnd_txt">
                                    'NO Data Found'{" "}
                                  </p>
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </Scrollbars>
          </div>
        </div>
      </div>
    </>
  );
};

export default Depositmodal3;
