/** Packages */
import React, { useState, useEffect, useRef, useContext, useMemo }from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Tabs, Tab } from 'react-bootstrap';

/** Files */
import '../../css/styles.css';
import Innerheader from '../Innerheader';
import Sidebar from '../Sidebar';
import Footer from '../Footer';
import Lendingcard from './Lendingcard';
import Announcementmodal2 from "../../components/Announcementmodal2";

/** Hooks */
import { UseMasterChef , UseERC20, checkIsApproved} from '../../hooks/useUserContract';
import { getPool, getPoolLength } from '../../hooks/useUserStaking';

/**Action */
import { getStakingTokensInfo } from '../action/staking';

const Lending = (props) => {

  const [announcementModal2, setAnnouncementModal2] = useState(false)
  const [pools, setpools] = useState([])

  const onDismiss1 = () => { setAnnouncementModal2(false) }

  useEffect(()=> {
    setAnnouncementModal2(localStorage.getItem("accountInfo") != "" ? false : true)
    UseERC20()
    poolDetails()
  },[])


  const poolDetails = async () => {
    try {
      const arr = []
      const length = await getPoolLength();
      for (let i = 0; i < length; i++) {
        let getPools = await getPool(i);
        let checkTokenApporval = await checkIsApproved(localStorage.getItem("accountInfo"), getPools?.lpToken);
        let data = await getStakingTokensInfo({ tokenAddress: getPools?.lpToken });
        if(getPools?.lpToken == data.tokenAddress){
          getPools['tokenDetails'] = data
          getPools['approve'] = checkTokenApporval
        }
        arr.push(getPools)
      }
      setpools(arr)
      // console.log('poolDetails', arr);
    } catch (e) {
      console.log("poolDetails_err", e);
    }
  }
  return (
    <div>

      <Innerheader />
      <div className='row main_view mx-0'>
        <div className='col-1 col_sidebar'>
          <Sidebar />
        </div>
        <div className='col-11 main_dash_sec pb-4 col_11_admin'>
          <div className='mt_main_sec'>
            <div className='text-center'>
              <button className="get-started-btn btn_head mb-4 ml-3">
                Lending</button>
            </div>
            <div className='row mt-5 mb-5 align-items-center posi_detal_row'>
              <div className='col-12 col-sm-6 col-lg-3 mb-3'>
                <p className='mb-0'>
                  <span className='text_yellow text-uppercase'>Total Value Stocked:</span>
                  <span className='text-white pl-2'>$32,110.2</span>

                </p>
              </div>
              <div className='col-12 col-sm-6 col-lg-3 text-lg-center mb-3'>
                <p className='mb-0'>
                  <span className='text_yellow text-uppercase'>In Open Positions:</span>
                  <span className='text-white pl-2'>$2,110.2</span>

                </p>
              </div>
              <div className='col-12 col-sm-6 col-lg-3 text-lg-center mb-3'>
                <div className="input-group input_grp_dash mt-2 input_grp_search mx-lg-auto">
                  <input type="text" className="form-control" placeholder="Find Deposit Token" aria-label="Commitment" aria-describedby="basic-addon2" />
                  <div className="input-group-append">
                    <span className="input-group-text" id="basic-addon2">

                      <i className="fa fa-search" aria-hidden="true"></i>

                    </span>
                  </div>
                </div>
              </div>
              <div className='col-12 col-sm-6 col-lg-3 text-lg-right  mb-3'>
                <Dropdown className='btn_grey_dd dd_posit_sort ml-lg-auto'>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <div className='d-flex'>

                      <span>Sort by</span>
                      <span className='arrow_bg'><i className="fa fa-angle-down" aria-hidden="true"></i></span>

                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className='dd_menu_grey sort_dd_menu_grey'>
                    <Dropdown.Item>
                      <div className='d-flex px-3'>

                        <span>Sort by</span>

                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <div className='d-flex px-3'>

                        <span>Wallet Balance</span>

                      </div>
                    </Dropdown.Item>

                    <Dropdown.Item>
                      <div className='d-flex px-3'>

                        <span>User Supplied</span>

                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <div className='d-flex px-3'>

                        <span>Total Supply</span>

                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <div className='d-flex px-3'>

                        <span>Available</span>

                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className='row lending_row justify-content-center text-white'>
              {
                pools?.length > 0 ? pools.map((item, index) => {
                  return <Lendingcard data={item}/>
                }) : "No data found"
            }
            </div>
            <p className='text-center no_item_text d-none'>You currently have no positions.</p>
          </div>
        </div>

        <div className='w-100 footer_inner'>
          <Footer />
        </div>

        {announcementModal2 && <Announcementmodal2 connect={"string"} onDismiss={() => onDismiss1()} />}
      </div>
    </div>
  );
}

export default Lending