import React, { Component } from 'react';

import '../../css/styles.css';

import Summarymodal from "./Summarymodal";

import { Form } from 'react-bootstrap';



import { Slider, RangeSlider } from 'rsuite';

import "rsuite/dist/rsuite.min.css";

class Long extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value1: 0,
      value2: 0,
      summaryModal: false,
    };
  }

  onDismisssummary() {
    this.setState({ summaryModal: false });
  }

  render() {
    const labels1 = ['2x', '3x', '4x', '5x', '6x', '7x', '8x'];
    const labels2 = ['2x', '3x', '4x', '5x', '6x', '7x', '8x'];

    const { value1, value2, summaryModal } = this.state;
    const handleStyle1 = {
      color: '#fff',
      fontSize: 12,
      width: 32,
      height: 22
    };
    const handleStyle2 = {
      color: '#fff',
      fontSize: 12,
      width: 32,
      height: 22
    };
    return (
      <div>
        <div className='p-3'>
          <p className='text-white text-uppercase mb-0'>Market Price:</p>
          <p className='text_grey'>
            <span className='price_yel'>247.69</span>
            <span className='pl-2'>per ETH</span>
          </p>
          <form className='dashboard_form'>
            <label>Commitment:</label>
            <div className="input-group mb-3 input_grp_dash mt-2">
              <input type="text" value="0" className="form-control" placeholder="Commitment" aria-label="Commitment" aria-describedby="basic-addon2" />
              <div className="input-group-append">
                <span className="input-group-text" id="basic-addon2">
                  <button className='btn btn_max'>
                    MAX
                  </button>
                </span>
              </div>
            </div>
            <p className='d-flex align-items-center switch_menu_p'>
              <span className='text-white mr-3 text-uppercase'>Market</span>
              <span className="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="customSwitch2" />
                <label className="custom-control-label" for="customSwitch2"></label>
              </span>
              <span className='text-red ml-4 text-uppercase'>Limit</span>

            </p>
            <label>Open Rate:</label>
            <div class="input-group mb-3 input_grp_dash mt-2">
              <input type="text" value="0" className="form-control border_full" placeholder="Open Rate" aria-label="Commitment" aria-describedby="basic-addon2" />

            </div>

            <div className='card card_trans_short mt-5'>
              <div className='card-body'>
                <p className='text-white text-uppercase font_12 mb-4'>Leverage : (Max : 50)</p>

                <Slider
                  id="customSlider1"
                  min={2}
                  max={labels1.length + 1}
                  value={value1}
                  className="custom-slider mx-2"
                  handleStyle={handleStyle1}
                  graduated
                  progress
                  // tooltip={true}
                  // handleTitle={labels[value]}
                  onChange={v => {
                    this.setState({ value1: v });
                  }}
                  renderMark={mark => {
                    return mark + "x";
                  }}
                />

                <p className='text-white text-uppercase font_12 mb-4 mt-5'>Leverage : (Max : 1000)</p>

                <Slider
                  id="customSlider2"
                  min={2}
                  max={labels2.length + 1}
                  value={value2}
                  className="custom-slider mx-2"
                  handleStyle={handleStyle2}
                  graduated
                  progress
                  // tooltip={true}
                  // handleTitle={labels[value]}
                  onChange={v => {
                    this.setState({ value2: v });
                  }}
                  renderMark={mark => {
                    return mark + "x";
                  }}
                />
              </div>
            </div>
            <p className='d-flex align-items-center switch_menu_p mt-4'>
              <span className='text-white mr-3 text-uppercase'>SL / </span>
              <span className='text-red text-uppercase mr-4'>TP</span>

              <span className="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="customSwitch3" />
                <label className="custom-control-label" for="customSwitch3"></label>
              </span>

            </p>
            
            <div className='row'>
              <div className='col-12 col-lg-6 pr-lg-2'>
                <label>Stop Loss: (%)</label>
                <div class="input-group mb-3 input_grp_dash mt-2">
                  <input type="text" value="0" className="form-control border_full" placeholder="Stop Loss" aria-label="StopLoss" aria-describedby="basic-addon2" />

                </div>
              </div>
              <div className='col-12 col-lg-6 pl-lg-2'>
                <label>Take Profit: (%)</label>
                <div class="input-group mb-3 input_grp_dash mt-2">
                  <input type="text" value="0" className="form-control border_full" placeholder="Take Profit" aria-label="TakeProfit" aria-describedby="basic-addon2" />

                </div>
              </div>
            </div>
            <p className='text-white text-center font_12 mt-4'>Lorem ipsum dolor sit amet,  Ut enim ad minim veniam</p>
            <div className='text-center mt-5'>
              <button className="get-started-btn text-uppercase">
                Open Position</button>
              <button type="button" className="get-started-btn text-uppercase mt-4" onClick={() => this.setState({ summaryModal: true })}>
                Summary</button>
            </div>
          </form>
        </div>
        {summaryModal && <Summarymodal connect={"string"} onDismiss={() => this.onDismisssummary()} />}

      </div>
    );
  }
}

export default Long