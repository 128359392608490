import React, { Component, useEffect, useState } from 'react';
import { Modal, Dropdown } from 'react-bootstrap';



import wall1 from "../../images/metamask_wallet.png";
import wall2 from "../../images/connect_wallet.png";
import { Scrollbars } from 'react-custom-scrollbars';
import { addCurrency } from '../action/admin';

//import action
import { getCurrencyOption, EditPair } from '../action/admin';

import { toastAlert } from '../../lib/toast';
import isEmpty from '../../lib/isEmpty';
const initialFormvalue = {
    pairId: "",
    firstCurrency: "",
    firstCurrencyId: "",
    secondCurrency: "",
    secondCurrencyId: "",
    // currencyProfit:"",
    leverage: "",
    slippage: "",
    // open:"",
    // last: "",
    // low: "",
    // high:"",
    // asks: "",
    // bids: "",
    // changes: "",
    // volume: "",
    // secVolume: "",
    // marginFee: "",
    marketPrice: "",
    minQuantity: "",
    maxQuantity: "",
    commission: "",
    status: "",
    description: "",
    openFee: "",
    // executionFee:"",
    maintananceMargin: "",
    tickerRoot: ""
}

const PairEditModel = (props) => {
    const [formValue, setFormvalue] = useState(initialFormvalue)
    const { firstCurrency, secondCurrency, leverage, slippage, marketPrice, minQuantity, maxQuantity, commission, status, description, openFee, maintananceMargin, tickerRoot } = formValue
    const [leverageModal, setLeverageModal] = useState(true)
    const [errror, setError] = useState()
    const [currencyOption, setcurrencyOption] = useState([])
    const { record } = props
    // useEffect(() => {
    //     getOption()
    // }, [])
    useEffect(() => {
        if (record) {
            let formData = {
                ...formValue, ...{
                    pairId: record._id,
                    firstCurrency: record.firstCurrency,
                    firstCurrencyId: record.firstCurrencyId,
                    secondCurrency: record.secondCurrency,
                    secondCurrencyId: record.secondCurrencyId,
                    leverage: record.leverage,
                    slippage: record.slippage,
                    marketPrice: record.marketPrice,
                    minQuantity: record.minQuantity,
                    maxQuantity: record.maxQuantity,
                    commission: record.commission,
                    status: record.status,
                    description: record.description,
                    openFee: record.openFee,
                    maintananceMargin:record.maintananceMargin,
                    tickerRoot: record.tickerRoot
                }
            }
            setFormvalue(formData)
        }
    }, [record])
    // const getOption = async () => {
    //     try {
    //         let data = await getCurrencyOption()
    //         if (data) {
    //             setcurrencyOption(data)
    //         }
    //     } catch (err) {
    //         console.log(err, "getOption___err")
    //     }
    // }
    const handleChange = async (e) => {
        try {
            const { name, value } = e.target
            let regex = /[ A-Za-z`!@#$%^&*()_+=\[\]{};':"\\|,<>\/?~]/
            console.log(!['description'].includes(name) && regex.test(value), value?.split('.')?.length > 2, value?.split('-')?.length > 2, (value?.split('-')?.length >= 2 && !isEmpty(value?.split('-')[0])), 'handleChange-check')
            if (!['description'].includes(name) && regex.test(value) || value?.split('.')?.length > 2 || value?.split('-')?.length > 2 || (value?.split('-')?.length >= 2 && !isEmpty(value?.split('-')[0]))) {
                return
            }
            let formData = { ...formValue, ...{ [name]: value } }
            console.log(formData, "formData")
            setFormvalue(formData)
        } catch (err) {
            console.log(err, "handleChange_Err")
        }
    }

    const Editpair = async () => {
        try {
            // e.preventDefault()
            let data = formValue
            let error = await PairdVaidation(data)
            if (isEmpty(error)) {
                const { message, status } = await EditPair(data)
                if (status) {
                    toastAlert('success', message)
                    props.onDismiss()
                } else {
                    toastAlert('error', message)
                }
            } else {
                return false
            }
        }
        catch (err) {
            console.log(err, "Addnewpair__err")
        }
    }
    const PairdVaidation = async (data) => {
        try {
            console.log("currencyFieldVaidation", data);
            let errors = {}
            // if (isEmpty(data.firstCurrency)) {
            //     errors.firstCurrency = "First Currency is required"
            // }
            // if (isEmpty(data.secondCurrency)) {
            //     errors.secondCurrency = "Second Currency is required"
            // }
            // else if (data.secondCurrency == data.firstCurrency) {
            //     errors.secondCurrency = 'Second Currency should be differ firstCurrency'
            // }
            if (isEmpty(data.leverage)) {
                errors.leverage = "Leverage is required"
            }
            if (isEmpty(data.slippage)) {
                errors.leverage = "Slippage is required"
            }
            if (isEmpty(data.marketPrice)) {
                errors.marketPrice = "Market Price is required"
            }
            if (isEmpty(data.minQuantity)) {
                errors.minQuantity = "Min Quantity is required"
            }
            if (isEmpty(data.maxQuantity)) {
                errors.maxQuantity = "Max Quantity is required"
            }
            if (isEmpty(data.commission)) {
                errors.commission = "Commission is required"
            }
            if (isEmpty(data.status)) {
                errors.status = "Status is required"
            }
            if (isEmpty(data.description)) {
                errors.description = "Description is required"
            }
            if (isEmpty(data.openFee)) {
                errors.openFee = ")pen Fee is required"
            }
            if (isEmpty(data.maintananceMargin)) {
                errors.maintananceMargin = "Maintanance Margin is required"
            }
            // if (isEmpty(data.tickerRoot)) {
            //     errors.tickerRoot = "Ticker root is required"
            // }
            setError(errors)
            console.log(errors, "errors")
            return errors
        } catch (e) {
            console.log('currencyFieldVaidation', e);
        }
    }

    return (
        <Modal className="wallet-modal" show={leverageModal} centered size="sm">
            <Modal.Header className="pb-0">
                <div>
                    <h3 className="sec-head ">Edit Pair</h3>
                </div>
                <button type="button" class="close" onClick={() => props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

            </Modal.Header>
            <Modal.Body className="select-wallet pt-0">
                <Scrollbars className="modal_scroll_sm" style={{ height: 100 }}>
                    <form className="dashboard_form pad_right_das mt-4 mb-4">

                        <label>First Currency</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">

                            <Dropdown className='btn_grey_dd dd_posit_sort dd_posit_sort_fullw'>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    <div className='d-flex'>

                                        <span>{firstCurrency ? firstCurrency : "select first Currency"}</span>
                                        <span className='arrow_bg'><i className="fa fa-angle-down" aria-hidden="true"></i></span>

                                    </div>
                                </Dropdown.Toggle>

                                {/* <Dropdown.Menu className='dd_menu_grey sort_dd_menu_grey sort_dd_menu_grey_full'>
                                    {currencyOption && currencyOption.length > 0 ? currencyOption.map((val, i) => {
                                        return (
                                            <Dropdown.Item onClick={() => {
                                                console.log("data", "dats")
                                                let formData = { ...formValue, ...{ ['firstCurrency']: val.currencySymbol, ['firstCurrencyId']: val._id } }
                                                setFormvalue(formData)
                                            }}>
                                                <div className='d-flex px-3'>

                                                    <span>{val.currencySymbol}</span>

                                                </div>
                                            </Dropdown.Item>
                                        )

                                    }) : ""}
                                </Dropdown.Menu> */}
                            </Dropdown>
                            {/* <input type="text"
                                name="firstCurrency"
                                className="form-control border_full"
                                placeholder="Enter Currency Symbol"
                                aria-label="multiplier"
                                aria-describedby="basic-addon2"
                                onChange={(e) => { handleChange(e)}} /> */}
                        </div>
                        <p className='error_modal_red'>{errror?.firstCurrency}</p>

                        <label>Second Currency</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">

                            <Dropdown className='btn_grey_dd dd_posit_sort dd_posit_sort_fullw'>
                                <Dropdown.Toggle variant="success" id="dropdown-basic-1">
                                    <div className='d-flex'>

                                        <span>{secondCurrency ? secondCurrency : "select second Currency"}</span>
                                        <span className='arrow_bg'><i className="fa fa-angle-down" aria-hidden="true"></i></span>

                                    </div>
                                </Dropdown.Toggle>

                                {/* <Dropdown.Menu className='dd_menu_grey sort_dd_menu_grey sort_dd_menu_grey_full'>
                                    {currencyOption && currencyOption.length > 0 ? currencyOption.map((val, i) => {
                                        return (
                                            <Dropdown.Item
                                                onClick={() => {
                                                    console.log("data", "dats")
                                                    let formData = { ...formValue, ...{ ['secondCurrency']: val.currencySymbol, ['secondCurrencyId']: val._id } }
                                                    setFormvalue(formData)
                                                }}
                                            >
                                                <div className='d-flex px-3'>

                                                    <span>{val.currencySymbol}</span>

                                                </div>
                                            </Dropdown.Item>)
                                    }) : ""}
                                </Dropdown.Menu> */}
                            </Dropdown>
                            {/* <input type="text"
                                name="secondCurrency"
                                className="form-control border_full"
                                placeholder="Enter Currency Symbol"
                                aria-label="multiplier"
                                aria-describedby="basic-addon2"
                                onChange={(e) => { handleChange(e)}} /> */}
                        </div>

                        <p className='error_modal_red'>{errror?.secondCurrency}</p>


                        <label>Leverage</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">
                            <input
                                type="text"
                                name="leverage"
                                className="form-control border_full"
                                placeholder="Enter Leverage"
                                aria-label="managementfee" aria-describedby="basic-addon2"
                                value={leverage}
                                onChange={(e) => { handleChange(e) }} 
                                />
                        </div>

                        <p className='error_modal_red'>{errror?.leverage}</p>


                        <label>Slippage:</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">
                            <input
                                type="text"
                                name="slippage"
                                className="form-control border_full"
                                placeholder="Enter slippage"
                                aria-label="managementfee"
                                aria-describedby="basic-addon2"
                                value={slippage}
                                onChange={(e) => { handleChange(e) }} 
                                />
                        </div>
                        <p className='error_modal_red'>{errror?.slippage}</p>


                        <label>Market Price:</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">
                            <input type="text"
                                name="marketPrice"
                                className="form-control border_full"
                                placeholder="Enter Market Price"
                                aria-label="managementfee"
                                aria-describedby="basic-addon2"
                                value={marketPrice}
                                onChange={(e) => { handleChange(e) }} 
                                />
                        </div>
                        <p className='error_modal_red'>{errror?.marketPrice}</p>
                        <label>Min Quantity:</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">
                            <input type="text"
                                name="minQuantity"
                                className="form-control border_full"
                                placeholder="Enter Min Quantity"
                                aria-label="managementfee"
                                aria-describedby="basic-addon2"
                                value={minQuantity}
                                onChange={(e) => { handleChange(e) }} 
                                />
                        </div>
                        <p className='error_modal_red'>{errror?.minQuantity}</p>
                        <label>Max Quantity:</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">
                            <input
                                type="text"
                                name="maxQuantity"
                                className="form-control border_full"
                                placeholder="Enter Max Quantity"
                                aria-label="managementfee"
                                aria-describedby="basic-addon2"
                                value={maxQuantity}
                                onChange={(e) => { handleChange(e) }} 
                                />
                        </div>
                        <p className='error_modal_red'>{errror?.maxQuantitys}</p>
                        <label>Commission:</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">
                            <input
                                type="text"
                                name="commission"
                                className="form-control border_full"
                                placeholder="Enter Commission"
                                aria-label="managementfee"
                                aria-describedby="basic-addon2"
                                value={commission}
                                onChange={(e) => { handleChange(e) }} 
                                />
                        </div>
                        <p className='error_modal_red'>{errror?.commission}</p>
                        <label>Status:</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">
                            <Dropdown className='btn_grey_dd dd_posit_sort dd_posit_sort_fullw'>
                                <Dropdown.Toggle variant="success" id="dropdown-basic-1">
                                    <div className='d-flex'>

                                        <span>{status ? status : "select  Status"}</span>
                                        <span className='arrow_bg'><i className="fa fa-angle-down" aria-hidden="true"></i></span>

                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='dd_menu_grey sort_dd_menu_grey sort_dd_menu_grey_full'>
                                    <Dropdown.Item
                                        onClick={(e) => {
                                            console.log("data", "dats")
                                            let formData = { ...formValue, ...{ ['status']: "active" } }
                                            setFormvalue(formData)
                                        }}
                                    >
                                        <div className='d-flex px-3'>
                                            <span>Active</span>
                                        </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={(e) => {
                                            console.log("data", "dats")
                                            let formData = { ...formValue, ...{ ['status']: "inactive" } }
                                            setFormvalue(formData)
                                        }}
                                    >
                                        <div className='d-flex px-3'>
                                            <span>In Active</span>
                                        </div>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            {/* <input
                                type="text"
                                name="status"
                                className="form-control border_full"
                                placeholder="Enter Contract Address"
                                aria-label="managementfee"
                                aria-describedby="basic-addon2"
                                onChange={(e) => { handleChange(e) }} /> */}
                        </div>
                        <p className='error_modal_red'>{errror?.status}</p>
                        <label>Description:</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">
                            <input
                                type="text"
                                name="description"
                                className="form-control border_full"
                                placeholder="Enter Description"
                                aria-label="managementfee"
                                aria-describedby="basic-addon2"
                                value={description}
                                onChange={(e) => { handleChange(e) }} 
                            />
                        </div>
                        <p className='error_modal_red'>{errror?.description}</p>
                        <label>Open Fee:</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">
                            <input
                                type="text"
                                name="openFee"
                                className="form-control border_full"
                                placeholder="Enter Open Fee in percentage"
                                aria-label="managementfee"
                                aria-describedby="basic-addon2"
                                value={openFee}
                                onChange={(e) => { handleChange(e) }} />
                        </div>
                        <p className='error_modal_red'>{errror?.openFee}</p>
                        <label>Maintanance Margin:</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">
                            <input
                                type="text"
                                name="maintananceMargin"
                                className="form-control border_full"
                                placeholder="Enter Maintanance Margin in perenctange"
                                aria-label="managementfee"
                                aria-describedby="basic-addon2"
                                value={maintananceMargin}
                                onChange={(e) => { handleChange(e) }} />
                        </div>
                        <p className='error_modal_red'>{errror?.maintananceMargin}</p>
                        <label>Ticker Root:</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">
                            <input
                                type="text"
                                name="tickerRoot"
                                className="form-control border_full"
                                placeholder="Enter Ticker Root"
                                aria-label="managementfee"
                                aria-describedby="basic-addon2"
                                value={tickerRoot}
                                // onChange={(e) => { handleChange(e) }} 
                                />
                        </div>
                        <p className='error_modal_red'>{errror?.tickerRoot}</p>
                        <div className='text-center mt-5'>
                            <button type="button" className="get-started-btn text-uppercase"
                                onClick={() => { Editpair() }}>
                                Add
                            </button>
                        </div>
                    </form>
                </Scrollbars>

            </Modal.Body>
        </Modal>
    )
}

export default PairEditModel