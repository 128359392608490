import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import {
  Link,
  withRouter,
  useLocation,
  useHistory,
  useParams,
} from "react-router-dom";
import Innerheader from "../Innerheader";
import Orderbookmodal from "../yamada/Orderbookmodal";
import Trademodal from "../yamada/Trademodal";
import Marginration from "../yamada/Marginrationmodal";
import Assets from "../yamada/Assetsmodal";
import Smallimg from "../../images/brown.PNG";
import Roll from "../../images/roll.PNG";
import $ from "jquery";
import {
  Col,
  Row,
  Container,
  Nav,
  Dropdown,
  Tabs,
  Tab,
  Button,
  Form,
} from "react-bootstrap";
import Positions from "../../components/yamada/positions";
import Pending from "../../components/yamada/pending";
import History from "../../components/yamada/history";
import Long from "../../components/yamada/long";
import Short from "../../components/yamada/short";
import Seriesgraph from "./seriesgraph";
import Walletmodal from "../Walletmodal";
import { ImArrowRight, ImArrowLeft } from "react-icons/im";
import uparrow from "../../images/uparrow.PNG";
import coinlogo from "../../images/binance.png";

import {
  getAssetData,
  getPerpatualPairsData,
  marketPlacing,
} from "../action/user";
// import Chartgraph from '../../components/chart';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { orderPlacing } from "../action/user";
import CryptoJS from "crypto-js";
import toast, { Toaster } from "react-hot-toast";
import io from "socket.io-client";
import {
  SOCKEt_URL,
  cryptoPass,
  paramsDataTypes,
  SALT,
} from "../../config/env";
import { useSelector, useDispatch } from "react-redux";
import SocketContext from "../context/SocketContext";
import { priceConversion } from "../../hooks/useCalculation";
import {
  approveToken,
  checkIsApproved,
  contractParams,
  getWholeNumber,
  getWholeNumberQuantity,
  randomFixedInteger,
  UseExchangeMasterChef,
} from "../../hooks/useUserExchange";
import { toastAlert } from "../../lib/toast";
import { WALLET_ADDRESS } from "../../redux/action";
import Depositmodal3 from "../yamada/Assetsmodal";
import Depositmodal from "../yamada/Orderbookmodal";

const Dashboard1 = (props) => {
  const initalFormValue = {
    price: 0,
    leverage: 1,
    quantity: 0,
    Principal: 0,
    currenypaid: "",
    pairId: "",
    ordertype: "market",
    takePrice: 0,
    stopLossPrice: 0,
    isTakeorStop: false,
    pairData: {},
    slippage: 0,
  };
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { tickerRoot } = useParams();
  // console.log("location_location",location.pathname.split("/")[2])
  const socketContext = useContext(SocketContext);
  const assetData = useSelector((state) => state.Auth.wallets);
  const [depositModal, setDepositModal] = useState(false);
  const [depositModal1, setDepositModal1] = useState(false);
  const [depositModal2, setDepositModal2] = useState(false);
  const [depositModal3, setDepositModal3] = useState(false);
  const [walletModal, setWalletModal] = useState(false);
  const [pairData, setPairData] = useState([]);
  const [formValue, setFormValue] = useState(initalFormValue);
  const [buyorsell, setBuyorsell] = useState("buy");
  const [tickerPrice, setTickerPrice] = useState(0);
  const [tickerPreference, setTickerPreference] = useState({});
  const [show, setShow] = useState(false);
  const [fundings, setFundings] = useState({});
  const [checkTokenApprove, setCheckTokenApprove] = useState(false);

  const onDismissorder = () => {
    setDepositModal(false);
  };
  const onDismissorder1 = () => {
    setDepositModal1(false);
  };
  const onDismissorder2 = () => {
    setDepositModal2(false);
  };
  const onDismissorder3 = () => {
    setDepositModal3(false);
  };
  const onDismiss = () => {
    // console.log("onDismiss",walletModal)
    setWalletModal(false);
  };

  const getPairData = async () => {
    try {
      const data = await getPerpatualPairsData();
      if (tickerRoot == "" || tickerRoot == undefined) {
        history.replace("/trade/" + data[0].tickerRoot);
        localStorage.setItem("currentPair", data[0].tickerRoot)
      }
      let formData = { ...formValue };
      formData.pairId = data[0]._id;
      await checkIsApporvePaidToken(
        data[0].secondCurrency,
        localStorage.getItem("accountInfo")
      );
      setFormValue(formData);
      setPairData(data);
    } catch (e) {
      console.log("getPairData", e);
    }
  };
  const checkIsApporvePaidToken = async (token, account) => {
    if (account != undefined) {
      const getAssetsData = await getAssetData(
        { walletAddress: localStorage.getItem("accountInfo") },
        dispatch
      );
      if (getAssetsData.length > 0) {
        console.log("getAssetsData_check", getAssetsData);
        const tokenAddress = getAssetsData.find((el) => {
          return el.currencySymbol == token;
        })?.currencyId?.currencyAddress;
        const checkIsApprovedToken = await checkIsApproved(
          account,
          tokenAddress
        );
        console.log(
          "checkIsApporvePaidToken",
          token,
          tokenAddress,
          checkIsApprovedToken
        );
        if (checkIsApprovedToken) {
          setCheckTokenApprove(true);
        }
      }
    }
  };
  const approvePaidToken = async (token, account) => {
    if (account != undefined) {
      console.log("approvePaidToken", token, account);
      const tokenAddress = assetData.find((el) => {
        return el.currencySymbol == token;
      })?.currencyId?.currencyAddress;
      const approve = await approveToken(account, tokenAddress);
      if (approve) {
        setCheckTokenApprove(true);
      }
      console.log("approvePaidToken", token, tokenAddress, approve);
    }
  };
  const placeOrder = async () => {
    try {
      let data = {
        price: formValue.price,
        walletAddress: localStorage.getItem("accountInfo"),
        leverage: formValue.leverage,
        quantity: formValue.quantity,
        Principal: formValue.Principal,
        currenypaid: formValue.currenypaid,
        pairId: formValue.pairId,
        orderId: await randomFixedInteger(),
        buyorsell: buyorsell,
        isbuy: buyorsell == "buy" ? true : false,
        positionSide: buyorsell == "buy" ? "short" : "long",
        ordertype: formValue.ordertype,
        takePrice: formValue.takePrice,
        stopLossPrice: formValue.stopLossPrice,
        isTakeOrStop: formValue.isTakeorStop,
        slippage: formValue.slippage,
        status: "open",
      };
      let payload = [
        {
          walletAddress: localStorage.getItem("accountInfo"),
          currenypaid: assetData.find((el) => {
            return el.currencySymbol == formValue.currenypaid;
          }).currencyId.currencyAddress,
          pairId: formValue.pairId,
          orderId: data.orderId,
          positionId: "",
          quantity: getWholeNumberQuantity(parseFloat(formValue.quantity), 18),
          price: getWholeNumber(formValue.price, 18),
          leverage: formValue.leverage,
          isbuy: buyorsell == "buy" ? true : false,
          // positionSide: buyorsell == 'buy' ? false : true,
          // status: 'open',
          ordertype: formValue.ordertype == "market" ? 0 : 1,
          Principal: getWholeNumber(formValue.Principal, 18),
          closedId: "",
        },
        SALT,
      ];
      console.log("New_marketOrderPlacing_check_floatDigits", payload);

      // Contract-calls
      const findParamsDataTypes = paramsDataTypes.find(
        (el) => el.type == "order"
      );
      if (formValue.ordertype == "market") {
        console.log("New_marketOrderPlacing", data);
        const getOrderData = await orderPlacing(data, dispatch);
        if (getOrderData?.status) {
          // getOrderData.data.newrecord.executeQuantity = formValue.quantity;

          const encodePayload = await contractParams(
            payload,
            findParamsDataTypes.param,
            "order"
          );
          console.log("encodePayload_market", encodePayload);
          if (encodePayload) {
            const newMarketPlacing = await marketPlacing(
              {
                newOrder: getOrderData.data.newrecord,
                matchingList: getOrderData.data.orderLists,
                pairData: getOrderData.data.pairData,
                metaStatus: true,
              },
              dispatch
            );
          } else {
            const newMarketPlacing = await marketPlacing(
              {
                newOrder: getOrderData.data.newrecord,
                matchingList: getOrderData.data.orderLists,
                pairData: getOrderData.data.pairData,
                metaStatus: false,
              },
              dispatch
            );
          }
        }
      } else {
        let openFee =
          (parseFloat(formValue.Principal) *
            parseFloat(formValue.pairData.openFee)) /
          100;
        let initialMargin = parseFloat(formValue.Principal) - openFee;
        let quantity = parseFloat(
          parseFloat(initialMargin) * parseFloat(formValue.leverage)
        ).toFixed(8);
        let conversion = await priceConversion(
          formValue.pairData.secondCurrency,
          formValue.pairData.firstCurrency
        );
        quantity = parseFloat(quantity) * conversion;
        console.log("orderPlacing_limit_payload", {
          formValue_pairData: formValue?.pairData,
          chechQyt: quantity < formValue.pairData.minQuantity,
          openFee,
          initialMargin,
          quantity,
        });
        if (parseFloat(quantity) < parseFloat(formValue.pairData.minQuantity)) {
          toastAlert(
            "error",
            "Quantity of contract must not be lesser than " +
            formValue.pairData.minQuantity
          );
        } else {
          const encodePayload = await contractParams(
            payload,
            findParamsDataTypes.param,
            "order"
          );
          console.log("encodePayload_limit", encodePayload);
          if (encodePayload) {
            const getOrderData = await orderPlacing(data, dispatch);
          } else {
            console.log("OrderPlacingErr");
            // toastAlert('error', 'Metamask-Error')
          }
        }
      }
    } catch (error) {
      console.log("placeOrder_orderPlacing_error", error);
    }
  };
  useEffect(() => {
    getPairData();
    UseExchangeMasterChef();
    setTickerPreference(JSON.parse(localStorage.getItem("TickerPreference")));
    if (!JSON.parse(localStorage.getItem("TickerPreference"))) {
      localStorage.setItem(
        "TickerPreference",
        JSON.stringify({
          high: true,
          low: true,
          volumeF: false,
          volumeS: false,
        })
      );
    }
  }, [walletModal]);

  useEffect(() => {
    socketContext.socket.on("tickerPrice", async (data) => {
      // console.log('socketContext_tickerPrice_0',data, localStorage.getItem("currentPair"));
      if (localStorage.getItem("currentPair") == data.tickerRoot) {
        // console.log('socketContext_tickerPrice_1',data);
        data.secoundCurruncyVolume = await volumeConvert(data);
        setTickerPrice(data);
      }
    });
    socketContext.socket.on("userFunds", async (data) => {
      setFundings(data);
    });
  }, []);

  const volumeConvert = async (data) => {
    const secoundCurruncyVolume =
      (await priceConversion(data.firstCurrency, data.secondCurrency)) *
      data.volume;
    return secoundCurruncyVolume;
  };
  const chooseTickerPreference = async (data) => {
    let local = JSON.parse(localStorage.getItem("TickerPreference"));
    local[data.name] = data.checked;
    setTickerPreference(local);
    localStorage.setItem("TickerPreference", JSON.stringify(local));
  };
  return (
    <div>
      <Innerheader onDismiss={() => onDismiss()} />
      {/* <>
        <div className="row main_view main_views mx-0">
          <div className="container-fluid fulldashboard">
            <div className="row mt-4">
              <div className="col-12 col-lg-7 col-xl-8">
                <div className="card fullcard card_table1 mt-3">
                  <div className="card-body">
                    <div className="d-flex rollflex">
                      <div>
                        <ul className="firsttabsul">
                          <li>
                            {" "}
                            <button
                              type="button"
                              className="btn"
                              onClick={() => {
                                {
                                  setDepositModal(true);
                                }
                              }}
                            >
                              Order Book
                            </button>
                          </li>

                          <li>
                            <button
                              type="button"
                              className="btn"
                              onClick={() => {
                                setDepositModal3(true);
                              }}
                            >
                              Assets
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {depositModal && (
                      <Orderbookmodal
                        connect={"string"}
                        onDismiss={() => {
                          onDismissorder();
                        }}
                      />
                    )}
                    {depositModal2 && (
                      <Marginration
                        connect={"string"}
                        onDismiss={() => {
                          onDismissorder2();
                        }}
                      />
                    )}
                    {depositModal3 && (
                      <Assets
                        connect={"string"}
                        onDismiss={() => {
                          onDismissorder3();
                        }}
                      />
                    )}
                  </div>
                </div>

                <div className="card card_table2 mt-3">
                  <div className="card-body">
                    <div className="row align-items-center flexnowrp_divvv">
                      <div className="col-xl-3 col-sm-4 col-6">
                        <p className="bt">
                          {localStorage.getItem("currentPair")}
                        </p>
                        <h5 className="rate">
                          {buyorsell == "buy"
                            ? tickerPrice.bids
                            : tickerPrice.asks}
                        </h5>
                      </div>
                      <div className="col-xl-3 col-sm-4 col-6">
                        <p className="bt">Funding (Long)</p>
                        <h6 className="rate">
                          <span className="no1">
                            {fundings?.longProfit == 0
                              ? "0.00"
                              : fundings?.longProfit?.toFixed(6)}
                            %
                          </span>
                        </h6>
                      </div>
                      <div className="col-xl-3 col-sm-4 col-6">
                        <p className="bt">Funding (Short)</p>
                        <h6 className="rate">
                          <span className="no1">
                            {fundings?.shortProfit == 0
                              ? "0.00"
                              : fundings?.shortProfit?.toFixed(6)}
                            %
                          </span>{" "}
                        </h6>
                      </div>
                      <div className="col-xl-3 col-sm-4 col-6">
                        <p className="bt">24h Change</p>
                        <h6 className="rate">
                          <span className="gno1">
                            {tickerPrice?.changes?.toFixed(2)}%
                          </span>
                        </h6>
                      </div>
                      {tickerPreference?.high && (
                        <div className="col-xl-3 col-sm-4 col-6">
                          <p className="bt">24h High</p>
                          <h6 className="rate">
                            <span className="gno1">{tickerPrice?.high}</span>
                          </h6>
                        </div>
                      )}
                      {tickerPreference?.low && (
                        <div className="col-xl-3 col-sm-4 col-6">
                          <p className="bt">24h Low</p>
                          <h6 className="rate">
                            <span className="gno1">{tickerPrice?.low}</span>
                          </h6>
                        </div>
                      )}
                      {tickerPreference?.volumeF && (
                        <div className="col-xl-3 col-sm-4 col-6">
                          <p className="bt">
                            24h Volume ({tickerPrice?.firstCurrency})
                          </p>
                          <h6 className="rate">
                            <span className="gno1">
                              {tickerPrice?.volume?.toFixed(2)}{" "}
                              {tickerPrice?.firstCurrency}
                            </span>
                          </h6>
                        </div>
                      )}
                      {tickerPreference?.volumeS && (
                        <div className="col-xl-3 col-sm-4 col-6">
                          <p className="bt">
                            24h Volume ({tickerPrice?.secondCurrency})
                          </p>
                          <h6 className="rate">
                            <span className="gno1">
                              {tickerPrice?.secoundCurruncyVolume?.toFixed(2)}
                            </span>
                          </h6>
                        </div>
                      )}

                      <div className="col-xl-3 col-sm-2 col-6 rollflex1">
                        <div className="rollflex">
                          <Dropdown
                            show={show}
                            onToggle={(isOpen, event, metadata) => {
                              // console.log(metadata,"metadata")
                              if (metadata.source !== "select") {
                                setShow(isOpen);
                              }
                            }}
                          >
                            <Dropdown.Toggle
                              variant="secondary"
                              id="dropdown-basic"
                            >
                              <img src={Roll} alt="roll" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item onSelect={() => setShow(true)}>
                                <Form.Check
                                  name="high"
                                  type="checkbox"
                                  id="default-checkbox11"
                                  label="24h High"
                                  checked={tickerPreference?.high}
                                  onChange={(e) => {
                                    chooseTickerPreference(e.target);
                                  }}
                                />
                              </Dropdown.Item>
                              <Dropdown.Item onSelect={() => setShow(true)}>
                                <Form.Check
                                  name="low"
                                  type="checkbox"
                                  id="default-checkbox12"
                                  label="24h Low"
                                  checked={tickerPreference?.low}
                                  onChange={(e) => {
                                    chooseTickerPreference(e.target);
                                  }}
                                />
                              </Dropdown.Item>
                              <Dropdown.Item onSelect={() => setShow(true)}>
                                <Form.Check
                                  name="volumeF"
                                  type="checkbox"
                                  id="default-checkbox13"
                                  checked={tickerPreference?.volumeF}
                                  label={`24h Volume (${tickerPrice?.firstCurrency})`}
                                  onChange={(e) => {
                                    chooseTickerPreference(e.target);
                                  }}
                                />
                              </Dropdown.Item>
                              <Dropdown.Item onSelect={() => setShow(true)}>
                                <Form.Check
                                  name="volumeS"
                                  type="checkbox"
                                  id="default-checkbox14"
                                  checked={tickerPreference?.volumeS}
                                  label={`24h Volume (${tickerPrice?.secondCurrency})`}
                                  onChange={(e) => {
                                    chooseTickerPreference(e.target);
                                  }}
                                />
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="card card_table2 mt-3 pt-2 pb-2">
                    <Seriesgraph pairData={formValue?.pairData} />
                  </div>
                </div>

                <div className="card card_table2 positiontable positiontable_new_heh mt-3 we">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="first"
                  >
                    <Row>
                      <Col sm={12}>
                        <Nav variant="pills" className="flex-row paddings">
                          <Nav.Item>
                            <Nav.Link eventKey="first">Positions</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="second">Pending</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="third">History</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                      <Col sm={12}>
                        <Tab.Content>
                          <Tab.Pane eventKey="first">
                            <Positions />
                          </Tab.Pane>
                          <Tab.Pane eventKey="second">
                            <Pending />
                          </Tab.Pane>
                          <Tab.Pane eventKey="third">
                            <History />
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>
              </div>
              <div className="col-12 col-lg-5 col-xl-4">
                <div className="card card_table2 longshort mt-3">
                  <div className="card-body">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="Long1"
                    >
                      <Row>
                        <Col sm={12}>
                          <div>
                            <Nav
                              variant="pills"
                              className="flex-row longshortborder"
                            >
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="Long1"
                                  onClick={() => {
                                    // this.setState({"buyorsell":"buy"})
                                    setBuyorsell("buy");
                                  }}
                                >
                                  Long
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="Short1"
                                  onClick={() => {
                                    setBuyorsell("sell");
                                  }}
                                >
                                  Short
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </div>
                        </Col>
                        <Col sm={12}>
                          <Tab.Content>
                            <Tab.Pane eventKey="Long1">
                              <Long
                                pairData={pairData}
                                pair={buyorsell}
                                tickerPrice={tickerPrice}
                                checkIsApporvePaidToken={(token, account) => {
                                  checkIsApporvePaidToken(token, account);
                                }}
                                onSet={(formValue) => {
                                  console.log(
                                    "limit_form_value_data",
                                    formValue
                                  );
                                  setFormValue(formValue);
                                }}
                              />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Short1">
                              <Short
                                pairData={pairData}
                                pair={buyorsell}
                                tickerPrice={tickerPrice}
                                checkIsApporvePaidToken={(token, account) => {
                                  checkIsApporvePaidToken(token, account);
                                }}
                                onSet={(formValue) => {
                                  console.log(formValue, "formaData");
                                  setFormValue(formValue);
                                }}
                              />
                            </Tab.Pane>
                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </div>

                  <div className="connectwallet">
                    <div className="row align-items-center walletss">
                      <div className="col-lg-6 col-6 borderright">
                        {localStorage.getItem("accountInfo") == undefined ? (
                          <Button
                            className="calculate"
                            variant="warning"
                            onClick={() => {
                              setWalletModal(true);
                            }}
                          >
                            Connect wallet
                          </Button>
                        ) : checkTokenApprove == false ? (
                          <Button
                            className="calculate"
                            variant="warning"
                            onClick={() => {
                              approvePaidToken(
                                formValue.currenypaid,
                                localStorage.getItem("accountInfo")
                              );
                            }}
                          >
                            Approve
                          </Button>
                        ) : (
                          <Button
                            className="calculate"
                            variant="warning"
                            onClick={() => {
                              placeOrder();
                            }}
                          >
                            Place Order
                          </Button>
                        )}
                      </div>
                      <div className="col-lg-6 col-6 text-center">
                        <p className="dailyreward mb-0">Daily Reward</p>
                      </div>
                    </div>

                    {walletModal && (
                      <Walletmodal
                        connect={"string"}
                        onDismiss={() => {
                          onDismiss();
                        }}
                      />
                    )}
                  </div>
                </div>

                <div className="card card_table2  longshort mt-3">
                  <div className="card-body">
                    <p className="sec-title">CheeseBurger Defi</p>

                    <p className="sec-para">
                      Swap or Trade wide range of crypto assets within seconds
                      anywhere, anytime around the world. Get high APY & APR on
                      your favorite crypto asset staking.
                    </p>
                    <div className="card card_table3">
                      <div className="card-body py-5 text-center">
                        <p className="trader trader_ttx_big mb-0">
                          Don't have{" "}
                          <img
                            src={coinlogo}
                            className="img-fuid img_icoo_swp_logo"
                          />{" "}
                          USDT?
                        </p>

                        <Button className="mb-0 pool mt-4" variant="warning">
                          <a
                            href="https://cheeseburger-defi.maticz.in/"
                            target={"_blank"}
                          >
                            Swap Now
                          </a>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </> */}

      <>
        <div className="row main_view main_views mx-0 custom_dashboard">
          <div className="container-fluid fulldashboard">
            <div className="row mt-4">
              <div className="col-xl-3 col-12 mb-2 mb-xl-0">
                <div className="card fullcard card_table1 mt-3">
                  <h4 className="asset_txt">Assets</h4>
                  <Depositmodal3 />
                </div>
              </div>
              <div className="col-xl-6 col-12">
                <div className="card card_table2 mt-3">
                  <div className="card-body">
                    <div className="row align-items-center flexnowrp_divvv">
                      <div className="col-xl-3 col-sm-4 col-6">
                        <p className="bt">
                          {localStorage.getItem("currentPair")}
                        </p>
                        <h5 className="rate">
                          {buyorsell == "buy"
                            ? tickerPrice.bids
                            : tickerPrice.asks}
                        </h5>
                      </div>
                      <div className="col-xl-3 col-sm-4 col-6">
                        <p className="bt">Funding (Long)</p>
                        <h6 className="rate">
                          <span className="no1">

                            {fundings?.longProfit
                              ? fundings?.longProfit?.toFixed(6)
                              : "0.00"}
                            %
                          </span>
                        </h6>
                      </div>
                      <div className="col-xl-3 col-sm-4 col-6">
                        <p className="bt">Funding (Short)</p>
                        <h6 className="rate">
                          <span className="no1">
                            {fundings?.shortProfit == 0
                              ? fundings?.shortProfit?.toFixed(6)
                              : "0.00"}
                            %
                          </span>{" "}
                        </h6>
                      </div>
                      <div className="col-xl-3 col-sm-4 col-6">
                        <p className="bt">24h Change</p>
                        <h6 className="rate">
                          <span className="gno1">
                            {tickerPrice?.changes?.toFixed(2)}%
                          </span>
                        </h6>
                      </div>
                      {tickerPreference?.high && (
                        <div className="col-xl-3 col-sm-4 col-6">
                          <p className="bt">24h High</p>
                          <h6 className="rate">
                            <span className="gno1">{tickerPrice?.high}</span>
                          </h6>
                        </div>
                      )}
                      {tickerPreference?.low && (
                        <div className="col-xl-3 col-sm-4 col-6">
                          <p className="bt">24h Low</p>
                          <h6 className="rate">
                            <span className="gno1">{tickerPrice?.low}</span>
                          </h6>
                        </div>
                      )}
                      {tickerPreference?.volumeF && (
                        <div className="col-xl-3 col-sm-4 col-6">
                          <p className="bt">
                            24h Volume ({tickerPrice?.firstCurrency})
                          </p>
                          <h6 className="rate">
                            <span className="gno1">
                              {tickerPrice?.volume?.toFixed(2)}{" "}
                              {tickerPrice?.firstCurrency}
                            </span>
                          </h6>
                        </div>
                      )}
                      {tickerPreference?.volumeS && (
                        <div className="col-xl-3 col-sm-4 col-6">
                          <p className="bt">
                            24h Volume ({tickerPrice?.secondCurrency})
                          </p>
                          <h6 className="rate">
                            <span className="gno1">
                              {tickerPrice?.secoundCurruncyVolume?.toFixed(2)}
                            </span>
                          </h6>
                        </div>
                      )}

                      <div className="col-xl-3 col-sm-2 col-6 rollflex1">
                        <div className="rollflex">
                          <Dropdown
                            show={show}
                            onToggle={(isOpen, event, metadata) => {
                              // console.log(metadata,"metadata")
                              if (metadata.source !== "select") {
                                setShow(isOpen);
                              }
                            }}
                          >
                            <Dropdown.Toggle
                              variant="secondary"
                              id="dropdown-basic"
                            >
                              <img src={Roll} alt="roll" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item onSelect={() => setShow(true)}>
                                <Form.Check
                                  name="high"
                                  type="checkbox"
                                  id="default-checkbox11"
                                  label="24h High"
                                  checked={tickerPreference?.high}
                                  onChange={(e) => {
                                    chooseTickerPreference(e.target);
                                  }}
                                />
                              </Dropdown.Item>
                              <Dropdown.Item onSelect={() => setShow(true)}>
                                <Form.Check
                                  name="low"
                                  type="checkbox"
                                  id="default-checkbox12"
                                  label="24h Low"
                                  checked={tickerPreference?.low}
                                  onChange={(e) => {
                                    chooseTickerPreference(e.target);
                                  }}
                                />
                              </Dropdown.Item>
                              <Dropdown.Item onSelect={() => setShow(true)}>
                                <Form.Check
                                  name="volumeF"
                                  type="checkbox"
                                  id="default-checkbox13"
                                  checked={tickerPreference?.volumeF}
                                  label={`24h Volume (${tickerPrice?.firstCurrency})`}
                                  onChange={(e) => {
                                    chooseTickerPreference(e.target);
                                  }}
                                />
                              </Dropdown.Item>
                              <Dropdown.Item onSelect={() => setShow(true)}>
                                <Form.Check
                                  name="volumeS"
                                  type="checkbox"
                                  id="default-checkbox14"
                                  checked={tickerPreference?.volumeS}
                                  label={`24h Volume (${tickerPrice?.secondCurrency})`}
                                  onChange={(e) => {
                                    chooseTickerPreference(e.target);
                                  }}
                                />
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card card_table2 mt-3 pt-2 pb-2">
                  <Seriesgraph pairData={formValue?.pairData} />
                </div>
                <div className="card card_table2 longshort mt-3">
                  <div className="card-body">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="Long1"
                    >
                      <Row>
                        <Col sm={12}>
                          <div>
                            <Nav
                              variant="pills"
                              className="flex-row longshortborder"
                            >
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="Long1"
                                  onClick={() => {
                                    // this.setState({"buyorsell":"buy"})
                                    setBuyorsell("buy");
                                  }}
                                >
                                  Long
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="Short1"
                                  onClick={() => {
                                    setBuyorsell("sell");
                                  }}
                                >
                                  Short
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </div>
                        </Col>
                        <Col sm={12}>
                          <Tab.Content>
                            <Tab.Pane eventKey="Long1">
                              <Long
                                pairData={pairData}
                                pair={buyorsell}
                                tickerPrice={tickerPrice}
                                checkIsApporvePaidToken={(token, account) => {
                                  checkIsApporvePaidToken(token, account);
                                }}
                                onSet={(formValue) => {
                                  console.log(
                                    "limit_form_value_data",
                                    formValue
                                  );
                                  setFormValue(formValue);
                                }}
                              />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Short1">
                              <Short
                                pairData={pairData}
                                pair={buyorsell}
                                tickerPrice={tickerPrice}
                                checkIsApporvePaidToken={(token, account) => {
                                  checkIsApporvePaidToken(token, account);
                                }}
                                onSet={(formValue) => {
                                  console.log(formValue, "formaData");
                                  setFormValue(formValue);
                                }}
                              />
                            </Tab.Pane>
                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </div>

                  <div className="connectwallet">
                    <div className="row align-items-center justify-content-center walletss">
                      {/* <div className="col-12 borderright"> */}
                      <div className="col-10">
                        {localStorage.getItem("accountInfo") == undefined ? (
                          <Button
                            className="calculate"
                            variant="warning"
                            onClick={() => {
                              setWalletModal(true);
                            }}
                          >
                            Connect wallet
                          </Button>
                        ) : checkTokenApprove == false ? (
                          <Button
                            className="calculate"
                            variant="warning"
                            onClick={() => {
                              approvePaidToken(
                                formValue.currenypaid,
                                localStorage.getItem("accountInfo")
                              );
                            }}
                          >
                            Approve
                          </Button>
                        ) : (
                          <Button
                            className="calculate"
                            variant="warning"
                            onClick={() => {
                              placeOrder();
                            }}
                          >
                            Place Order
                          </Button>
                        )}
                      </div>
                      {/* <div className="col-lg-6 col-6 text-center">
                        <p className="dailyreward mb-0">Daily Reward</p>
                      </div> */}
                    </div>

                    {walletModal && (
                      <Walletmodal
                        connect={"string"}
                        onDismiss={() => {
                          onDismiss();
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-12">
                <div className="card fullcard card_table1 mt-3">
                  <h4 className="order_book_txt">Order Book</h4>
                  <Depositmodal connect={"string"} pairData={formValue?.pairData} />
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-xl-9 col-12">
                <div className="card card_table2 positiontable positiontable_new_heh mt-3 we">
                  {/* <div className='card-body'> */}
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="first"
                  >
                    <Row>
                      <Col sm={12}>
                        <Nav variant="pills" className="flex-row paddings">
                          <Nav.Item>
                            <Nav.Link eventKey="first">Positions</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="second">Pending</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="third">History</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                      <Col sm={12}>
                        <Tab.Content>
                          <Tab.Pane eventKey="first">
                            <Positions />
                          </Tab.Pane>
                          <Tab.Pane eventKey="second">
                            <Pending />
                          </Tab.Pane>
                          <Tab.Pane eventKey="third">
                            <History />
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                  {/* </div> */}
                </div>
              </div>
              <div className="col-xl-3 col-12">
                <div className="card card_table2  custom_cheese_burger longshort mt-3">
                  <div className="card-body">
                    {/* <p className='sec-title'>CheeseBurger Perpetuals & Pool</p> */}
                    <p className="sec-title">CheeseBurger Defi</p>

                    {/* <p className='sec-para'>
                                                Staking rewards are a kind of income paid to crypto owners who help regulate and validate a cryptocurrency's transactions.</p> */}

                    <p className="sec-para">
                      Swap or Trade wide range of crypto assets within seconds
                      anywhere, anytime around the world. Get high APY & APR on
                      your favorite crypto asset staking.
                    </p>
                    <div className="card card_table3">
                      <div className="card-body py-5 text-center">
                        <p className="trader trader_ttx_big mb-0">
                          Don't have{" "}
                          <img
                            src={coinlogo}
                            className="img-fuid img_icoo_swp_logo"
                          />{" "}
                          USDT?
                        </p>

                        <Button className="mb-0 pool mt-4" variant="warning">
                          <a
                            href="https://cheeseburger-swap.maticz.in/"
                            target={"_blank"}
                          >
                            Swap Now
                          </a>
                        </Button>

                        {/* <div className='d-flex justify-content-between'>
                                                        <Button className='mb-2' variant="outline-secondary">Open / Close position</Button>
                                                        <Button className='mb-2' variant="outline-secondary">Pool Make a Profit</Button>
                                                    </div> */}

                        {/* <div className='row mt-2 align-items-center'>
                                                        <div className='col-md-3 col-3'>
                                                            <p className='trader mb-0'>Loreum Trader</p>
                                                        </div>
                                                        <div className='col-md-6 col-6'>
                                                            <ImArrowRight className='ar-r' />
                                                            <Button className='mb-0 pool' variant="warning"><Link to='/lending'>Stake</Link></Button>
                                                            <ImArrowRight className='ar-l' />
                                                        </div>
                                                        <div className='col-md-3 col-3'>
                                                            <img src={uparrow} className='img-fluid' />
                                                            <p className='trader mb-0'>Price up</p>
                                                        </div>
                                                    </div> */}
                        {/* <Button className='mb-1 pool' variant="warning">Pool</Button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default Dashboard1;