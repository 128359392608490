import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Tabs, Tab } from 'react-bootstrap';
import '../../css/styles.css';

import Adminheader from '../Adminheader';
import Adminsidebar from '../Adminsidebar';
import Footer from '../Footer';

import Whitelistmodal from "./Whitelistmodal";


import whitelist from "../../images/whitelist.png"
import users from "../../images/users.png"

import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import { addUserWhitelist } from '../action/admin';


const Whitelist = (props) => {
  const { location } = props

  const [whitelistModal, setWhitelistModal] = useState(false)
  const [whitelistUser, setWhitelistUser] = useState(location.state.whitelisted)
  console.log("props_whistList", location.state.whitelisted);
  const onDismisswhitelist = () => {
    setWhitelistModal(false)
  }
   const columns = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      cell: (record, index) => index + 1 
    },
    {
      name: "User",
      selector: "walletAddress",
      sortable: true,
      cell: (record, index) => { return record.walletAddress }
    },
    {
      name: "Status",
      selector: "whitelisted",
      sortable: true,
      cell: (record, index) => { console.log("record.whitelisted", record.whitelisted); return record.whitelisted.toString().toUpperCase() }

    }
  ];
  return (
    <div>

      <Adminheader />
      <div className='row main_view mx-0 w-100'>
        <div className='col-1 col_sidebar'>
          <Adminsidebar />
        </div>
        <div className='col-11 main_dash_sec pb-4 col_11_admin'>
          <div className='row'>
            <div className='col-12 col-md-10 col-lg-9 mx-auto'>
              <div className='mt_main_sec text-center'>
                <button type="button" className="get-started-btn btn_head mb-4 ml-3">
                  Whitelist</button>
              </div>
              <div className='mt_main_sec'>
                {/* <div className='text-right mt-5 mb-5'>
                  <button className="get-started-btn text-uppercase" onClick={() => setWhitelistModal(true)}>
                    Add Whitelist</button>
                </div> */}
                <div className='mt-3 pb-5'>
                  <div className='table-responsive'>
                    {whitelistUser && whitelistUser.length > 0 ?
                      <DataTable className='table table_user table_admin mb-0'
                        noHeader
                        sortable={false}
                        columns={columns}
                        data={whitelistUser}
                        pagination

                      /> : <p className='no_data_texter text-center'>No data</p>
                    }



                  </div>

                </div>


              </div>
            </div>
          </div>
        </div>

      </div>

      {whitelistModal && <Whitelistmodal connect={"string"} onDismiss={() => onDismisswhitelist()} />}

    </div>
  );
}


export default Whitelist