import React, { useState, useEffect, useRef, useContext, useMemo }from 'react';

import '../../css/styles.css';

import { Button } from 'react-bootstrap';

import Depositcoinmodal from "../margintrading/Depositcoinmodal";
import Withdrawcoinmodal from "../margintrading/Withdrawcoinmodal";

import eth from "../../images/eth.png"
import { approveToken } from '../../hooks/useUserContract';

const Lendingcard = (props) => {
    const {data} = props
    console.log('lendingpage',data);
    const [depositcoinModal, setDepositcoinModal] = useState(false)
    const [withdrawcoinModal, setWithdrawcoinModal] = useState(false)
    const [isApprove, setIsApprove] = useState(false)

    const onDismissdeposit = () => { setDepositcoinModal(false)}
    const onDismisswithdraw = () => {   setWithdrawcoinModal(false) }
    useEffect(()=> {
        setIsApprove(data?.approve)
    },[props])
    const tokenApprove = async() => {
        const approve = await approveToken(localStorage.getItem("accountInfo"), data?.lpToken);
        console.log('tokenApprove_tokenApprove', approve)
        if (approve) setIsApprove(true)
    }
    return (
        <>
            <div className='col-12 col-md-6 col-lg-4 col-xl-3 col_lend'>
                <div className='card inner_card mb-3 card_lending'>
                    <div className='card-body'>
                        <div className="media">
                            <img className="align-self-center mr-2 user_eth" src={eth} alt="User" />
                            <div className="media-body">
                                <p className="mt-0 text_yellow font_13 mb-0">{data?.tokenDetails?.tokenSymbol}</p>
                                <p className="mb-0 text-white font_13 mt-0 text-left">APY : 0.0000 %</p>
                            </div>
                        </div>
                        <div className='row w-100 mt-3'>
                            <div className='col-12 col-md-6 mb-3'>
                                <p className="mt-0 text_yellow font_13 mb-1">Wallet Balance</p>
                                <p className="mb-0 text-white font_13 mt-0 text-left">0 {data?.tokenDetails?.tokenSymbol}</p>
                            </div>
                            <div className='col-12 col-md-6 mb-3'>
                                <p className="mt-0 text_yellow font_13 mb-1">Your Supplied</p>
                                <p className="mb-0 text-white font_13 mt-0 text-left">0 {data?.tokenDetails?.tokenSymbol}</p>
                            </div>
                        </div>
                        <div className='card card_trans_new'>
                            <div className='card-body pb-2 px-2'>
                                <div className='row w-100'>
                                    <div className='col-12 col-md-6 mb-3'>
                                        <p className="mt-0 text_yellow font_13 mb-1">Pool Supply</p>
                                        <p className="mb-0 text-white font_13 mt-0 text-left">17.503 {data?.tokenDetails?.tokenSymbol}</p>
                                    </div>
                                    <div className='col-12 col-md-6 mb-3'>
                                        <p className="mt-0 text_yellow font_13 mb-1">Open Positions</p>
                                        <p className="mb-0 text-white font_13 mt-0 text-left">5.433 {data?.tokenDetails?.tokenSymbol}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row w-100 mt-3'>
                            <div className='col-12 col-md-6 mb-3'>
                                <p className="mt-0 text_yellow font_13 mb-1">Avail. Supply</p>
                                <p className="mb-0 text-white font_13 mt-0 text-left">12.07 {data?.tokenDetails?.tokenSymbol}</p>
                            </div>
                            <div className='col-12 col-md-6 mb-3'>
                                <p className="mt-0 text_yellow font_13 mb-1">Your Rewards</p>
                                <p className="mb-0 text-white font_13 mt-0 text-left">0 {data?.tokenDetails?.tokenSymbol}</p>
                            </div>
                        </div>
                        <div className='mt-3 btn_sec_lend'>
                            {data.approve == false ?
                                <button className='btn btn_outline_yellow mr-3' onClick={() => { tokenApprove()}}>
                                    {/* <span className='icon_bg_yellow'>
                                        <i className="fa fa-arrow-up" aria-hidden="true"></i>
                                    </span> */}
                                    <span className='ml-3'>Approve</span>
                                </button>
                                :
                                <button className='btn btn_outline_yellow mr-3' onClick={() => { setDepositcoinModal(true) }}>
                                    <span className='icon_bg_yellow'>
                                        <i className="fa fa-arrow-up" aria-hidden="true"></i>
                                    </span>
                                    <span className='ml-3'>Deposit</span>
                                </button>
                            }
                            <button className='btn btn_outline_grey' onClick={() => { setWithdrawcoinModal(true) }}>
                                <span className='icon_bg_grey'>
                                    <i className="fa fa-arrow-down" aria-hidden="true"></i>
                                </span>
                                <span className='ml-3'>Withdraw</span>
                            </button>
                        </div>
                        <div className='mt-4 text-center'>
                            <button className='btn btn_grey_fill' disabled>

                                <span>Claim Rewards</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div> 
            {depositcoinModal && <Depositcoinmodal connect={"string"} data={data} onDismiss={() => { onDismissdeposit() }} />}
            {withdrawcoinModal && <Withdrawcoinmodal connect={"string"} data={data} onDismiss={() => { onDismisswithdraw() }} />}
        </>
    );
}

export default Lendingcard