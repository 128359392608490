import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import { CURRENT_CHAIN_ID, CURRENT_RPC_URL } from "./useWeb3";
import toast, { Toaster } from 'react-hot-toast';
import { WALLET_ADDRESS } from "../redux/action";
import { createUserWallet } from "../components/action/user";
import { createSocketUser } from "../config/socketConnectivity";

export const MetamaskWallet = async (dispatch) => {
    // const RPC_URL = CURRENT_RPC_URL();
    const CHAIN_ID = CURRENT_CHAIN_ID();
    try {
        if (window.ethereum) {
            var web3 = new Web3(window.ethereum);
            try {
            console.log("window.ethereum.enable()", await window.ethereum.enable())
            const webEthereum = await window.ethereum.enable()
            // .then(async () => {
            if (webEthereum != undefined) {
                // User has allowed account access to DApp...
                const accounts = await web3.eth.getAccounts();
                console.log("accounts",accounts)
                const account = accounts[0]
                console.log("accounts 1",account)
                console.log("accounts_getBalance",await web3.eth.getBalance(account))
                const balanceFrom = await web3.eth.getBalance(account.toString());
                console.log("account in : ", account, balanceFrom);
                localStorage.setItem("accountInfo", account)
                localStorage.setItem("accountBalance", balanceFrom);
                createSocketUser(account)
                
                const createwallet = await createUserWallet({ walletAddress: account });
                console.log("createwallet_createwallet",createwallet)
                dispatch({
                    type: WALLET_ADDRESS,
                    payload: account
                });
                return web3;
            }
            // });
            } catch (e) {
                // User has denied account access to DApp...
                console.log("MetamaskWallet_err", e)
            }
        }
        // Legacy DApp Browsers
        else if (window.web3) {
            const web3 = new Web3(window.web3.currentProvider);
            const chainId = web3.eth.getChainId();
            // if(parseInt(chainId) != parseInt(CHAIN_ID)){
            //     chainIdCheck()
            //     return true
            // }
            const accounts = web3.eth.getAccounts();
            const account = accounts[0].toString();
            const balanceFrom = web3.eth.getBalance(account);

            console.log("account_in_1", account)
            localStorage.setItem("accountInfo", account)
            localStorage.setItem("accountBalance", balanceFrom);
            createSocketUser(account)
            dispatch({
                type: WALLET_ADDRESS,
                payload: account
            });
            const createwallet = createUserWallet({ walletAddress: account });
            //    localStorage.setItem("accountInfo",account)
               return web3;
        }
        // Non-DApp Browsers
        else {
            //alert('No Dapp Supported Wallet Found');
            console.log("No Dapp Supported Wallet Found")
            toast.error(`No Dapp Supported Wallet Found !`,
                {
                    style: {
                        minWidth: '300px',
                        minHeight: '55px'
                    }
                });
        }
        // return web3
    } catch (e) {
        toast.error(`Error : ${e}`,
            {
                style: {
                    minWidth: '300px',
                    minHeight: '55px'
                }
            });
    }
}

export const WalletConnect = async (dispatch) => {
    const RPC_URL = CURRENT_RPC_URL();
    const CHAIN_ID = (CURRENT_CHAIN_ID());
    //Create WalletConnect Provider
    console.log("Wallet connect");
    const RPC_DATA = {};
    RPC_DATA[CHAIN_ID] = RPC_URL
    const provider = new WalletConnectProvider({
        rpc: RPC_DATA,
        network: 'binance',
        chainId: CHAIN_ID,
        // infuraId: "69de03b5c7194095980c9233f9cf71df",
    }
    );
    provider.enable().then(function (error, result) {
        console.log('error: ' + error);
        console.log(result);
    }).catch(e => {
        //try again
        toast.error(`Error : ${e}`,
            {
                style: {
                    minWidth: '300px',
                    minHeight: '55px'
                }
            });
    });
    const web3 = new Web3(provider);
    const accounts = await web3.eth.getAccounts();
    console.log("Account : ", accounts[0]);
    const account = accounts[0].toString();
    const balanceFrom = web3.eth.getBalance(account);
    localStorage.setItem("accountInfo", account)
    localStorage.setItem("accountBalance", balanceFrom);
    createSocketUser(account)
    dispatch({
        type: WALLET_ADDRESS,
        payload: account
    });
    const createwallet = createUserWallet({ walletAddress: account });
    // localStorage.setItem("provider",JSON.stringify(provider))
    return provider;
}

