import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { Modal, Table, Dropdown, Form } from "react-bootstrap";
import modalimg from "../../images/doll.png";
import usdt from "../../images/usdt.png";
import { Scrollbars } from "react-custom-scrollbars";
import { getOrderBook } from '../action/user';
import SocketContext from '../context/SocketContext';


const Depositmodal = (props) => {
    const [depositModal, setDepositModal] = useState(true);
    const [isSuccess, setIssuccess] = useState(false);
    const [orders, setOrders] = useState([])
    const [sellLastOrder, setSellLastOrder] = useState(0)
    const [buyLastOrder, setBuyLastOrder] = useState(0)
    const { pairData } = props
    const socketContext = useContext(SocketContext)


    const orderBookDetails = async () => {
        try {
            const orderBook = await getOrderBook({ pairId: pairData._id });
            const data = orderBook.data.buyOrder.concat(orderBook.data.sellOrder);

            setOrders(data);
            setBuyLastOrder(orderBook.data.buyOrder.length > 0 ? orderBook.data.buyOrder[0]._id : 0);
            setSellLastOrder(orderBook.data.sellOrder.length > 0 ? orderBook.data.sellOrder[0]._id : 0)
        } catch (e) {
            console.log('orderBook_err', e);
        }
    }
    useEffect(() => {
        console.log('orderBook_deposit_model', props);
        orderBookDetails()
    }, [pairData]);

    useEffect(() => {
        socketContext.socket.on("orderBook", (data) => {
            console.log("orderBooksData",data)
            const res = data?.buyOrder?.concat(data.sellOrder);
            setOrders(res)
        })
    }, [])

    return (
        <>
            <div className="did1 order_book">
                <div className="orderbook-modal">
                    <div className="select-wallet orderbook pb-5 px-0">
                        <Scrollbars
                            className="modal_scroll custom_scroll"
                            style={{ height: 100 }}
                        >
                            <div className="">
                                <div className="card card_table2 orderbookss mt-3 we orderbookscard">
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th style={{ minWidth: "98px" }}>Price</th>
                                                <th style={{ minWidth: "75px" }}>Size</th>
                                                <th style={{ minWidth: "75px", textAlign: 'end' }}>Sum</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <span className="nocollect">{buyLastOrder}</span>{" "}
                                                    <span className="nocollect1"> {sellLastOrder}</span>{" "}
                                                </td>
                                            </tr>
                                            {orders.length > 0 ? orders.map((value, index) => (
                                                <tr className="tr1">
                                                    <>
                                                        <td className={value.buyorsell == 'sell' ? "text_red_table" : "text_green_table"}>{value._id}
                                                            <div>

                                                            </div>
                                                        </td>
                                                        <td className={value.buyorsell == 'sell' ? "text_red_table" : "text_green_table"}>{value.quantity?.toFixed(6)}</td>
                                                        <td className={value.buyorsell == 'sell' ? "text_red_table" : "text_green_table"} style={{ width: "45px", height: "40px" }}>{value.total?.toFixed(6)}</td>
                                                    </>
                                                </tr>
                                            )) :
                                                <td colSpan={10}>
                                                    <p className='text-center no_font_text'>No data found</p>
                                                </td>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Scrollbars>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Depositmodal;
