/** Packages */
import axios from "axios";
import { Back_Route } from "../../config/env";

/** Library */
import { toastAlert } from "../../lib/toast";

export const createStakingTokens = async (payload) => {
    try {
        await axios.post(`${Back_Route}/admin/addStakingTokens`, payload).then(res => {
            // console.log('createStakingTokens', res.data)
            return res.data
        }).catch(err => {
            toastAlert('error', err.response.data.message)
        })
    } catch (e) {
        console.log("createStakingTokens_err", e)
    }
}

export const getStakingTokensInfo = async (payload) => {
    try {
        const getTokensInfo = await axios.post(`${Back_Route}/admin/getStakingTokens`, payload).then((res) => {
            // console.log('getStakingTokensInfo', res.data.data);
            return res.data.data
        }).catch(err => {
            toastAlert('error', err.response.data.message)
        });
        return getTokensInfo
    } catch (e) {
        console.log("getStakingTokensInfo_err", e)
    }
}