import React, { Component, useState } from 'react';
import { Modal } from 'react-bootstrap';

import wall1 from "../../images/metamask_wallet.png";
import wall2 from "../../images/connect_wallet.png";

const Whitelistmodal = (props) => {
    const [whitelistModal, setWhitelistModal] = useState(true);
    const [address, setAddress] = useState("");

    

    return (
        <Modal className="wallet-modal" show={whitelistModal} centered size="sm">
            <Modal.Header className="pb-0">
                <div>
                    <h3 className="sec-head ">Add Whitelist</h3>
                </div>
                <button type="button" class="close" onClick={() => props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

            </Modal.Header>
            <Modal.Body className="select-wallet pt-0">
                <form className="dashboard_form mt-4 mb-4">
                    <label>Address:</label>
                    <div className="input-group mb-3 input_grp_dash mt-2">
                        <input type="text" className="form-control border_full" placeholder="Wallet Address" aria-label="walletaddress" aria-describedby="basic-addon2" />
                    </div>
                    <div className='text-center mt-5'>
                        <button className="get-started-btn text-uppercase">
                            Add</button>
                    </div>
                </form>

            </Modal.Body>
        </Modal>
    )
}


export default Whitelistmodal