import React, { Component, useState } from 'react';
import { Modal } from 'react-bootstrap';

import wall1 from "../../images/metamask_wallet.png";
import wall2 from "../../images/connect_wallet.png";

import { Scrollbars } from 'react-custom-scrollbars';
import { updateCms } from '../action/admin';
import { toastAlert } from '../../lib/toast';

const ExchangeCmsModel = (props) => {
    const [featureModal, setFeatureModal] = useState(true);
    const [editDate, setEditData] = useState(props.data)

    const editCmsData = async () => {
        try {
            const editCms = await updateCms(editDate);
            if(editCms.status){
                toastAlert("success", editCms.message)
                props.onDismiss()  
            }else{
                toastAlert("error", editCms.message)
            }
        } catch (e) {
            console.log("editCmsData_err", e);
        }
    }

    const onChangeData = (value, type) => {
        try{
            var update = {...editDate}
            console.log('editDate_props', update, value, type)
            if(type == 'heading'){
                update.data.heading = value
            }else if(type == 'sub'){
                update.data.subheading = value
            }else if(type == 'content'){
                update.data.content = value
            }
            setEditData(update)
        }catch(e){
            console.log("onChangeData_err", e)
        }
    }

    const onChangeData1 = (value, index, type) => {
        try{
            var update = {...editDate}
            console.log('editDate_props', update, value, type)
            if(type == 'heading'){
                update.data.data[index].heading = value
            }else if(type == 'sub'){
                update.data.data[index].subheading = value
            }else if(type == 'content'){
                update.data.data[index].content = value
            }
            console.log('onChangeData1', update , index)
            setEditData(update)
        }catch(e){
            console.log("onChangeData_err", e)
        }
    }


    return (
        <Modal className="wallet-modal" show={featureModal} centered size="sm" id="feature_modal">
            <Modal.Header className="pb-0">
                {/* {console.log("editDate_props", editDate)} */}
                <div>
                    <h3 className="sec-head ">Edit CMS</h3>
                </div>
                <button type="button" class="close" onClick={() => props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

            </Modal.Header>
            <Modal.Body className="select-wallet pt-0">
                <Scrollbars className="modal_scroll_sm" style={{ height: 100 }}>
                    {
                        props.data.identifier == "Header" || props.data.identifier == "Sub" || props.data.identifier == "Sub1" || 
                        props.data.identifier == "DefiHeading" || props.data.identifier == "DefiSub" || props.data.identifier == "DefiFooter" 
                        ?
                        <div className="dashboard_form mt-4 mb-4 pad_right_das">
                        <label>Identifier:</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">
                            <input type="text" className="form-control border_full" disabled={true} placeholder="Enter Feature Title" aria-label="featuretitle" aria-describedby="basic-addon2" value={props?.data?.identifier}/>

                        </div>

                        <label>Heading:</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">
                        <input type="text" className="form-control border_full" placeholder="Enter Feature Title" aria-label="featuretitle" aria-describedby="basic-addon2" value={editDate?.data?.heading} onChange={(e)=> {onChangeData(e.target.value, 'heading')}}/>
                        </div>

                        <label>Sub Heading:</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">
                        <input type="text" className="form-control border_full" placeholder="Enter Feature Title" aria-label="featuretitle" aria-describedby="basic-addon2" value={editDate?.data?.subheading} onChange={(e)=> {onChangeData(e.target.value, 'sub')}}/>
                        </div>

                        <label>Content:</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">
                        <input type="text" className="form-control border_full" placeholder="Enter Feature Title" aria-label="featuretitle" aria-describedby="basic-addon2" value={editDate?.data?.content} onChange={(e)=> {onChangeData(e.target.value, 'content')}}/>
                        </div>

                        
                    </div>
                    : <></>
                    }
                    {
                        props.data.identifier == "ProductAndServices" || props.data.identifier == "Roadmap" || props.data.identifier == "IdoDetails" ?
                        <div className="dashboard_form mt-4 mb-4 pad_right_das">
                        <label>Identifier:</label>
                        <div className="input-group mb-4 input_grp_dash mt-2">
                            <input type="text" className="form-control border_full" disabled={true} placeholder="Enter Feature Title" aria-label="featuretitle" aria-describedby="basic-addon2" value={props?.data?.identifier}/>

                        </div>

                                {
                                    props?.data?.data?.data?.map((value, index) => (
                                        <div className='mb-5'>

                                            <label>Heading:</label>
                                            <div className="input-group mb-3 input_grp_dash mt-2">
                                                <input type="text" className="form-control border_full" placeholder="Enter Feature Title" aria-label="featuretitle" aria-describedby="basic-addon2" value={editDate?.data?.data[index].heading} onChange={(e) => { onChangeData1(e.target.value, index, 'heading') }} />
                                            </div>

                                            <label>Sub Heading:</label>
                                            <div className="input-group mb-3 input_grp_dash mt-2">
                                                <input type="text" className="form-control border_full" placeholder="Enter Feature Title" aria-label="featuretitle" aria-describedby="basic-addon2" value={editDate?.data?.data[index].subheading} onChange={(e) => { onChangeData1(e.target.value, index, 'sub') }} />
                                            </div>

                                            <label>Content:</label>
                                            <div className="input-group mb-3 input_grp_dash mt-2">
                                                <input type="text" className="form-control border_full" placeholder="Enter Feature Title" aria-label="featuretitle" aria-describedby="basic-addon2" value={editDate?.data?.data[index].content} onChange={(e) => { onChangeData1(e.target.value, index, 'content') }} />
                                            </div>
                                        </div>
                                    ))
                                }
                        

                        
                    </div>
                    : <></>
                    }
                    <div className='text-center mt-5 pb-3'>
                            <button type='button' className="get-started-btn text-uppercase" onClick={()=> {editCmsData()}}>
                                Update</button>
                        </div>
                </Scrollbars>

            </Modal.Body>
        </Modal>
    )
}


export default ExchangeCmsModel