import React, { Component } from 'react';

import '../../css/styles.css';

import { Col, Row, Container, Nav, Dropdown, Tabs, Tab, Table, Button, Form } from 'react-bootstrap';

import Long1 from "../../images/long1.PNG";
import Die1 from "../../images/die1.PNG";

import { MdDelete } from 'react-icons/md';
import { BsPlus } from 'react-icons/bs';  
import { AiFillPlusCircle } from 'react-icons/ai';

import { Slider } from 'rsuite';

import Longshort from './longshortprice';

// import img11 from '../../images/client1.webp';

import DataTable from 'react-data-table-component';
import { columns, data } from "./data";

class Long extends Component {

    constructor(props) {
        super(props);
        this.state = {
            depositModal11: false,
            value1: 0,
            addss: [{
              "entryprice":"",
              "quantity": ""
            }]
        };
    }

    addvariant = () => {
        var data = [...this.state.addss]
        data.push({
            "entryprice":"",
            "quantity": ""
        })
        this.setState({ addss: data })
    }

    removevariant = (index) => {
        var data = [...this.state.addss]
        data.splice(index, 1)
        this.setState({ addss: data })
    }

    onDismissorder() {
        this.setState({ depositModal11: false });
    }
    render() {

        const { depositModal11 } = this.state;
        const labels1 = ['1x', '5x', '10x', '15x', '20x'];
        // const labels2 = ['img1', 'img1', 'img1', 'img1', 'img1'];
        const { value1 } = this.state;
        const handleStyle1 = {
            color: '#fff',
            fontSize: 12,
            width: 32,
            height: 22
        };

        return (

            <div>
                <div className='longs pnllongtabs'>

                    <div className='d-flex etflex3 mt-3'>
                        <div>
                            <p>Leverage</p>
                        </div>
                        <div>
                            <p>20X</p>
                        </div>
                    </div>

                    <div className='slide1 pt-2 pb-3'>
                        <Slider
                            id="customSlider1"
                            min={2}
                            max={labels1.length + 1}
                            value={value1}
                            className="custom-slider mx-2"
                            handleStyle={handleStyle1}
                            graduated
                            progress
                            // handleTitle={labels2[value1]}                          
                            onChange={v => {
                                this.setState({ value1: v });
                            }}
                            renderMark={mark => {
                                return mark + "x";
                            }}
                        />
                    </div>

                    <p className='estprice'>Maximum position at current leverage:</p>
                    <div className='etflex3 mt-1'>
                        <div>
                            <p>500,000 USDT</p>
                        </div>
                    </div>

                    <div>
                        {/* <Table>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Username</th>
                                </tr>
                            </thead>
                        </Table> */}

                        <Form className='openpricefield'>
                            <div className='row'>
                                <div className='col-md-2 col-lg-2 col-sm-2 col-xs-2 col-2'>
                                    <p className='t-p'>Open</p>
                                </div>
                                <div className='col-md-4 col-lg-4 col-sm-4 col-xs-4 col-4'>
                                    <p className='t-p'>Entry Price (USDT)</p>
                                </div>
                                <div className='col-md-4 col-lg-4 col-sm-4 col-xs-4 col-4'>
                                    <p className='t-p'>Quantity (BTC)</p>
                                </div>
                                <div className='col-md-2 col-lg-2 col-sm-2 col-xs-2 col-2'>
                                    <p className='t-p'>Action</p>
                                </div>
                            </div>
                            {this.state.addss.map((e, i) =>
                            <div>
                                <div className='row row1'>
                                    <div className='col-md-2 col-lg-2 col-sm-2 col-xs-2 col-2 borderright'>
                                        <p className='sno mb-0'>{i+1}</p>
                                    </div>
                                    <div className='col-md-4 col-lg-4 col-sm-4 col-xs-4 col-4 borderright'>
                                            <Form.Group className="mb-1 mt-1" controlId="exampleForm.ControlInput1">
                                                <Form.Control type="number" className='positionform' />
                                            </Form.Group>
                                    </div>
                                    <div className='col-md-4 col-lg-4 col-sm-4 col-xs-4 col-4 borderright'>
                                        <Form.Group className="mb-1 mt-1" controlId="exampleForm.ControlInput1">
                                            <Form.Control type="number" className='positionform'/>
                                        </Form.Group>
                                    </div>
                                    <div className='col-md-2 col-lg-2 col-sm-2 col-xs-2 col-2'>
                                    {/* <Button variant="primary" onClick={() => this.removevariant(i)}> <MdDelete /></Button> */}
                                       <p className='deletep' onClick={() => this.removevariant(i)}><MdDelete className='deleteicon' /></p>
                                    </div>                   
                                </div>
                                </div>
                             ) }
                               <div className='text-right'>
                                        {/* <Button variant="primary" onClick={() => this.addvariant()}><BsPlus /> Add Position</Button> */}
                                        <p className='addposition' onClick={() => this.addvariant()}><AiFillPlusCircle /> Add Position</p>
                                    </div>
                        </Form>
                    </div>


                    <div className='d-flex justify-content-between mt-3'>
                        <Button className='pl-3 pr-3 pt-2 pb-2 calculate' variant="warning">Calculate</Button>
                        <Button className='pl-3 pr-3 pt-2 pb-2' variant="outline-warning">Cancel</Button>
                    </div>

                    <div>
                        <h6 className='result mt-3'>Result</h6>

                        <div className='card card_table2 longborder mt-3'>
                            <div className='card-body'>
                                <div className='d-flex etflex3 mt-1'>
                                    <p className='result1 mb-2'>Avg Price (USDT)</p>
                                    <p className='mb-2 mt-0'></p>
                                </div>

                            </div>
                        </div>



                    </div>

                </div>

            </div>

        );
    }
}

export default Long;