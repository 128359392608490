import React, { Component, useEffect, useState } from 'react';
import '../../css/styles.css';
import Chart from 'react-apexcharts';
import Position from 'rsuite/esm/Overlay/Position';
import { Col, Row, Container, Nav, Dropdown, Tabs, Tab, Button } from 'react-bootstrap';
import { graphData } from '../../hooks/useGetData';
import { useLocation } from 'react-router-dom';

const Graph = (props) => {
  const { pairData } = props
  const location = useLocation()
  // console.log('tickerPrice_graph',pairData,location);
  const [currentPair, setCurrentPair]= useState('')
  const [filterBtn, setFilterBtn] = useState("1m")
  const [series, setSeries] = useState([{ data: [] }])
  const [dropDownGraph, setDropDownGraph] = useState('Mark Price');
  const [options, setOptions] = useState({
    chart: {
        type: 'candlestick',
        height: 300,
      },
      plotOptions: {
        candlestick: {
          colors: {
            upward: '#3e703a',
            downward: '#3a170f'
          }
        }
      },
      // title: {
      //   text: 'ETH / USDT',
      //   align: 'left'
      // },

      xaxis: {
        type: "datetime",
        axisBorder: {
          show: true,
          color: '#e98922'
        },
        labels: {
          style: {
              colors: '#fff',
          }
        //     labels: {
        //         style: {
        //             colors: ["#e98922","#e98922","#e98922","#e98922","#e98922","#e98922","#e98922","#e98922","#e98922","#e98922","#e98922","#e98922"],
        //             fontSize: '11px',
        //         },        
        // }
      }
    },
      yaxis:
      {
        labels: {
          show: true,
          align: 'right',
          style: {
            colors: ["#e98922", "#e98922", "#e98922", "#e98922", "#e98922", "#e98922", "#e98922", "#e98922", "#e98922", "#e98922", "#e98922", "#e98922"],
            fontSize: '11px',
          },
        },

      },

      legend: {
        show: true,
        position: 'top',
        // floating: true,
        verticalAlign: 'top',
        align: 'center',
        labels: {
          colors: "#e98922",
          useSeriesColors: false,
        },
      },
      markers: {
        show: false,
      },
      dataLabels: {
        style: {
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'bold',
            colors: undefined
        },
        background: {
          enabled: true,
          foreColor: '#000',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          background: '#000',
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
          }
        },
        dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
        }
      },
      tooltip: {
        shared: true,
        // background: '#000',
        style:{
          background: '#000',
          

        },
        custom: [function ({ seriesIndex, dataPointIndex, w }) {
          var o = w.globals.seriesCandleO[seriesIndex][dataPointIndex]
          var h = w.globals.seriesCandleH[seriesIndex][dataPointIndex]
          var l = w.globals.seriesCandleL[seriesIndex][dataPointIndex]
          // var c = w.globals.seriesCandleC[seriesIndex][dataPointIndex]
          return (
            '<div>' + "Open : " + o + '</div>' +
            '<div>' + "High : " + h + '</div>' +
            '<div>' + "Low : " + l + '</div>' 
            // '<div>' + "Close : " + c + '</div>'
          )
        }]
      }
    },)

useEffect(()=> {
  setCurrentPair(pairData?.tickerRoot)
  getGraphData(location.pathname.split("/")[2],filterBtn)
},[pairData])

  const getGraphData = async (pair, filter) => {
    const getData = await graphData(pair, filter);
    setFilterBtn(filter)
    setSeries([{ data: getData }])
  }

    return (
      <div>
        <div className='btn_date_selct'>
          <div className='fler_innerdiv_btn'>
            <div className='dropdown_months'>
              <button className={filterBtn == "15m"? "btn active" : 'btn'} onClick={() => { setFilterBtn("15m") ; getGraphData(currentPair,"15m")}}>15M</button>
              <button className={filterBtn == "1h" ? "btn active" : 'btn'} onClick={() => { setFilterBtn("1h") ;  getGraphData(currentPair,"1h") }}>1H</button>
              <button className={filterBtn == "1d" ? "btn active" : 'btn'} onClick={() => { setFilterBtn("1d") ;  getGraphData(currentPair,"1d") }}>1D</button>
              <button className={filterBtn == "1w" ? "btn active" : 'btn'} onClick={() => { setFilterBtn("1w") ;  getGraphData(currentPair,"1w") }}>1W</button>
              <button className={filterBtn == "1m" ? "btn active" : 'btn'} onClick={() => { setFilterBtn("1m") ;  getGraphData(currentPair,"1m") }}>1M</button>
            </div>
          </div>
          {/* <Dropdown onSelect={(e)=> {setDropDownGraph(e)}}>
            <Dropdown.Toggle
              className="main-style"
              id="dropdown-basic"
            >
             {dropDownGraph}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey={'Mark Price'}>
                Mark Price
              </Dropdown.Item>
              <Dropdown.Item eventKey={'Index Price'}>
                Index Price
              </Dropdown.Item>
              <Dropdown.Item eventKey={'Last Price'}>
                Last Price
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
        </div>
        <div id="chart" className='chart1 mt-4'>
          {series && series.length > 0 &&
            <Chart options={options} series={series}
              type="candlestick" height={300} width="100%" />}
        </div>
      </div>
    );
}

export default Graph;