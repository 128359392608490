import React, { Component } from 'react';
import { useContext, useEffect, useState } from "react";
import { Modal, Table, Form, Tab, Tabs, Nav, Dropdown, Button, Col, Container, Row } from 'react-bootstrap';

import Long from '../targetlongtab';
import Short from '../targetshorttab';

class Pnl extends Component {



    render() {

        return (
            <div>

                <div className='pnltabs'>
                    <div >
                        <Tab.Container id="left-tabs-example" defaultActiveKey="Long11">
                                                <Row className='mx-0'>
                                                    <Col sm={12}>
                                                        <div>
                                                            <Nav variant="pills" className="flex-row longshortborder">

                                                                <Nav.Item>
                                                                    <Nav.Link eventKey="Long11">Long</Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey="Short11">Short</Nav.Link>
                                                                </Nav.Item>

                                                            </Nav>
                                                        </div>
                                                    </Col>
                                                    <Col sm={12}>
                                                        <Tab.Content>
                                                            <Tab.Pane eventKey="Long11">
                                                                <Long />
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="Short11">
                                                                <Short />
                                                            </Tab.Pane>
                                                        </Tab.Content>
                                                    </Col>
                                                </Row>
                                            </Tab.Container>

                    </div>

                

                </div>



            </div>
        )
    }

}


export default Pnl;