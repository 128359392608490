import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Tabs, Tab } from 'react-bootstrap';
import '../../css/styles.css';

import Innerheader from '../Innerheader';
import Sidebar from '../Sidebar';
import Footer from '../Footer';


import Announcementmodal2 from "../../components/Announcementmodal2";

import Graph from './Graph';
import Position from './Position';
import Trade from './Trade';

import Long from './Long';
import Short from './Short';


import globe from "../../images/earth.png"
import usdt from "../../images/usdt.png"
import eth from "../../images/eth.png"

// import { Chart } from "react-google-charts";



// export const data = [

//     ["DAY", "", "", "", ""],
//     ["10", 22000,25800,20700,29600],
//     ["20", 19000,20100,22200,25300],
//     ["30", 19000,18100,15200,11300],
//     ["40", 20000,26900,27800,28700],
//     ["50", 19000,18900,18800,18700],
//     ["60", 19000,20100,22200,25300],
//     ["70", 17000,18100,19200,20100],
//     ["80", 20000,22100,28200,30300],
//     ["90", 10000,12100,14200,19300],
//     ["100",10000,10100,10200,10300],
//     ["110",15000,14900,14800,14700],
//     ["120",10000,10100,10200,10300],

//   ];


//   export const options = {
//     legend: "none",
//     bar: { groupWidth: "100%" }, 
//     candlestick: {
//       fallingColor: { strokeWidth: 0, fill: "#a52714" },
//       risingColor: { strokeWidth: 0, fill: "#0f9d58" }, 
//     },
//   };

class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            announcementModal2: true,
        }
    }


    onDismiss1() {
        this.setState({ announcementModal2: false });
    }


    render() {
        const { announcementModal2 } = this.state

        return (
            <div>
                <Innerheader />
                <div className='row main_view mx-0'>
                    <div className='col-1 col_sidebar'>
                        <Sidebar />
                    </div>
                    <div className='col-11 col-lg-9 main_dash_sec pb-4'>
                        <div className='row mt_main_sec'>
                            <div className='col-12'>
                                <div className='card inner_card'>
                                    <div className='card-body'>
                                        <div className='d-flex w-100 justify-content-between align-items-center mob_flex'>
                                            <Dropdown className='btn_grey_dd btn_grey_dd_dash'>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    <div className='d-flex'>
                                                        <span>
                                                            <img src={eth} className="coin_img_1" />
                                                            <img src={usdt} className="coin_img_2" />
                                                        </span>
                                                        <span>ETH / USDT</span>
                                                        <span className='arrow_bg'><i className="fa fa-angle-down" aria-hidden="true"></i></span>

                                                    </div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className='dd_menu_grey'>
                                                    <Dropdown.Item>
                                                        <div className='d-flex'>
                                                            <p className='mb-0'>
                                                                <span className='mr-3'>
                                                                    <img src={eth} className="coin_img_1" />
                                                                    <img src={usdt} className="coin_img_2" />
                                                                </span>
                                                                <span>ETH / USDT</span>
                                                            </p>
                                                        </div>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item>
                                                        <div className='d-flex'>
                                                            <p className='mb-0'>
                                                                <span className='mr-3'>
                                                                    <img src={eth} className="coin_img_1" />
                                                                    <img src={usdt} className="coin_img_2" />
                                                                </span>
                                                                <span>ETH / USDT</span>
                                                            </p>
                                                        </div>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item>
                                                        <div className='d-flex'>
                                                            <p className='mb-0'>
                                                                <span className='mr-3'>
                                                                    <img src={eth} className="coin_img_1" />
                                                                    <img src={usdt} className="coin_img_2" />
                                                                </span>
                                                                <span>ETH / USDT</span>
                                                            </p>
                                                        </div>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item>
                                                        <div className='d-flex'>
                                                            <p className='mb-0'>
                                                                <span className='mr-3'>
                                                                    <img src={eth} className="coin_img_1" />
                                                                    <img src={usdt} className="coin_img_2" />
                                                                </span>
                                                                <span>ETH / USDT</span>
                                                            </p>
                                                        </div>
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <span className='text_yellow'>Current Time in UTC : 10:48:11</span>
                                            <span className='d-flex justify-content-between align-items-center'>
                                                <span className='text-white mr-3'>USDT Price</span>
                                                <span class="custom-control custom-switch">
                                                    <input type="checkbox" class="custom-control-input" id="customSwitch1" />
                                                    <label class="custom-control-label" for="customSwitch1"></label>
                                                </span>
                                                <span className='text-grey ml-4'>ETH Price</span>
                                            </span>
                                        </div>
                                        <div className='mt-3'>
                                            <Graph />
                                            {/* <Chart
                                        chartType="CandlestickChart"
                                        width="100%"
                                        height="400px"
                                        data={data}
                                        options={options}
                                        /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 mt-4'>
                                <Tabs defaultActiveKey="positions" id="uncontrolled-tab-example" className="mb-4 tab_style_1">
                                    <Tab eventKey="positions" title="YOUR POSITIONS">
                                        {([...Array(8)]).map((item, index) => {
                                            return <Position />
                                        })}

                                        <p className='text-center no_item_text d-none'>You currently have no positions.</p>
                                    </Tab>
                                    <Tab eventKey="history" title="TRADE HISTORY">
                                        {
                                            ([...Array(8)]).map((item, index) => {
                                                return <Trade />
                                            })
                                        }
                                        <p className='text-center no_item_text d-none'>You currently have no trades.</p>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-lg-2 col_2_view mt_main_sec pb-4'>
                        <div className='card inner_card h-100'>
                            <div className='card-body px-0'>
                                <Tabs defaultActiveKey="long" id="uncontrolled-tab-example" className="mb-5 mt-3 tab_style_1 tab_style_cen">
                                    <Tab eventKey="long" title="LONG">
                                        <Long />
                                    </Tab>
                                    <Tab eventKey="short" title="SHORT">
                                        <Short />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                    <div className='w-100 footer_inner'>
                        <Footer />
                    </div>
                    {announcementModal2 && <Announcementmodal2 connect={"string"} onDismiss={() => this.onDismiss1()} />}

                </div>
            </div>
        );
    }
}

export default Dashboard