import React, { Component, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import logo from "../../images/logo1.png";
import { adminLogin } from "../action/admin";

const Adminlogin = (props) => {
  const history = useHistory();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const login = async () => {
    const payload = { userName: userName, password: password };
    const isValid = await loginValidation();
    if (isValid) {
      const checkLogin = await adminLogin(payload);
      console.log("checkLogincheckLogincheckLogin", checkLogin);
      if (checkLogin) {
        history.push("/admindashboard");
      }
    }
  };

  const loginValidation = async () => {
    let error = {};
    if (userName == "" || userName == null || userName == undefined) {
      error.userName = "Username is required";
    }
    if (password == "" || password == null || password == undefined) {
      error.password = "Password is required";
    }
    setError(error);
    return Object.keys(error).length > 0 ? false : true;
  };

  return (
    <div>
      <div className="page-top py-5 admin_bg">
        <div className="container container_custom">
          {/* <div className="text-center sec-head">
            <img src={logo} className="img-fluid adminlogo" />
          </div>
          <div className="text-center mt-4">
            <button className="get-started-btn btn_head mb-4 ml-3">
              Login
            </button>
          </div> */}

          <div className="row mt-4 pb-4">
            <div className="col-12 col-sm-7 col-lg-6 col-xl-5 mx-auto">
              <p className="text-white text-center font_12 mb-4">
                Lorem ipsum dolor sit amet, Ut enim ad minim veniam, Lorem ipsum
                dolor sit amet, Ut enim ad minim veniam
              </p>

              <div className="card inner_card mt-5">
                <div className="card-body">
                  <form className="dashboard_form mt-3 mb-4">
                    <label>User Name:</label>
                    <div className="input-group mb-3 input_grp_dash mt-2">
                      <input
                        type="text"
                        className="form-control border_full"
                        placeholder="User Name"
                        aria-label="username"
                        aria-describedby="basic-addon2"
                        onChange={(e) => {
                          setUserName(e.target.value);
                        }}
                      />
                    </div>
                    <p className="error_modal_red">{error?.userName}</p>

                    <label>Password:</label>
                    <div className="input-group mb-3 input_grp_dash mt-2">
                      <input
                        type="password"
                        className="form-control border_full"
                        placeholder="Password"
                        aria-label="password"
                        aria-describedby="basic-addon2"
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                      />
                    </div>
                    <p className="error_modal_red">{error?.password}</p>

                    <div className="text-center mt-5">
                      <button
                        type="button"
                        className="get-started-btn text-uppercase"
                        onClick={() => {
                          login();
                        }}
                      >
                        Login
                      </button>
                    </div>
                    {/* <hr className='hr_grey' /> */}
                    {/* <div className='row'>
                                            <div className='col-12 col-md-6 mb-3 mb-md-0'>
                                                <p className='text-white mb-0'>Forgot Your Password?</p>

                                            </div>
                                            <div className='col-12 col-md-6 mb-3 mb-md-0 text-md-right'>
                                                <Link to="/adminforgot" className='btn btn_trans_forgot'>Reset Password</Link>


                                            </div>
                                        </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Adminlogin;
