import React, { Component, useState } from 'react';
import { Modal, Dropdown } from 'react-bootstrap';

import wall1 from "../../images/metamask_wallet.png";
import wall2 from "../../images/connect_wallet.png";
import { Scrollbars } from 'react-custom-scrollbars';
import { addCurrency } from '../action/admin';



const CurrencyModel = (props) => {

    const [leverageModal, setLeverageModal] = useState(true)
    const [currencySymbol, setCurrencySymbol] = useState('')
    const [currencyName, setCurrencyName] = useState('')
    const [currencyImage, setCurrencyImage] = useState('')
    const [network, setNetwork] = useState('')
    const [tokenType, setTokenType] = useState('')
    const [minABI, setMinABI] = useState('')
    const [currencyType, setCurrencyType] = useState('')
    const [contractAddress, setContractAddress] = useState('')
    const [errror, setError] = useState()

    const currencyTypes = [
        { name: "Token", value: "Token" },
        { name: "Crypto", value: "Crypto" }
    ]
    const addNewCurrency = async () => {
        const formData = new FormData()
        formData.append("currencySymbol", currencySymbol)
        formData.append("currencyName", currencyName)
        formData.append("file", currencyImage)
        formData.append("network", network)
        formData.append("currencyType", currencyType)
        formData.append("tokenType", tokenType)
        formData.append("minABI", minABI)
        formData.append("contractAddress", contractAddress)
        const validation = await currencyFieldVaidation()
        console.log("validation_addCur", validation)
        if (validation) {
            const add = addCurrency(formData);
            console.log("New_Add_currency", add)
        }
    }

    const currencyFieldVaidation = async (data) => {
        try {
            let errors = {}
            if (currencySymbol == null || currencySymbol == undefined || currencySymbol == '') {
                errors.currencySymbol = "CurrencySymbol is required"
            }
            if (currencyName == null || currencyName == undefined || currencyName == '') {
                errors.currencyName = "CurrencyName is required"
            }
            if (currencyImage == null || currencyImage == undefined || currencyImage == '') {
                errors.currencyImage = "CurrencyImage is required"
            }
            setError(errors)

            if (Object.keys(errors).length > 0) {
                // console.log(document.querySelectorAll(".error_modal_red")[0],"id 0");
                var scrollyoffset = document.querySelectorAll(".error_modal_red")[0].offsetTop - 100;
                // console.log(scrollyoffset,"scrollyoffset")
                // document.getElementById("modal_scroll").scrollToStart({animated: true})
                document.querySelectorAll("#modal_scroll>div")[0].scrollTo(0, scrollyoffset);
            }
            return Object.keys(errors).length > 0 ? false : true
        } catch (e) {
            console.log('currencyFieldVaidation', e);
        }
    }

    return (
        <Modal className="wallet-modal" show={leverageModal} centered size="sm">
            <Modal.Header className="pb-0">
                <div>
                    <h3 className="sec-head ">Add Currency</h3>
                </div>
                <button type="button" class="close" onClick={() => props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

            </Modal.Header>
            <Modal.Body className="select-wallet pt-0">
                <Scrollbars className="modal_scroll_sm"
                    // ref={ref => {this.scrollView = ref}} 
                    style={{ height: 100 }} id="modal_scroll">
                    <form className="dashboard_form pad_right_das mt-4 mb-4">

                        <label>Currency Symbol *</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">
                            <input type="text" className="form-control border_full" placeholder="Enter Currency Symbol" aria-label="multiplier" aria-describedby="basic-addon2" onChange={(e) => { setCurrencySymbol(e.target.value) }} />
                        </div>
                        <p className='error_modal_red'>{errror?.currencySymbol}</p>


                        <label>Currency Name *</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">
                            <input type="text" className="form-control border_full" placeholder="Enter Currency Name" aria-label="managementfee" aria-describedby="basic-addon2" onChange={(e) => { setCurrencyName(e.target.value) }} />
                        </div>

                        <p className='error_modal_red'>{errror?.currencyName}</p>


                        <label>Currency Image *</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">

                            <div className="custom-file mt-2">
                                <input type="file" className="custom-file-input" id="customFile" placeholder="Choose Currency Image" aria-label="managementfee" aria-describedby="basic-addon2" onChange={(e) => { console.log('fileOnchange', e.target.files[0]); setCurrencyImage(e.target.files[0]) }} />
                                <label className="custom-file-label" for="customFile">{currencyImage == '' ? 'Choose file' : currencyImage?.name}</label>
                                {/* {currencyImage != "" ? <img src={currencyImage} width='20px' height='20px'/> : ""} */}
                            </div>


                            {/* <input type="file" className="form-control border_full" placeholder="Choose Currency Image" aria-label="managementfee" aria-describedby="basic-addon2" onChange={(e)=> {setCurrencyImage(e.target.files)}}/>
                            <span>{errror?.currencyImage}</span> */}
                        </div>
                        <p className='error_modal_red'>{errror?.currencyImage}</p>


                        <label>Network</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">
                            <input type="text" className="form-control border_full" placeholder="Enter Network" aria-label="managementfee" aria-describedby="basic-addon2" onChange={(e) => { setNetwork(e.target.value) }} />
                        </div>

                        <label>Currency Type</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">
                            <Dropdown className='btn_grey_dd dd_posit_sort dd_posit_sort_fullw'>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    <div className='d-flex'>

                                        <span>{currencyType != "" ? currencyType : "select currency type"}</span>
                                        <span className='arrow_bg'><i className="fa fa-angle-down" aria-hidden="true"></i></span>

                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='dd_menu_grey sort_dd_menu_grey sort_dd_menu_grey_full'>

                                {currencyTypes.map((val, i) => {
                                    return (

                                        <Dropdown.Item onClick={() => {
                                            console.log("data", "dats", val.value)
                                            setCurrencyType(val.value)
                                        }}>
                                            <div className='d-flex px-3'>

                                                <span>{val.name}</span>

                                            </div>
                                        </Dropdown.Item>
                                    )
                                })
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                    <label>Token Type</label>
                    <div className="input-group mb-3 input_grp_dash mt-2">
                        <input type="text" className="form-control border_full" placeholder="Enter Token Type" aria-label="managementfee" aria-describedby="basic-addon2" onChange={(e) => { setTokenType(e.target.value) }} />
                    </div>

                    <label>MIN-ABI</label>
                    <div className="input-group mb-3 input_grp_dash mt-2">
                        <input type="text" className="form-control border_full" placeholder="Enter Contract ABI" aria-label="managementfee" aria-describedby="basic-addon2" onChange={(e) => { setMinABI(e.target.value) }} />
                    </div>

                    <label>Contract Address</label>
                    <div className="input-group mb-3 input_grp_dash mt-2">
                        <input type="text" className="form-control border_full" placeholder="Enter Contract Address" aria-label="managementfee" aria-describedby="basic-addon2" onChange={(e) => { setContractAddress(e.target.value) }} />
                    </div>

                    <div className='text-center mt-5'>
                        <button type="button" onClick={() => { addNewCurrency() }} className="get-started-btn text-uppercase" >
                            Add</button>
                    </div>
                </form>
            </Scrollbars>

        </Modal.Body>
        </Modal >
    )
}

export default CurrencyModel