import React, { Component, useState } from 'react';
import { Dropdown, Modal } from 'react-bootstrap';

import wall1 from "../../images/metamask_wallet.png";
import wall2 from "../../images/connect_wallet.png";

import { Scrollbars } from 'react-custom-scrollbars';
import { editTokensDetails } from '../action/admin';
import { toastAlert } from '../../lib/toast';

const TokenListEditModal = (props) => {
    const [featureModal, setFeatureModal] = useState(true)
    console.log("TokenListEditModal_props", props?.data)
    const [data, setData] = useState(props?.data)
    const [file, setFile] = useState("")
    const [error, setError] = useState({})

    const Types = [
        { name: "Testnet", value: "testnet" },
        { name: "Mainnet", value: "mainnet" }
    ]
    const onChange = async (value, field) => {
        try {
            console.log("onChange_data", value);
            let edit = { ...data }
            edit[field] = value
            console.log("After_edit", edit)
            setData(edit)
        } catch (e) {
            console.log("onChange_err");
        }
    }
    const validation = async () => {
        try {
            let errors = {}
            if (data?.name == '' || data?.name == undefined || data?.name == null) {
                errors['name'] = "Name Field is required"
            } if (data?.symbol == '' || data?.symbol == undefined || data?.symbol == null) {
                errors['symbol'] = "Symbol Field is required"
            } if (data?.decimals == '' || data?.decimals == undefined || data?.decimals == null) {
                errors['decimal'] = "Decimal Field is required"
            } if (data?.address == '' || data?.address == undefined || data?.address == null) {
                errors['address'] = "Address Field is required"
            }
            // if (data?.url == '' || data?.url == undefined || data?.url == null) {
            //     errors['url'] = "Url Field is required"
            // }
            if (data?.chainId == '' || data?.chainId == undefined || data?.chainId == null) {
                errors['chainid'] = "Chainid Field is required"
            }
            setError(errors)
            return Object.keys(errors).length > 0 ? false : true
        } catch (e) {
            console.log("validation_err", e);
        }
    }
    const onSubmit = async () => {
        try {
            const validate = await validation();
            if (validate) {
                const formData = new FormData();
                formData.append("name", data?.name)
                formData.append("symbol", data?.symbol)
                formData.append("decimals", data?.decimals)
                formData.append("address", data?.address)
                // formData.append("url", data?.url)
                formData.append("file", data?.logoURI)
                formData.append("chainId", data?.chainId)
                // formData.append("type", data?.type)
                formData.append("id", data?._id)
                const edit = await editTokensDetails(formData);
                console.log('edit_addTokensDetails', edit)
                if (edit.status) {
                    toastAlert('success', edit.message)
                    props.onDismiss()
                } else {

                }
            }

        } catch (e) {
            console.log("onChangeFile_err", e)
        }
    }

    return (
        <Modal className="wallet-modal" show={featureModal} centered size="sm" id="feature_modal">
            <Modal.Header className="pb-0">
                <div>
                    <h3 className="sec-head ">Edit Token</h3>
                </div>
                <button type="button" class="close" onClick={() => props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

            </Modal.Header>
            <Modal.Body className="select-wallet pt-0">
                <Scrollbars className="modal_scroll_sm" style={{ height: 100 }}>
                    <div className="dashboard_form mt-4 mb-4 pad_right_das">
                        <label>Name:</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">
                            <input type="text" className="form-control border_full" placeholder="Enter Name" aria-label="featuretitle" aria-describedby="basic-addon2"
                                value={data?.name} onChange={(e) => { onChange(e.target.value, 'name') }}
                            />
                            <div className='w-100 ms-1'><span className='text-danger'>{error?.name}</span></div>
                        </div>

                        <label>Symbol:</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">
                            <input type="text" className="form-control border_full" placeholder="Enter Symbol" aria-label="featuretitle" aria-describedby="basic-addon2"
                                value={data?.symbol} onChange={(e) => { onChange(e.target.value, 'symbol') }}
                            />
                            <div className='w-100 ms-1'><span className='text-danger'>{error?.symbol}</span></div>
                        </div>

                        <label>Decimal:</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">
                            <input type="number" className="form-control border_full" placeholder="Enter Decimal" aria-label="featuretitle" aria-describedby="basic-addon2"
                                value={data?.decimals} onChange={(e) => { onChange(e.target.value, 'decimals') }}
                            />
                            <div className='w-100 ms-1'><span className='text-danger'>{error?.decimal}</span></div>
                        </div>

                        <label>Address:</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">
                            <input type="text" className="form-control border_full" placeholder="Enter Address" aria-label="featuretitle" aria-describedby="basic-addon2"
                                value={data?.address} onChange={(e) => { onChange(e.target.value, 'address') }}
                            />
                            <div className='w-100 ms-1'><span className='text-danger'>{error?.address}</span></div>
                        </div>


                        <label>Chain Id:</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">
                            <input type="text" className="form-control border_full" placeholder="Enter Chain-Id" aria-label="featuretitle" aria-describedby="basic-addon2"
                                value={data?.chainId} onChange={(e) => { onChange(e.target.value, 'chainId') }}
                            />
                            <div className='w-100 ms-1'><span className='text-danger'>{error?.chaind}</span></div>
                        </div>

                        {/* <label>Type:</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">
                            <Dropdown className='btn_grey_dd dd_posit_sort dd_posit_sort_fullw'>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    <div className='d-flex'>

                                        <span>{data?.type != "" ? data?.type?.toUpperCase() : "select type"}</span>
                                        <span className='arrow_bg'><i className="fa fa-angle-down" aria-hidden="true"></i></span>

                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='dd_menu_grey sort_dd_menu_grey sort_dd_menu_grey_full'>

                                {Types.map((val, i) => {
                                    return (

                                        <Dropdown.Item onClick={() => {
                                            console.log("data", "dats", val.value)
                                            onChange(val.value, 'type')
                                        }}>
                                            <div className='d-flex px-3'>

                                                <span>{val.name}</span>

                                            </div>
                                        </Dropdown.Item>
                                    )
                                })
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                        <div className='w-100 ms-1'><span className='text-danger'>{error?.type}</span></div>
                    </div> */}

                        {/* <label>Url:</label>
                        <div className="input-group mb-3 input_grp_dash mt-2">
                            <input type="text" className="form-control border_full" placeholder="Enter Url" aria-label="featuretitle" aria-describedby="basic-addon2"
                                value={data?.url} onChange={(e)=> {onChange(e.target.value, 'url')}}
                            />
                            <div className='w-100 ms-1'><span className='text-danger'>{error?.url}</span></div>
                        </div> */}

                        {/* {console.log("errrrrrrrrrr/rrrrrrrr", data)} */}
                        <label>Image:</label>
                        {/* <div className="custom-file mt-2"> */}
                        {/* <input type="file" className="custom-file-input" id="customFile"
                                onChange={(e) => {
                                    if (['JPG', 'JPEG', 'PNG'].includes((e.target.files[0].name).split(".")[e.target.files[0].name.split('.').length - 1].toUpperCase())) {
                                        setFile(e.target.files[0])
                                        onChange(e.target.files[0].name, 'image')
                                    } else {
                                        console.log("dfadadsfasd")
                                        setError(prevState => ({
                                            ...prevState,
                                            file: "Invalid file formate",
                                          }))
                                    }
                                }}
                            /> */}
                        {/* <input type="text" className="form-control border_full" placeholder="Enter Logo-URI" aria-label="featuretitle" aria-describedby="basic-addon2" value={data?.logoURI}
                             onChange={(e)=> {onChange(e.target.value, 'logoURI')}}></input>
                            <label className="custom-file-label" for="customFile">{data.image == '' ? "Choose file" : data?.image}</label>
                        </div> */}
                        <div className="input-group mb-3 input_grp_dash mt-2">
                            <input type="text" className="form-control border_full" placeholder="Enter Logo-URI" aria-label="featuretitle" aria-describedby="basic-addon2" value={data?.logoURI}
                             onChange={(e)=> {onChange(e.target.value, 'logoURI')}}></input>
                        </div>
                        <div className='text-center mt-5 pb-3'>
                            <button className="get-started-btn text-uppercase" onClick={() => onSubmit()}> Update</button>
                        </div>

                    </div>
                </Scrollbars>

            </Modal.Body>
        </Modal>
    )
}


export default TokenListEditModal