import React, { Component } from 'react'
import { Link } from 'react-router-dom';


import logo from "../../images/logo1.png"
import logo1 from "../../images/logo2.png"

class Adminforgot extends Component {

    constructor(props)
    {
        super(props);
    }


   render(){

       return(
           <div>
                <div className='page-top py-5 admin_bg'>
              <div className='container container_custom'>
              <div className='text-center sec-head'>
            <img src={logo1} className="img-fluid adminlogo" />
            </div>
            <div className='text-center mt-4'>
            <button className="get-started-btn btn_head mb-4 ml-3">
                        Reset Password</button>
                        </div>

                           <div className='row mt-4 pb-4'>
                               <div className='col-12 col-sm-7 col-lg-6 col-xl-5 mx-auto'>
                        <p className='text-white text-center font_12 mb-4'>Lorem ipsum dolor sit amet,  Ut enim ad minim veniam, Lorem ipsum dolor sit amet,  Ut enim ad minim veniam</p>

                                   <div className='card inner_card mt-5'>
                                       <div className='card-body'>
                                       <form className="dashboard_form mt-3 mb-4">
                                       <label>Password:</label>
                                        <div className="input-group mb-3 input_grp_dash mt-2">
                                        <input type="text" className="form-control border_full" placeholder="Password" aria-label="password" aria-describedby="basic-addon2" />
                                        
                                        </div>

                                        <label>Confirm Password:</label>
                                        <div className="input-group mb-3 input_grp_dash mt-2">
                                        <input type="password" className="form-control border_full" placeholder="Confirm Password" aria-label="confirmpassword" aria-describedby="basic-addon2" />
                                        
                                        </div>
                                       
                                            <div className='text-center mt-5'>
                                            <button className="get-started-btn text-uppercase">
                                        Reset</button>
                                        </div>
                                        <hr className='hr_grey'/>
                                        <div className='row'>
                                            <div className='col-12 col-md-6 mb-3 mb-md-0'>
                                            <p className='text-white mb-0'>Back to Login?</p>

                                            </div>
                                            <div className='col-12 col-md-6 mb-3 mb-md-0 text-md-right'>
                                            <Link to="/adminlogin" className='btn btn_trans_forgot'>Login</Link>


                                            </div>
                                        </div>
                                        </form>
                                       </div>
                                   </div>
                               </div>
                           </div>
                           </div>
              </div>
              </div>
         
       )
   }
}


export default Adminforgot