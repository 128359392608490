import axios from "axios";
import * as config from '../config/env'

export const graphData = async (pair, filter) => {
    // console.log('graphData_helper',pair, filter);
    var dataPoints = [];
    try {
        const data = await axios.get(`https://api.binance.com/api/v3/klines?symbol=${pair}&interval=${filter}`);
        for (var i = 0; i < 200; i++) {
            dataPoints.push({
                x: data.data[i][0],
                y: [data.data[i][1], data.data[i][2], data.data[i][3], data.data[i][4]]
            });
        }
        return dataPoints
    } catch (e) {
        console.log("graphData", e)
    }
}
