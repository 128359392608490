/** Front_Actions */ 

export const WALLET_ADDRESS = "WALLET_ADDRESS";


/** Admin_Actions */ 

export const ADDRESS = "ADDRESS";

/**USER_ACTION */
export const WALLETS = "WALLETS";
/**Trade_Actions */

export const ORDER_PLACING = "ORDER_PLACING"
export const OPEN_ORDERS = "OPEN_ORDERS"
export const POSITION_ORDERS = "POSITION_ORDERS"
export const CANCEL_ORDERS = "CANCEL_ORDERS"