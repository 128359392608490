import React, { useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router, Route, Switch,Redirect } from 'react-router-dom';
import './App.css';
import Landing from './components/Landing';
import Dashboard from './components/margintrading/Dashboard';
import Positionfull from './components/position/Positionfull';
import Lending from './components/lending/Lending';
import Dashboard1 from './components/yamada/dashboard1';
import Adminlogin from './components/admin/Adminlogin';
import Adminforgot from './components/admin/Adminforgot';
import Admindashboard from './components/admin/Admindashboard';
import Leverage from './components/admin/Leverage';
import Currency from './components/admin/Currency';
import Whitelist from './components/admin/Whitelist';
import Users from './components/admin/Users';
import Feature from './components/admin/Feature';
import TokenList from './components/admin/TokenList'
import Pools from './components/admin/Pools'
import Settings from './components/admin/Settings';
import Cms from './components/admin/ExchangeCms';
import DefiCms from './components/admin/DefiCms'
import HelperRoute from './helperRoutes';

/** Config */
import { socket } from './config/socketConnectivity';

// ** Redex ** 
import store from './redux/store';
import { Provider } from 'react-redux';
import SocketContext from './components/context/SocketContext';
import AdminPairManagment from './components/admin/AdminPairManagment';
import Adminpairposition from './components/admin/Adminpairposition';
import TradeHistory from './components/admin/TradeHistory';


function App() {


  return (
    <Provider store={store}>
      <div>
        <SocketContext.Provider value={({ socket })}>
          <div><Toaster /></div>
          <HelperRoute />
          <Router basename={'/'}>
            {/* <Route exact path='/' component={Landing} />
           <Route exact path='/home' component={Landing} />
            <Route exact path='/margintrading' component={Dashboard} /> */}
            <Route exact path="/" component={Landing}>
              {/* <Redirect to="/trade/:tikerRoot?" /> */}
            </Route>
            <Route exact path='/trade/:tikerRoot?' component={Dashboard1} />
            <Route exact path='/position' component={Positionfull} />
            <Route exact path='/lending' component={Lending} />

            <Route exact path='/adminlogin' component={Adminlogin} />
            <Route exact path='/adminforgot' component={Adminforgot} />
            <Route exact path='/admindashboard' component={Admindashboard} />
            <Route exact path='/adminpairmanagment' component={AdminPairManagment} />
            <Route exact path='/leverage' component={Leverage} />
            <Route exact path='/tradeHistory' component={TradeHistory} />
            <Route exact path='/currency' component={Currency} />
            <Route exact path='/whitelist' component={Whitelist} />
            <Route exact path='/users' component={Users} />
            <Route exact path='/feature' component={Feature} />
            <Route exact path='/settings' component={Settings} />
            <Route exact path='/tokenList' component={TokenList} />
            <Route exact path='/pools' component={Pools} />
            <Route exact path='/cms' component={Cms} />
            <Route exact path='/defiCms' component={DefiCms} />
            
          </Router>
        </SocketContext.Provider>
      </div>
    </Provider>

  );
}

export default App;
