import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';

import '../../css/styles.css';

import { Col, Row, Container, Nav, Dropdown, Tabs, Tab, Table, Button, Form } from 'react-bootstrap';

import Long1 from "../../images/long1.PNG";

import { Slider } from 'rsuite';

// import Die1 from "../../images/die1.PNG";
import Die1 from "../../images/binance (1).png";

import Longshort from './longshortprice';

import DataTable from 'react-data-table-component';
import { columns, data } from "./data";
import { priceConversion, liquidityCalculation } from '../../hooks/useCalculation';
import SocketContext from '../context/SocketContext';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toastAlert } from '../../lib/toast';

const Short = (props) => {
    const initalFormValue = {
        price: 0,
        leverage: 1,
        quantity: 0,
        Principal: 0,
        currenypaid: '',
        pairId: "",
        ordertype: "market",
        takePrice: 0,
        stopLossPrice: 0,
        isTakeorStop: false,
        slippage: 0
    }
    const history = useHistory()
    const { wallets } = useSelector((state) => (state.Auth))
    const socketContext = useContext(SocketContext)
    const [depositModal11, SetdepositModal11] = useState(false)
    const [value1, Setvalue1] = useState(0)
    const [pairs, Setpairs] = useState({})
    const [leverage, Setleverage] = useState(1)
    const [balance, Setbalance] = useState(0)
    const [dropdown, Setdropdown] = useState(true)
    const [total, Settotal] = useState(0)
    const [type, Settype] = useState('short')
    const [principal, Setprincipal] = useState(0)
    const [quantity, Setquantity] = useState(0)
    const [paidCurrency, SetpaidCurrency] = useState(0)
    const [liqPrice, SetliqPrice] = useState(0)
    const [takeProfit, SettakeProfit] = useState(0)
    const [stopLoss, SetstopLoss] = useState(0)
    const [ordertype, Setordertype] = useState('market')
    const [leverageQuantity, SetleverageQuantity] = useState(0)
    const [leveragePaidCurrency, SetleveragePaidCurrency] = useState(0)
    const [openFee, SetopenFee] = useState(0)
    const [showValue, SetshowValue] = useState(false)
    const [showValue2, SetshowValue2] = useState(false)
    const [marketPrice, setMarketPrice] = useState(0)
    const [qtyAfterFee,setQtyAfterFee] = useState(0)

    const { pairData, tickerPrice } = props

    /** OrderPlacing-api-data*/
    const [orderData, setOrderData] = useState(initalFormValue);

    useEffect(() => {
        console.log('walletswalletswalletswalletswallets', wallets);
        console.log('walletsorderData', orderData);
        if (wallets && wallets.length > 0) {
            let assets = wallets.find((val) => (val.currencySymbol == pairs.secondCurrency))
            if (assets) {
                Setbalance(assets?.balance)
            }
        } else {
            Setbalance(0)
        }
    }, [wallets, pairs])

    useEffect(() => {
        // console.log('kjdghsdjhgsdgsd',pairData.length,pairData)
        if (pairData.length > 0) {
            const filterPair = pairData.find((el)=> el.tickerRoot == localStorage.getItem('currentPair'))

            history.replace('/trade/' + filterPair.tickerRoot)
            // localStorage.setItem('currentPair', pairData[0].tickerRoot)
            Setpairs(filterPair)
            SettakeProfit(filterPair.asks)
            calculateLiqPrice(filterPair)
            props.checkIsApporvePaidToken(filterPair.secondCurrency, localStorage.getItem("accountInfo"))
            let form = { ...orderData }
            orderData.price = filterPair.asks
            orderData.currenypaid = filterPair.secondCurrency
            orderData.pairId = filterPair._id
            orderData.takePrice = filterPair.asks
            form.pairData = filterPair
            form.currenypaid = filterPair.secondCurrency
            form.pairId = filterPair._id
            form.price = marketPrice == 0 ? filterPair.bids : marketPrice
            props.onSet(form)
            setOrderData(form)
        }
    }, [pairData, props.pair])
    const onDismissorder = () => {
        SetdepositModal11(false)
    }
    const calculatePercentage = async (percentage) => {
        const tradeBalance = (balance / 100) * percentage;
        Setprincipal(tradeBalance)
        const QuantityConversion = await priceConversion(pairs.secondCurrency, pairs.firstCurrency);
         /** Start Quantity after fee */
         let initialMargin = parseFloat(tradeBalance) - openFee
         let quantityy = parseFloat(parseFloat(initialMargin) * parseFloat(leverage)).toFixed(8)
         quantityy = parseFloat(quantityy) * QuantityConversion
         setQtyAfterFee(quantityy)
         /** End  Quantity after fee*/

        Setquantity(QuantityConversion * tradeBalance)
        SetpaidCurrency(tradeBalance)
        SetleverageQuantity(QuantityConversion * (leverage > 0 ? (parseFloat(leverage) * tradeBalance) : tradeBalance))
        SetleveragePaidCurrency(leverage == 1 ? tradeBalance : parseFloat(leverage) * tradeBalance)
        let form = { ...orderData }
        form.Principal = tradeBalance
        form.quantity = QuantityConversion * tradeBalance
        props.onSet(form)
        setOrderData(form)
    }
    useEffect(()=> {
        // socketContext.socket.on("tickerPrice", (data)=> {
            if(localStorage.getItem('currentPair') == tickerPrice.tickerRoot){
                // let form = { ...orderData }
                // form.price = data.asks
                setMarketPrice(tickerPrice.asks)
                // props.onSet(form)
                // setOrderData(form)
                calculateLiqPrice(tickerPrice)
            }
        //   })
    },[tickerPrice,pairs,leverage])

    const PairChange = (e) => {
        history.replace('/trade/'+ e.tickerRoot)
        localStorage.setItem('currentPair', e.tickerRoot)
        Setpairs(e)
        Setdropdown(false)
        Setprincipal(0);
        Setleverage(1); setStopLoss(0); setTakeProfit(0); SetshowValue(false)
        props.checkIsApporvePaidToken(e.secondCurrency, localStorage.getItem("accountInfo"))
        let form = { ...orderData }
        form.pairId = e._id
        form.currenypaid = e.secondCurrency
        form.price = marketPrice
        form.Principal = 0
        form.leverage = 1
        form.quantity = 0
        props.onSet(form)
        setOrderData(form)
    }
    const calculateLiqPrice = async (data) => {
        try {
            const liquidityPrice = await liquidityCalculation(type, data.asks, data.maintananceMargin, leverage)
            // console.log(liquidityPrice, "calculateLiqPrice_short", data)
            SetliqPrice(parseFloat(liquidityPrice).toFixed(2))
        } catch (err) {
            console.log(err, "calculateLiqPrice__err")
        }
    }
    const addLeverage = async (e) => {
        const QuantityConversion = await priceConversion(pairs.secondCurrency, pairs.firstCurrency);
        const quantity = parseFloat(principal) * parseFloat(e)
        const liquidityPrice = await liquidityCalculation(type, marketPrice, pairs.maintananceMargin, e)

         /** Start Quantity after fee */
         let initialMargin = parseFloat(principal) - openFee
         let quantityy = parseFloat(parseFloat(initialMargin) * parseFloat(leverage)).toFixed(8)
         quantityy = parseFloat(quantityy) * QuantityConversion
         setQtyAfterFee(quantityy)
         /** End  Quantity after fee*/

        Setleverage(e)
        SetleveragePaidCurrency(parseFloat(principal) * parseFloat(e))
        SetleverageQuantity(parseFloat(quantity) * parseFloat(QuantityConversion))
        SetliqPrice(parseFloat(liquidityPrice).toFixed(2))
        let form = { ...orderData }
        form.leverage = e
        props.onSet(form)
        setOrderData(form)
    }

    const priceCalculation = async (e) => {
        Setprincipal(e)
        const QuantityConversion = await priceConversion(pairs.secondCurrency, pairs.firstCurrency);
        let openFee = parseFloat(e) * (parseFloat(pairs.openFee) / 100)
         /** Start Quantity after fee */
         let initialMargin = parseFloat(e) - openFee
         let quantityy = parseFloat(parseFloat(initialMargin) * parseFloat(leverage)).toFixed(8)
         quantityy = parseFloat(quantityy) * QuantityConversion
         setQtyAfterFee(quantityy)
         /** End  Quantity after fee*/
        Setquantity(QuantityConversion * e)
        SetopenFee(openFee)
        SetleverageQuantity(QuantityConversion * (leverage > 0 ? (parseFloat(leverage) * e) : e))
        SetleveragePaidCurrency(leverage == 1 ? e : parseFloat(leverage) * e)
        let form = { ...orderData }
        form.quantity = QuantityConversion * e
        form.Principal = e
        props.onSet(form)
        setOrderData(form)
    }

    const setTakeProfit = async (percentage) => {
        const takeProfitPercentage = (marketPrice * percentage) / 100
        let takeProfit = parseFloat(marketPrice) + parseFloat(takeProfitPercentage)
        SettakeProfit(takeProfit)
        let form = { ...orderData }
        form.takePrice = takeProfit
        props.onSet(form)
        setOrderData(form)
    }
    const setStopLoss = async (percentage) => {
        const stopLossPercentage = (marketPrice * percentage) / 100
        let stopLoss = parseFloat(marketPrice) - parseFloat(stopLossPercentage)
        SetstopLoss(stopLoss)
        let form = { ...orderData }
        form.stopLossPrice = stopLoss
        props.onSet(form)
        setOrderData(form)
    }

    return (

        <div className='tab_scroll_enal'>

            <Tab.Container id="left-tabs-example-inner" defaultActiveKey="market1">
                <Row>
                    <Col sm={12}>
                        <div>
                            <Nav variant="pills" className="flex-row longshortborder border_onl_teab">

                                <Nav.Item>
                                    <Nav.Link eventKey="market1" onClick={() => {
                                        let form = { ...orderData }
                                        form.ordertype = 'market'
                                        props.onSet(form)
                                        setOrderData(form)
                                    }}>Market</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="limit1" onClick={() => {
                                        let form = { ...orderData }
                                        form.ordertype = 'limit'
                                        props.onSet(form)
                                        setOrderData(form)
                                    }}>Limit</Nav.Link>
                                </Nav.Item>

                            </Nav>
                        </div>
                    </Col>
                    <Col sm={12}>
                        <Tab.Content>
                            <Tab.Pane eventKey="market1">
                                <div className='longs'>

                                    <div className='card card_table2 longborder mt-3'>
                                        <div className='card-body'>
                                            <div className='etflex'>
                                                <div>
                                                    <Dropdown >
                                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                            <img src={Die1} className='bnb' alt="drops" /> {pairs && pairs?.tickerRoot ? pairs.tickerRoot : pairData[0]?.tickerRoot}
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu id="dropdwon_menu_new"
                                                        // className={this.state.dropdown == true ? 'd-block' : "d-none"}
                                                        >
                                                            <Dropdown.Item className='inseri_drop'>
                                                                <div>
                                                                    <div className='mt-3 pb-2'>
                                                                        <div className='table-responsive'>
                                                                            {pairData && <DataTable className='table table_user table_admin mb-0'
                                                                                noHeader
                                                                                sortable={false}
                                                                                columns={columns}
                                                                                data={pairData}
                                                                                onRowClicked={PairChange}
                                                                                pagination
                                                                            />}
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>

                                                {/* <div>
                                                    <button type='button' className='btn pr-0' onClick={() => SetdepositModal11(true)}><img src={Long1} alt="drops" /></button>
                                                </div> */}

                                            </div>
                                            {depositModal11 && <Longshort connect={"string"} onDismiss={() => onDismissorder()} />}

                                            <div>
                                                <Form>
                                                    <Form.Group className="mb-3 form1 trance" controlId="formBasicEmail">
                                                        <Form.Control type="number" className='true1' placeholder="Amount" min='0' value={principal} onChange={(e) => { priceCalculation(e.target.value) }} />
                                                        <p className='mb-0 mt-0 pricename1'>{pairs ? pairs?.secondCurrency : pairData[0]?.secondCurrency}</p>
                                                    </Form.Group>
                                                </Form>
                                            </div>

                                            <div>
                                                <p className='max'>Max available balance: {balance}</p>
                                                {
                                                    principal > 0 &&
                                                    <>
                                                        <p className='max'>{pairs?.firstCurrency} {leverageQuantity?.toFixed(5)}</p>
                                                        <p className='max'>{pairs?.secondCurrency} {leveragePaidCurrency}</p>
                                                        <p className='max'>Quantity after fee {qtyAfterFee}</p>
                                                    </>
                                                }
                                            </div>
                                        </div>

                                    </div>


                                    <div className='row nobtn mt-3'>
                                        <div className='col-lg-3 col-xl-3 px-3 px-lg-1 px-xl-3 col-md-3 col-sm-3 col-6 mt-2'>
                                            <Button variant="primary" onClick={() => { calculatePercentage(10) }}>10%</Button>
                                        </div>
                                        <div className='col-lg-3 col-xl-3 px-3 px-lg-1 px-xl-3 col-md-3 col-sm-3 col-6 mt-2'>
                                            <Button variant="primary" onClick={() => { calculatePercentage(20) }}>20%</Button>
                                        </div>
                                        <div className='col-lg-3 col-xl-3 px-3 px-lg-1 px-xl-3 col-md-3 col-sm-3 col-6 mt-2'>
                                            <Button variant="primary" onClick={() => { calculatePercentage(50) }}>50%</Button>
                                        </div>
                                        <div className='col-lg-3 col-xl-3 px-3 px-lg-1 px-xl-3 col-md-3 col-sm-3 col-6 mt-2'>
                                            <Button variant="primary" onClick={() => { calculatePercentage(100) }}>Max</Button>
                                        </div>
                                    </div>

                                    <div className='etflex3 mt-3'>
                                        <div>
                                            <p>Leverage</p>
                                        </div>
                                        <div>
                                            <p>{leverage}X</p>
                                        </div>
                                    </div>

                                    <div className='slide1 pt-2 pb-3'>

                                        <Slider
                                            progress
                                            id="customSlider1"
                                            min={1}
                                            //   max={labels1.length + 1}
                                            value={leverage}

                                            onChange={v => {
                                                addLeverage(v)
                                            }}

                                        />

                                    </div>
                                    <div>
                                        <div className='mt-2'>
                                        <Form.Check // prettier-ignore
                                                type="switch"
                                                id="custom-switch-2"
                                                label="Take Profit / Stop Loss"
                                                className='cuset_seit'
                                                checked={orderData?.isTakeorStop}
                                                onChange={() => { 
                                                    let form = {...orderData}
                                                    form.isTakeorStop = !orderData?.isTakeorStop
                                                    props.onSet(form)
                                                    setOrderData(form)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className={orderData?.isTakeorStop ? 'mt-3' : 'd-none'}>
                                        <div className='mt-3'>
                                            <p className='yeelo_text_albel mb-0'>Take Profit</p>

                                            <div>
                                                <div className='row nobtn mt-0 row_new_with_inout_btn'>
                                                    <div className='col-lg-3 col-xl-3 px-3 px-lg-1 px-xl-3 col-md-3 col-sm-3 col-6 mt-2'>
                                                        <Button variant="primary" onClick={() => { setTakeProfit(10) }}>+10%</Button>
                                                    </div>
                                                    <div className='col-lg-3 col-xl-3 px-3 px-lg-1 px-xl-3 col-md-3 col-sm-3 col-6 mt-2'>
                                                        <Button variant="primary" onClick={() => { setTakeProfit(50) }}>+50%</Button>
                                                    </div>
                                                    <div className='col-lg-3 col-xl-3 px-3 px-lg-1 px-xl-3 col-md-3 col-sm-3 col-6 mt-2'>
                                                        <Button variant="primary" onClick={() => { setTakeProfit(100) }}>+100%</Button>
                                                    </div>
                                                    <div className='col-lg-3 col-xl-3 px-3 px-lg-1 px-xl-3 col-md-3 col-sm-3 col-6 mt-2'>
                                                        <Button variant="primary" onClick={() => { setTakeProfit(300) }}>+300%</Button>
                                                    </div>

                                                    <div className='col-lg-3 col-xl-3 px-3 px-lg-1 px-xl-3 col-md-3 col-sm-3 col-6 mt-2'>
                                                        <input type="number" variant="primary" placeholder='Price' value={takeProfit} onChange={(e) => { 
                                                            let form = { ...orderData }
                                                            form.takePrice = e.target.value
                                                            props.onSet(form)
                                                            SettakeProfit(e.target.value)
                                                            setOrderData(form)
                                                            }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className='mt-1'>
                                            <p className='yeelo_text_albel mb-0'>Stop Loss</p>

                                            <div>
                                                <div className='row nobtn mt-0 row_new_with_inout_btn'>
                                                    <div className='col-lg-3 col-xl-3 px-3 px-lg-1 px-xl-3 col-md-3 col-sm-3 col-6 mt-2'>
                                                        <Button variant="primary" onClick={() => { setStopLoss(0) }}>None</Button>
                                                    </div>
                                                    <div className='col-lg-3 col-xl-3 px-3 px-lg-1 px-xl-3 col-md-3 col-sm-3 col-6 mt-2'>
                                                        <Button variant="primary" onClick={() => { setStopLoss(25) }}>-25%</Button>
                                                    </div>
                                                    <div className='col-lg-3 col-xl-3 px-3 px-lg-1 px-xl-3 col-md-3 col-sm-3 col-6 mt-2'>
                                                        <Button variant="primary" onClick={() => { setStopLoss(50) }}>-50%</Button>
                                                    </div>
                                                    <div className='col-lg-3 col-xl-3 px-3 px-lg-1 px-xl-3 col-md-3 col-sm-3 col-6 mt-2'>
                                                        <Button variant="primary" onClick={() => { setStopLoss(70) }}>-300%</Button>
                                                    </div>

                                                    <div className='col-lg-3 col-xl-3 px-3 px-lg-1 px-xl-3 col-md-3 col-sm-3 col-6 mt-2'>
                                                        <input type="number" variant="primary" placeholder='Price' value={stopLoss} onChange={(e) => { 
                                                             let form = { ...orderData }
                                                             form.stopLossPrice = e.target.value
                                                             props.onSet(form)
                                                            SetstopLoss(e.target.value)
                                                            setOrderData(form)
                                                            }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='etflex3 mt-3'>
                                        <div>
                                            <p>Entry Price
                                                {/* <span className='span_small_val'>(0.01% Spread)</span> */}
                                                </p>
                                        </div>
                                        <div>
                                            <p>{marketPrice ? marketPrice : 0} 
                                            {/* USD */}
                                            </p>
                                        </div>
                                    </div>


                                    <div className='etflex3 mt-1'>
                                        <div>
                                            <p>Liq. Price
                                                <span className='span_small_val'>-({pairs?.maintananceMargin}% Margin)</span></p>
                                        </div>
                                        <div>
                                            <p>{liqPrice} 
                                            {/* USD */}
                                            </p>
                                        </div>
                                    </div>


                                    <div className='etflex3 mt-1'>
                                        <div>
                                            <p>Fee
                                            </p>
                                        </div>
                                        <div>
                                            <p>{openFee ? openFee : 0}
                                            </p>
                                        </div>
                                    </div>
                                    {/* <p className='estprice'>Est. Price</p> */}

                                    <div className='etflex3 mt-1 align-items-center'>
                                        <div>
                                            <p className='mb-0'>Slippage Tolerance</p>
                                        </div>
                                        <div>

                                        <Form>
                                                    <Form.Group className="mb-3 form1 trance forn_slip_new" controlId="formBasicEmail">
                                                        <Form.Control type="number" className='true1' min='0' placeholder="Amount" value={orderData?.slippage}
                                                        onChange={(e) => {
                                                            if (e.target.value > pairs?.slippage) {
                                                                toastAlert('error', 'Slippage percentage is show high')
                                                                let form = { ...orderData }
                                                                form.slippage = 0
                                                                props.onSet(form)
                                                                setOrderData(form)
                                                            } else {
                                                                let form = { ...orderData }
                                                                form.slippage = parseFloat(e.target.value)
                                                                props.onSet(form)
                                                                setOrderData(form)
                                                            }
                                                        }
                                                        } 
                                                        />
                                                        <p className='mb-0 mt-0 pricename1'>%</p>
                                                    </Form.Group>
                                                </Form>
                                            {/* <p>{pairs?.slippage} %</p> */}
                                        </div>
                                    </div>

{/*                                     
                                    <div className='etflex3 mt-1'>
                                        <div>
                                            <p>Slippage Tolerance</p>
                                        </div>
                                        <div>
                                            <p>28.3</p>
                                        </div>
                                    </div> */}


                                </div>
                            </Tab.Pane>


                            <Tab.Pane eventKey="limit1">
                                <div className='longs'>
                                    {/* {console.log(pairs, "pairs.tickerRoot")} */}
                                    <div className='card card_table2 longborder mt-3'>
                                        <div className='card-body'>
                                            <div className='etflex'>
                                                <div>
                                                    <Dropdown >
                                                        <Dropdown.Toggle variant="success" id="dropdown-basic"

                                                            onChange={() => {
                                                                console.log('dropdown', dropdown);
                                                                // document.getElementById("#dropdwon_menu_new").classList.add("show");
                                                                // this.setState({dropdown: true}
                                                                //     )
                                                            }}
                                                        >
                                                            <img src={Die1} className='bnb' alt="drops" /> {pairs?.tickerRoot && pairs?.tickerRoot ? pairs.tickerRoot : pairData[0]?.tickerRoot}
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu id="dropdwon_menu_new"

                                                        // className={this.state.dropdown == true ? 'd-block' : "d-none"}
                                                        >
                                                            <Dropdown.Item className='inseri_drop'>
                                                                <div>


                                                                    <div className='mt-3 pb-2'>
                                                                        <div className='table-responsive'>
                                                                            {pairData && <DataTable className='table table_user table_admin mb-0'
                                                                                noHeader
                                                                                sortable={false}
                                                                                columns={columns}
                                                                                data={pairData}
                                                                                onRowClicked={PairChange}
                                                                                pagination
                                                                            />}
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>

                                                {/* <div>
                                                    <button type='button' className='btn pr-0' onClick={() => SetdepositModal11(true)}><img src={Long1} alt="drops" /></button>
                                                </div> */}

                                            </div>
                                            {depositModal11 && <Longshort connect={"string"} onDismiss={() => onDismissorder()} />}
                                            {/* <div className='etflex1'>
        <div>
            <p>Amount</p>
        </div>
        <div>
            <p>BUSD</p>
        </div>
    </div> */}
                                            <div>
                                                <Form>
                                                    <Form.Group className="mb-3 form1 trance" controlId="formBasicEmail">
                                                        <Form.Control type="number" className='true1' placeholder="Amount" value={principal} min='0' onChange={(e) => { priceCalculation(e.target.value) }} />
                                                        <p className='mb-0 mt-0 pricename1'>{pairs ? pairs?.secondCurrency : pairData[0]?.secondCurrency}</p>
                                                    </Form.Group>
                                                </Form>
                                            </div>

                                            <div>
                                                <p className='max'>Max available balance: {balance}</p>
                                                {
                                                    principal > 0 &&
                                                    <>
                                                        <p className='max'>{pairs?.firstCurrency} {leverageQuantity?.toFixed(5)}</p>
                                                        <p className='max'>{pairs?.secondCurrency} {leveragePaidCurrency}</p>
                                                        <p className='max'>Quantity after fee {qtyAfterFee}</p>
                                                    </>
                                                }
                                            </div>
                                        </div>

                                    </div>


                                    <div className='row nobtn mt-3'>
                                        <div className='col-lg-3 col-xl-3 px-3 px-lg-1 px-xl-3 col-md-3 col-sm-3 col-6 mt-2'>
                                            <Button variant="primary" onClick={() => { calculatePercentage(10) }}>10%</Button>
                                        </div>
                                        <div className='col-lg-3 col-xl-3 px-3 px-lg-1 px-xl-3 col-md-3 col-sm-3 col-6 mt-2'>
                                            <Button variant="primary" onClick={() => { calculatePercentage(20) }}>20%</Button>
                                        </div>
                                        <div className='col-lg-3 col-xl-3 px-3 px-lg-1 px-xl-3 col-md-3 col-sm-3 col-6 mt-2'>
                                            <Button variant="primary" onClick={() => { calculatePercentage(50) }}>50%</Button>
                                        </div>
                                        <div className='col-lg-3 col-xl-3 px-3 px-lg-1 px-xl-3 col-md-3 col-sm-3 col-6 mt-2'>
                                            <Button variant="primary" onClick={() => { calculatePercentage(100) }}>Max</Button>
                                        </div>
                                    </div>

                                    <div className='etflex3 mt-3'>
                                        <div>
                                            <p>Leverage</p>
                                        </div>
                                        <div>
                                            <p>{leverage}X</p>
                                        </div>
                                    </div>

                                    <div className='slide1 pt-2 pb-3'>

                                        <Slider
                                            progress
                                            id="customSlider1"
                                            min={1}
                                            //   max={labels1.length + 1}
                                            value={leverage}

                                            onChange={v => {
                                                addLeverage(v)
                                            }}

                                        />
                                        {/* <Slider
    id="customSlider1"
    min={1}
    max={labels1.length + 1}
    value={leverage}
    className="custom-slider mx-2"
    handleStyle={handleStyle1}
    graduated
    progress
                              
    onChange={v => {
      this.addLeverage(v)
    }}
    renderMark={mark => {
        return mark + "x";
    }}
/> */}
                                    </div>


                                    <div className='card card_table2 longborder mt-3'>
                                        <div className='card-body'>
                                            <div className='etflex'>
                                                <div>
                                                    <p className='yeelo_text_albel mb-0'>Limit Price</p>

                                                </div>

                                                {/* <div>
                                                    <p className='max mb-0'>Trigger Price: 25679.1</p>

                                                </div> */}

                                            </div>

                                            <div>
                                                <Form>
                                                    <Form.Group className="mb-3 form1 trance" controlId="formBasicEmail">
                                                        <Form.Control type="number" className='true1' placeholder="Limit Price" value={orderData.price} onChange={(e)=> {
                                                             let form = {...orderData}
                                                             form.price = e.target.value
                                                             props.onSet(form)
                                                             setOrderData(form)
                                                        }}
                                                        />
                                                        <p className='mb-0 mt-0 pricename1'>USD</p>
                                                    </Form.Group>
                                                </Form>
                                            </div>

                                            <div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='mt-2'>
                                        <Form.Check // prettier-ignore
                                            type="switch"
                                            id="custom-switch4"
                                            label="Take Profit / Stop Loss"
                                            className='cuset_seit'
                                            checked={orderData?.isTakeorStop}
                                            onChange={() => { 
                                                let form = {...orderData}
                                                form.isTakeorStop = !orderData?.isTakeorStop
                                                props.onSet(form)
                                                setOrderData(form)
                                             }}
                                        />
                                    </div>
                                    <div className={orderData?.isTakeorStop ? 'mt-3' : 'd-none'}>

                                        <div className='mt-3'>
                                            <p className='yeelo_text_albel mb-0'>Take Profit</p>

                                            <div>
                                                <div className='row nobtn mt-0 row_new_with_inout_btn'>
                                                    <div className='col-lg-3 col-xl-3 px-3 px-lg-1 px-xl-3 col-md-3 col-sm-3 col-6 mt-2'>
                                                        <Button variant="primary" onClick={() => { setTakeProfit(10) }}>+10%</Button>
                                                    </div>
                                                    <div className='col-lg-3 col-xl-3 px-3 px-lg-1 px-xl-3 col-md-3 col-sm-3 col-6 mt-2'>
                                                        <Button variant="primary" onClick={() => { setTakeProfit(10) }}>+50%</Button>
                                                    </div>
                                                    <div className='col-lg-3 col-xl-3 px-3 px-lg-1 px-xl-3 col-md-3 col-sm-3 col-6 mt-2'>
                                                        <Button variant="primary" onClick={() => { setTakeProfit(10) }}>+100%</Button>
                                                    </div>
                                                    <div className='col-lg-3 col-xl-3 px-3 px-lg-1 px-xl-3 col-md-3 col-sm-3 col-6 mt-2'>
                                                        <Button variant="primary" onClick={() => { setTakeProfit(10) }}>+300%</Button>
                                                    </div>

                                                    <div className='col-lg-3 col-xl-3 px-3 px-lg-1 px-xl-3 col-md-3 col-sm-3 col-6 mt-2'>
                                                        <input type="number" variant="primary" placeholder='Price' value={takeProfit} onChange={(e) => { 
                                                            let form = { ...orderData }
                                                            form.takePrice = e.target.value
                                                            props.onSet(form)
                                                            SettakeProfit(e.target.value)
                                                            setOrderData(form)
                                                            }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className='mt-3'>
                                            <p className='yeelo_text_albel mb-0'>Stop Loss</p>

                                            <div>
                                                <div className='row nobtn mt-0 row_new_with_inout_btn'>
                                                    <div className='col-lg-3 col-xl-3 px-3 px-lg-1 px-xl-3 col-md-3 col-sm-3 col-6 mt-2'>
                                                        <Button variant="primary" onClick={() => { setStopLoss(0) }}>None</Button>
                                                    </div>
                                                    <div className='col-lg-3 col-xl-3 px-3 px-lg-1 px-xl-3 col-md-3 col-sm-3 col-6 mt-2'>
                                                        <Button variant="primary" onClick={() => { setStopLoss(0) }}>-25%</Button>
                                                    </div>
                                                    <div className='col-lg-3 col-xl-3 px-3 px-lg-1 px-xl-3 col-md-3 col-sm-3 col-6 mt-2'>
                                                        <Button variant="primary" onClick={() => { setStopLoss(0) }}>-50%</Button>
                                                    </div>
                                                    <div className='col-lg-3 col-xl-3 px-3 px-lg-1 px-xl-3 col-md-3 col-sm-3 col-6 mt-2'>
                                                        <Button variant="primary" onClick={() => { setStopLoss(0) }}>-300%</Button>
                                                    </div>

                                                    <div className='col-lg-3 col-xl-3 px-3 px-lg-1 px-xl-3 col-md-3 col-sm-3 col-6 mt-2'>
                                                        <input type="number" variant="primary" placeholder='Price' value={stopLoss} onChange={(e) => { 
                                                             let form = { ...orderData }
                                                             form.stopLossPrice = e.target.value
                                                             props.onSet(form)
                                                            SetstopLoss(e.target.value)
                                                            setOrderData(form)
                                                            }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className='etflex3 mt-3'>
                                        <div>
                                            <p>Liq. Price
                                                <span className='span_small_val'>(-90% Margin)</span></p>
                                        </div>
                                        <div>
                                            <p>{liqPrice} USD

                                            </p>
                                        </div>
                                    </div>

                                    <div className='etflex3 mt-1'>
                                        <div>
                                            <p>Fee
                                            </p>
                                        </div>
                                        <div>
                                            <p>{openFee ? openFee : 0}
                                            </p>
                                        </div>
                                    </div>
                                    {/* <p className='estprice'>Est. Price</p> */}
                                </div>
                            </Tab.Pane>

                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>

        </div>

    );
}
export default Short