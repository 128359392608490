// import package
import React, { useEffect, useContext } from 'react';
import { getAssetData } from './components/action/user';
import { useSelector, useDispatch } from 'react-redux';
import { createSocketUser } from './config/socketConnectivity';
import { WALLET_ADDRESS } from './redux/action';
const HelperRoute = () => {
    const dispatch = useDispatch();
    const {walletAddress} = useSelector(state => state.Auth);
    useEffect(()=>{
        if(localStorage.getItem('accountInfo')){
            getAssetData({walletAddress : localStorage.getItem('accountInfo')},dispatch)
        }
        createSocketUser(localStorage.getItem('accountInfo'))
    },[localStorage.getItem("accountInfo"),walletAddress])

    return <div />
}

export default HelperRoute;