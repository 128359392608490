import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Tabs, Tab } from 'react-bootstrap';
import '../../css/styles.css';

import Adminheader from '../Adminheader';
import Adminsidebar from '../Adminsidebar';
import Footer from '../Footer';

import Leveragemodal from "./Leveragemodal";
import CurrencyModel from "./CurrencyModel"
import Announcementmodal2 from "../../components/Announcementmodal2";

import whitelist from "../../images/whitelist.png"
import users from "../../images/users.png"

import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import { data } from "./data";
import { getTradeHistory } from '../action/admin';


const TradeHistory = () => {

    const [leverageModal, setLeverageModal] = useState(false)
    const [announcementModal2, setAnnouncementModal2] = useState(false)
    const [tradeHistory, settradeHistory] = useState([])

    const onDismissleverage = () => { setLeverageModal(false) }
    const onDismiss1 = () => { setAnnouncementModal2(false) }
    const columns = [
        {
            name: "Contract",
            selector: "pairId.tickerRoot",
            sortable: true,
            cell: (record) => {return record.pairId.tickerRoot}
        },
        // {
        //     name: "Buy Wallet",
        //     selector: "user",
        //     sortable: true
        // },
        // {
        //     name: "Sell Wallet",
        //     selector: "amount",
        //     sortable: true
        // },
        {
            name: "Filled Type",
            selector: "status",
            sortable: true,
            cell: (record) => {return 'Trade'}
        },
        {
            name: "Quantity",
            selector: "quantity",
            sortable: true,
            cell: (record) => {return record.quantity}
        },
        {
            name: "Order Value",
            selector: "orderValue",
            sortable: true,
            cell: (record) => {return record.orderValue}
        },
        {
            name: "Fee",
            selector: "openFee",
            sortable: true,
            cell: (record) => {return record.openFee}
        },
        {
            name: "Remaining",
            selector: "balanceQuantity",
            sortable: true,
            cell: (record) => {return record.balanceQuantity}
        },
        {
            name: "Order Type",
            selector: "type",
            sortable: true,
            cell: (record) => {return record.type}
        },
        {
            name: "#",
            selector: "orderId",
            sortable: true,
            cell: (record) => {return record.orderId}
        },
        {
            name: "Date",
            selector: "createdDate",
            sortable: true,
            cell: (record) => {return new Date(record.createdDate).toDateString()}
        }
    ];


    useEffect(() => {
        getHistory()
    }, [])

    const getHistory = async () => {
        const getData = await getTradeHistory();
        console.log("getDatagetDatagetDatagetDatagetDatagetDatagetData",getData)
        settradeHistory(getData)
    }
    return (
        <div>
            <Adminheader />
            <div className='row main_view mx-0 w-100'>
                <div className='col-1 col_sidebar'>
                    <Adminsidebar />
                </div>
                <div className='col-11 main_dash_sec pb-4 col_11_admin'>
                    <div className='row'>
                        <div className='col-12 col-md-10 col-lg-9 mx-auto'>
                            <div className='mt_main_sec text-center'>
                                <button type="button" className="get-started-btn btn_head mb-4 ml-3">
                                    Trade History</button>
                            </div>
                            <div className='mt_main_sec'>
                                {/* <div className='text-right mt-5 mb-5'>
                                    <button className="get-started-btn text-uppercase" onClick={() => {setLeverageModal(true)}}>
                                        Add Leverage</button>
                                </div> */}
                                <div className='mt-3 pb-5'>
                                    <div className='table-responsive'>

                                        <DataTable className='table table_user table_admin mb-0'
                                            noHeader
                                            sortable={false}
                                            columns={columns}
                                            data={tradeHistory}
                                            pagination
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TradeHistory