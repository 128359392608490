import { ADDRESS } from '../action';

const initialState = {
    address: ""
};

const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADDRESS:
            return { ...state, address: action.payload };
            break;
        default:
            return state;
    }
};


export default adminReducer