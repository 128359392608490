import React, { Component } from 'react';
import { useContext, useEffect, useState } from "react";

import { Link } from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';
import Announcementmodal from "./Announcementmodal";
import Announcementmodal2 from "./Announcementmodal2";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// import 'font-awesome/css/font-awesome.min.css';

import veg1 from "../images/1.png";
import veg2 from "../images/2.png";
import veg3 from "../images/3.png";
import veg4 from "../images/4.png";

import bannerimg from "../images/banner123.png"
import burger from "../images/burger.png"
import logo from "../images/conte-logo.png"
// import logo1 from "../images/logo2.png"
import logo1 from "../images/new_defi2.png"
import globe from "../images/earth.png"
import moon from "../images/moon.png"
import rocketfoot from "../images/content-logo.png"

import rocket from "../images/falling1.png"
import fall from "../images/fly.png"
import smalllogo from "../images/smalllogo.png"
import flytoy from "../images/banner-image.png"

import Cheese from "../images/1.png"

import '../css/styles.css';
import { getCms } from './action/admin';




const Landing = (props) => {



    const showMobile = () => {

        //  document.getElementById("mobile_nav_item").classList.toggle('active');
        document.getElementById("mobile_nav_item").classList.toggle("left_stye");
        document.getElementById("mobileLayer").classList.toggle('active');
        document.getElementById("burger").classList.toggle('clicked');
        document.getElementById("burger").classList.toggle("burger_stye")

    }

    const [responsive1, setResponsive1] = useState({
        0: {
            items: 1,
        },
        424: {
            items: 1,
        },
        576: {
            items: 2,
        },
        768: {
            items: 3,
        },
        1200: {
            items: 4,
        },
    })
    const [announcementModal2, setAnnouncementModal2] = useState(localStorage.getItem("accountInfo") != "" ? false : true);
    const [announcementModal, setAnnouncementModal] = useState(false);
    const [cmsData, setCmsData] = useState([]);

    const [header, setHeader] = useState();
    const [sub, setSub] = useState();
    const [sub1, setSub1] = useState();
    const [productService, setProductService] = useState();
    const [roadmap, setRoadmap] = useState();
    const [ido, setIdo] = useState();

    const onDismiss = () => {
        // this.setState({ announcementModal: false });
        setAnnouncementModal(false)
    }
    const onDismiss1 = () => {
        // this.setState({ announcementModal2: false });
        // this.setState({ announcementModal: true });
        setAnnouncementModal2(false)
        setAnnouncementModal(true)
    }

    const getCmsData = async () => {
        try {
            const getData = await getCms('exchange');
            console.log("cmsData_data", getData);
            if (getData.length > 0) {
                setCmsData(getData);
                const headerCms = getData.find((el) => el.identifier == "Header");
                setHeader(headerCms);
                const subCms = getData.find((el) => el.identifier == "Sub");
                setSub(subCms)
                const ProductAndServicesCms = getData.find((el) => el.identifier == "ProductAndServices");
                setProductService(ProductAndServicesCms)
                const roadmapCms = getData.find((el) => el.identifier == "Roadmap");
                setRoadmap(roadmapCms)
                const sub1Cms = getData.find((el) => el.identifier == "Sub1");
                setSub1(sub1Cms)
                const idoCms = getData.find((el) => el.identifier == "IdoDetails");
                setIdo(idoCms)
            }
        } catch (e) {
            console.log("cmsData_err", e);
        }
    }

    useEffect(() => {
        getCmsData()
        window.addEventListener('scroll', function () {
            if (window.pageYOffset > 100) {
                document.getElementById("header").classList.add('header-scrolled');
            }
            else {
                document.getElementById("header").classList.remove('header-scrolled');
            }
        });
    }, [])

    return (
        cmsData && cmsData.length > 0 ?
            <>
                <div>
                    <div className='banner_sec'>
                        <div className='overlay_banner'></div>
                        <Header />
                        <div className='pb-5'>
                            <div className="container container_custom">
                                <div className='whole_sec'>
                                    <div className='row'>
                                        <div className='col-12 col-md-7 col-lg-6 col_banner_sec'>
                                            <p className='text_red font_weight_800 text-uppercase font_28 mb-0 banner_title_1'>{header?.data?.heading}</p>
                                            <p className='text_yellow font_weight_800 text-uppercase font_35 banner_title_2'>{header?.data?.subheading.slice(0, -3)}<span className='text_red font_weight_700 text-uppercase'>{header?.data?.subheading.slice(-3)} </span></p>
                                            <p className='banner_desc mb-4'>{header?.data?.content}</p>
                                            <div className='d-flex'>
                                                <Link to="/trade/:tikerRoot?"><button className='btn btn_red mr-3'>Trading</button></Link>
                                                {/* <button className='btn btn_white_outline'>Join Telegram</button> */}
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-5 col-lg-6 mt-5 mt-md-0 img_sec_ban position-relative'>
                                            <img src={bannerimg} className="img-fluid animate_plate" />
                                        </div>
                                    </div>

                                </div>
                            </div>



                        </div>
                    </div>
                    <div className='second_sec pt-3 pb-5'>
                        <div className="container container_custom  pb-5">
                            <div className='text-center'>
                                <button className="get-started-btn text-uppercase btn_head mb-4">
                                    IDO Details</button>
                            </div>
                            <div className='row mt-5 justify-content-center'>
                                {
                                    ido != undefined && ido?.data?.data?.map((value) => (
                                        <div className='col-12 col-md-6 col-lg-6 mb-5 pr-lg-4'>
                                            <div className='card card_service h-100'>

                                                <div className='card-body py-0'>
                                                    {/* <badge className="badge badge_grey">Margin Trading</badge> */}
                                                    <p class="mt-0 text_red font_14 font_weight_800 mb-0 let_space_sm">{value?.heading}</p>
                                                    <p class="mt-0 text_yellow font_22 font_weight_800 text-uppercase mb-0 let_space">{value?.subheading}</p>
                                                    <p className='card_desc mt-4 text-left'>{value?.content}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                {/*
                        {/* <div className='col-12 col-md-6 col-lg-6 mb-5 pl-lg-4'>
                            <div className='card card_service h-100'>
                                <div className='card-body py-0'>
                                    <badge className="badge badge_grey">Lorem Ipsum</badge>
                                    <p class="mt-0 text_red font_14 font_weight_800 mb-0 let_space_sm">Lorem Ipsum is simply</p>
                                    <p class="mt-0 text_yellow font_22 font_weight_800 text-uppercase mb-0 let_space">Lorem Ipsum</p>
                                    <div className='row mt-4 sale_row'>
                                        <div className='col-12 col-sm-6'>
                                            <p className='text_yellow text-uppercase'>Seed Sale : </p>
                                        </div>
                                        <div className='col-12 col-sm-6'>
                                            <p className='text_red text-uppercase'>0.0075$ Per Token</p>
                                        </div>
                                        <div className='col-12 col-sm-6'>
                                            <p className='text_yellow text-uppercase'>Private Sale : </p>
                                        </div>
                                        <div className='col-12 col-sm-6'>
                                            <p className='text_red text-uppercase'>0.0075$ Per Token</p>
                                        </div>
                                        <div className='col-12 col-sm-6'>
                                            <p className='text_yellow text-uppercase'>Public Sale : </p>
                                        </div>
                                        <div className='col-12 col-sm-6'>
                                            <p className='text_red text-uppercase'>0.0075$ Per Token</p>
                                        </div>
                                        <div className='col-12 col-sm-6'>
                                            <p className='text_yellow text-uppercase'>Total Token Sale : </p>
                                        </div>
                                        <div className='col-12 col-sm-6'>
                                            <p className='text_red text-uppercase'>26.4 $</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                            </div>
                        </div>
                    </div>

                    <div className='third_sec py-5'>

                        <div className="container container_custom">
                            <div className='row'>
                                <div className='col-12 col-md-11 col-lg-10 mx-auto'>
                                    <p className='text_red text-uppercase font_20 let_space font_weight_900 mb-2 text-center'>{sub?.data?.heading}</p>
                                    <p className='text_yellow text-uppercase font_25 font_weight_900 text-center let_space'>{sub?.data?.subheading}</p>
                                    <p className='third_des'>{sub?.data?.content}</p>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='whole_blak_sec'>
                        <div className='fourth_sec py-5'>
                            <div className="container container_custom py-5">
                                <div className='text-center'>
                                    <button className="get-started-btn text-uppercase btn_head mb-4">
                                        Product and Services</button>
                                </div>
                                <div className='fall_sec_pro'>
                                    <img src={fall} className="img-fluid img_fall_new_four" />

                                </div>
                                <div className='rocket_sec_pro'>
                                    <img src={flytoy} className="img-fluid img_rocket_four" data-aos="new-animation" />

                                </div>
                                <div className='row mt-5 pt-5'>
                                    <div className='col-12 col-md-6 col-lg-6 mb-3 pr-lg-4'>
                                        <div className='card card_service h-100'>
                                            <div className='card-body py-0 px-0'>
                                                <div className="media service_media">
                                                    <div className='bg_icon mr-3'>
                                                        <img className="align-self-center" src={flytoy} alt="Generic placeholder image" />
                                                    </div>
                                                    <div className="media-body align-self-center">
                                                        <p className="mt-0 text_red font_14 font_weight_800 mb-0 let_space_sm">{productService?.data?.data[0].heading}</p>
                                                        <p className="mt-0 text_yellow font_22 font_weight_800 text-uppercase mb-0 let_space">{productService?.data?.data[0].subheading}</p>

                                                    </div>
                                                </div>
                                                <p className='card_ser_desc mt-4 mb-0'>{productService?.data?.data[0].content}</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className='row mt-minus'>
                                    <div className='col-12 col-md-6 col-lg-6 offset-md-6 mb-3 pr-lg-4'>
                                        <div className='card card_service h-100'>
                                            <div className='card-body py-0 px-0'>
                                                <div className="media service_media">
                                                    <div className='bg_icon mr-3'>
                                                        <img className="align-self-center" src={flytoy} alt="Generic placeholder image" />
                                                    </div>
                                                    <div className="media-body align-self-center">
                                                        <p className="mt-0 text_red font_14 font_weight_800 mb-0 let_space_sm">{productService?.data?.data[1].heading}</p>
                                                        <p className="mt-0 text_yellow font_22 font_weight_800 text-uppercase mb-0 let_space">{productService?.data?.data[1].subheading}</p>

                                                    </div>
                                                </div>
                                                <p className='card_ser_desc mt-4 mb-0'>{productService?.data?.data[1].content}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row mt-minus'>

                                    <div className='col-12 col-md-6 col-lg-6 mb-3 pr-lg-4'>

                                        <div className='card card_service h-100'>
                                            <div className='card-body py-0 px-0'>
                                                <div className="media service_media">
                                                    <div className='bg_icon mr-3'>
                                                        <img className="align-self-center" src={flytoy} alt="Generic placeholder image" />
                                                    </div>
                                                    <div className="media-body align-self-center">
                                                        <p className="mt-0 text_red font_14 font_weight_800 mb-0 let_space_sm">{productService?.data?.data[2].heading}</p>
                                                        <p className="mt-0 text_yellow font_22 font_weight_800 text-uppercase mb-0 let_space">{productService?.data?.data[2].subheading}</p>

                                                    </div>
                                                </div>
                                                <p className='card_ser_desc mt-4 mb-0'>{productService?.data?.data[2].content}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row mt-minus'>

                                    <div className='col-12 col-md-6 col-lg-6 offset-md-6 mb-3 pr-lg-4'>

                                        <div className='card card_service h-100'>
                                            <div className='card-body py-0 px-0'>
                                                <div className="media service_media">
                                                    <div className='bg_icon mr-3'>
                                                        <img className="align-self-center" src={flytoy} alt="Generic placeholder image" />
                                                    </div>
                                                    <div className="media-body align-self-center">
                                                        <p className="mt-0 text_red font_14 font_weight_800 mb-0 let_space_sm">{productService?.data?.data[3].heading}</p>
                                                        <p className="mt-0 text_yellow font_22 font_weight_800 text-uppercase mb-0 let_space">{productService?.data?.data[3].subheading}</p>

                                                    </div>
                                                </div>
                                                <p className='card_ser_desc mt-4 mb-0'>{productService?.data?.data[3].content}</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>



                                <div className='fall_sec'>
                                    <img src={fall} className="img-fluid img_fall_four " />

                                </div>
                            </div>
                        </div>
                        <div className='fifth_sec py-5'>
                            <div className="container container_custom py-5">
                                <img src={rocket} className="img-fluid rocket_img_road" data-aos="new-animation-fly" />

                                <div className='text-center'>
                                    <button className="get-started-btn text-uppercase btn_head mb-4">
                                        Roadmap</button>
                                </div>
                                <div className="slider_sec_1 fullinerelative pt-lg-5">
                                    <div className='fullline'></div>
                                    {console.log("roadmap?.data?.dataroadmap?.data?.dataroadmap?.data?.data", roadmap?.data?.data)}
                                    <OwlCarousel items={3}
                                        className="owl-theme"
                                        loop={false}
                                        nav={true}
                                        margin={0} autoplay={false} responsive={responsive1} dots={false}>
                                        {
                                            roadmap != undefined && roadmap?.data?.data?.map((value, index) => (
                                                <div className={index == 1 ? 'car-item inprogress' : 'car-item completed'}>
                                                    <div>
                                                        <h6>{value?.heading}</h6>
                                                        <div className='line'></div>
                                                        <p className='time_line_road'>{value?.subheading}</p>

                                                        <p>{value?.content}</p>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>

                        <div className='sixth_sec pt-0'>


                            <img src={globe} className="img-fluid globe_img" />
                            <div className='right_img_sec'>
                                <img src={moon} className="img-fluid moon_img" />
                                <img src={rocketfoot} className="img-fluid rocket_img" data-aos="new-animation-rocket" />

                            </div>
                            <div className="container container_custom">
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-12 col-md-10 col-lg-9 offset-md-2 col-xl-7 mt-5'>
                                            <div className='mb-4'>
                                                {/* <img src={logo} className="img-fluid" /> */}
                                                <img src={logo1} className="img-fluid" />
                                            </div>
                                            <p className='footer_desc mb-4'>{sub1?.data?.content}</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <Footer />
                    </div>

                    {announcementModal && <Announcementmodal connect={"string"} onDismiss={() => onDismiss()} />}
                    {console.log(announcementModal2, announcementModal, "announcementModal2")}
                    {announcementModal2 && <Announcementmodal2 connect={"string"} onDismiss={() => onDismiss1()} />}


                </div>
            </> : <></>

    );
}


export default Landing