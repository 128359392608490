import React, { Component } from 'react';

import '../../css/styles.css';

import { Col, Row, Container, Nav, Dropdown, Tabs, Tab, Table, Button, Form } from 'react-bootstrap';

import Long1 from "../../images/long1.PNG";
import Die1 from "../../images/die1.PNG";

import { Slider } from 'rsuite';

import Longshort from './longshortprice';

// import img11 from '../../images/client1.webp';

import DataTable from 'react-data-table-component';
import { columns, data } from "./data";

class Long extends Component {

    constructor(props) {
        super(props);
        this.state = {
            depositModal11: false,
            value1: 0,
        };
    }

    onDismissorder() {
        this.setState({ depositModal11: false });
    }
    render() {

        const { depositModal11 } = this.state;
        const labels1 = ['1x', '5x', '10x', '15x', '20x'];
        // const labels2 = ['img1', 'img1', 'img1', 'img1', 'img1'];
        const { value1 } = this.state;
        const handleStyle1 = {
            color: '#fff',
            fontSize: 12,
            width: 32,
            height: 22
        };

        return (

            <div>
                <div className='longs pnllongtabs'>

                    <div className='d-flex etflex3 mt-3'>
                        <div>
                            <p>Leverage</p>
                        </div>
                        <div>
                            <p>20X</p>
                        </div>
                    </div>

                    <div className='slide1 pt-2 pb-3'>
                        <Slider
                            id="customSlider1"
                            min={2}
                            max={labels1.length + 1}
                            value={value1}
                            className="custom-slider mx-2"
                            handleStyle={handleStyle1}
                            graduated
                            progress
                            // handleTitle={labels2[value1]}                          
                            onChange={v => {
                                this.setState({ value1: v });
                            }}
                            renderMark={mark => {
                                return mark + "x";
                            }}
                        />
                    </div>

                    <p className='estprice'>Maximum position at current leverage:</p>
                    <div className='etflex3 mt-1'>
                        <div>
                            <p>500,000 USDT</p>
                        </div>
                    </div>

                    {/* <div className='card card_table2 longborder mt-3'>
                    <div className='card-body'>
                    <div className='d-flex etflex3 mt-1'>
                            <p className='result1 mb-0'>Enter price</p>
                            <p className='mb-0 mt-0'>USDT</p>
                    </div>
                    </div>
                    </div>

                    <div className='card card_table2 longborder mt-3'>
                    <div className='card-body'>
                    <div className='d-flex etflex3 mt-1'>
                            <p className='result1 mb-0'>ROE</p>
                            <p className='mb-0 mt-0'>%</p>
                    </div>
                    </div>
                    </div>

                    <div className='card card_table2 longborder mt-3'>
                    <div className='card-body'>
                    <div className='d-flex etflex3 mt-1'>
                            <p className='result1 mb-0'>ROE</p>
                            <p className='mb-0 mt-0'>%</p>
                    </div>
                    </div>
                    </div> */}

                    <div>
                        <Form>
                            <Form.Group className="mb-3 form1" controlId="formBasicEmail">
                                <Form.Control type="number" placeholder="Enter price" />
                                <p className='mb-0 mt-0 pricename'>USDT</p>
                            </Form.Group>

                            <Form.Group className="mb-3 form1" controlId="formBasicEmail">
                                <Form.Control type="number" placeholder="ROE" />
                                <p className='mb-0 mt-0 pricename'>%</p>
                            </Form.Group>
                            <Form.Group className="mb-3 form1" controlId="formBasicEmail">
                                <Form.Control type="number" placeholder="ROE" />
                                <p className='mb-0 mt-0 pricename'>%</p>
                            </Form.Group>
                        </Form>
                    </div>

                    <div className='d-flex justify-content-between mt-3'>
                        <Button className='pl-3 pr-3 pt-2 pb-2 calculate' variant="warning">Calculate</Button>
                        <Button className='pl-3 pr-3 pt-2 pb-2' variant="outline-warning">Cancel</Button>
                    </div>

                    <div>
                        <h6 className='result mt-3'>Result</h6>

                        <div className='card card_table2 longborder mt-3'>
                            <div className='card-body'>
                                <div className='d-flex etflex3 mt-1'>
                                    <p className='result1 mb-0'>Liquidation Price</p>
                                    <p className='mb-0 mt-0'>-USDT</p>
                                </div>
                            </div>
                        </div>

                        <p className='lastpara' style={{ color: "#BFBFBE" }}>Your open positions will be taken into consideration when calculating the liquidation price. Unrealized PNL and maintenance margin of your open position will affect the calculation of liquidation price.</p>

                    </div>

                </div>

            </div>

        );
    }
}

export default Long;