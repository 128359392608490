import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import '../../css/styles.css';
import { Col, Row, Container, Nav, Dropdown, Tabs, Tab, Table, Button } from 'react-bootstrap';
// import Diamond from "../../images/diamond.PNG";
import Diamond from "../../images/binance.png";
import { useDispatch, useSelector } from 'react-redux';
import { getPositionOpenOrder, closeOrder, closePositionOrder } from '../action/user';
import SocketContext from '../context/SocketContext';
import { paramsDataTypes, SALT } from '../../config/env';
import { contractParams, getWholeNumber } from '../../hooks/useUserExchange';

const Positions = (props) => {
  const dispatch = useDispatch()
  const {wallets, walletAddress} = useSelector(state => state.Auth)
  const socketContext = useContext(SocketContext)
  const [openPosition, setOpenPosition] = useState([])
  const [isProfit, setIsprofit] = useState(true);

  useEffect(() => {
    console.log("walletsthe_position",wallets)
    if(wallets && wallets.length > 0){
      getOrders()
      socketContext.socket.on("profitAndLoss", (data) => {
      // console.log("profitAndLoss_socketData", {data,walletAddress,local : localStorage.getItem("accountInfo")});
      localStorage.getItem("accountInfo") != null  ? setOpenPosition(data) : setOpenPosition([])
      });
    }else{
      setOpenPosition([])
    }
    
  }, [wallets])
  
  // useEffect(() => {
  //   socketContext.socket.on("openPositionOrders", (data) => {
  //     setOpenPosition(data)
  //   });
  // }, [])

  const getOrders = async () => {
    const getData = await getPositionOpenOrder({ walletAddress: localStorage.getItem("accountInfo") }, dispatch);
    console.log("getPositionOpenOrder", getData)
    setOpenPosition(getData)
  }

  const orderClose = async (data) => {
    // console.log('orderClose_datadata', data);
    let payload = {}
    payload.pairId = data.pairId
    payload.quantity = data.balanceQuantity
    payload.leverage = data.leverage
    payload.walletAddress = data.walletAddress
    payload.buyorsell = data.positionSide == 'short' ? 'buy' : 'sell'
    payload.orderId = data.orderId


    /** Contract-call */
    const findParamsDataTypes = paramsDataTypes.find((el) => el.type == 'position');
    console.log("findParamsDataTypes", findParamsDataTypes)
    if (findParamsDataTypes) {
      const close = await closeOrder(payload);
      console.log("Page_position", close);
      if (close?.status) {
        const profit = await getWholeNumber(parseFloat(close.data.profit), 18)
        close.data.newrecord.executeQuantity = 0
        let contractPayload = [{
          orderId: close.data.newrecord.positionId,
          positionId: data.orderId,
          pairId: data.pairId,
          executeQuantity: getWholeNumber(parseFloat(close.data.newrecord.executeQuantity), 18),
          entryPrice: getWholeNumber(close.data.entryPrice, 18),
          closePrice: getWholeNumber(close.data.closePrice, 18),
        }, close.data.closeId, profit, SALT]
        const encodePayload = await contractParams(contractPayload, findParamsDataTypes.param, 'closePosition');
        console.log("encodePayload_closed,contract", encodePayload)
        if (encodePayload) {
          const finallyClose = await closePositionOrder({ newOrder: close.data.newrecord, matchingList: close.data.orderLists, pairData: close.data.pairData, metaStatus: true })
        } else {
          const finallyClose = await closePositionOrder({ newOrder: close.data.newrecord, matchingList: close.data.orderLists, pairData: close.data.pairData, metaStatus: false })
        }
        console.log("encodePayload_market", encodePayload)
      }
    }
  }

  return (
    <div>
      <div className='tables'>
        {/* <div className='tables table_posi_minw '> */}
        <Table responsive>
          {/* <thead>
          <tr>
          <th>Contact</th>
            <th>QTY</th>
            <th>Value</th>
            <th>Entry Price</th>
            <th>Liq.Price</th>
            <th>Position margin</th>
            <th>Unrealized P&L (%)</th>
            <th>Daily realized P&L (%)</th>
            <th>TP/SL</th>
            <th>Closed by</th>
          </tr>
        </thead> */}
          <tbody>
            <tr className='colors'>
              <td>Contract</td>
              <td>Quantity</td>
              <td>Price</td>
              <td className='tab_filed_th'>Filed/Remaining</td>
              <td>TP/SL</td>
              <td>P/L</td>
              <td>Status</td>
              <td>Type</td>
              <td>Order</td>
              <td>Order time</td>
              <td>Action</td>

              {/* <td>Contact</td>
                <td>QTY</td>
                <td>Price</td>
                <td>Filed/Remaining</td>
                <td>TP/SL</td>
                <td>Type</td>
                <td>Status</td>
                <td>Order</td>
                <td>Order time</td>
                <td>Action</td>
                 */}
            </tr>
            {
              openPosition && openPosition.length > 0 ?
                openPosition.map((val, index) =>
                  <tr className='colors1'>
                    <td>
                      {/* <span><img src={Diamond} className='diamond' alt='diamond' /> </span>  */}
                      <span> {val.symbol}</span>
                    </td>
                    <td>{val.originalQuantity.toFixed(8)}</td>
                    <td>
                      <span>{val.entryPrice}</span>
                      </td>
                    <td>{val.executeQuantity.toFixed(8)}/{val.balanceQuantity.toFixed(8)}</td>
                    <td>{val.takePrice ? val.takePrice : 0} / {val.stopPrice ? val.stopPrice : 0}</td>
                   
                    <td>
                      <div  className='felx_icon_tetx_div'>
                      <span className='prof_loss_text'>
                        {
                          val.profirAndLoss > 0 ?
                        <i className='fa fa-long-arrow-up' />
                          :
                          <i className='fa fa-long-arrow-down' />

                        }

                        </span>
                      <span className={val.profirAndLoss > 0?'ml-2 text_profit':'ml-2 text_lost'}>{val.profirAndLoss.toFixed(4)}</span>
                      </div>
                      </td>
                    {/* <td>{val.profirAndLoss.toFixed(4)}</td> */}
                    <td>{val.positionStatus}</td>
                    <td>{val.positionSide}</td>
                    <td>{val.orderId}</td>
                    <td>{new Date(val.createdDate).toLocaleDateString()}</td>
                    <td> <Button variant="outline-warning" onClick={() => { orderClose(val) }}>Close</Button>{' '}</td>
                  </tr>
                )
                :
                <td colSpan={10}>
                  <p className='text-center no_font_text'>No position found</p>
                </td>
            }

          </tbody>
        </Table>
      </div>

    </div>
  );
}

export default Positions