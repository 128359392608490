import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import '../../css/styles.css';
import { Col, Row, Container, Nav, Dropdown, Tabs, Tab, Table, Button } from 'react-bootstrap';
// import Diamond from "../../images/diamond.PNG";
import Diamond from "../../images/binance.png";
import SocketContext from '../context/SocketContext';
import { useDispatch, useSelector } from 'react-redux';
import { getHistoryData } from '../action/user';


const History = (props) => {
  const dispatch = useDispatch()
  const socketContext = useContext(SocketContext)
  const [orderHistory, setOrderHistory] = useState([]);
  const [isProfit, setIsprofit] = useState(true);
  const {wallets} = useSelector(state => state.Auth)


  useEffect(() => {
    getHistory()
  }, [wallets]);


  const getHistory = async () => {
    if (wallets && wallets.length > 0) {
      const historyData = await getHistoryData({ walletAddress: localStorage.getItem("accountInfo") });
      console.log('historyData', historyData);
      setOrderHistory(historyData)
    } else {
      setOrderHistory([])
    }
  }

  useEffect(() => {
    console.log("walletsthe_position", wallets)
    if (wallets && wallets.length > 0) {
      getHistory()
      socketContext.socket.on("history", (data) => {
        // console.log("profitAndLoss_socketData", {data,walletAddress,local : localStorage.getItem("accountInfo")});
        localStorage.getItem("accountInfo") != null ? setOrderHistory(data) : setOrderHistory([])
      });
    } else {
      setOrderHistory([])
    }
  }, [wallets])

  return (
    <div>
      <div className='tables table_hist_new'>
        <Table responsive>
          <tbody>
            <tr className='colors'>
              <td>Contract</td>
              <td>Quantity</td>
              <td>Exec Price</td>
              <td>Price</td>
              <td className='tab_filed_th'>Filed/Remaining</td>
              <td>TP/SL</td>
              <td>Type</td>
              <td>buyorSell</td>
              <td>Status</td>
              <td>Order</td>
              <td>Order time</td>
              {/* <td>Action</td> */}
            </tr>
            {
              orderHistory && orderHistory.length > 0 ?
                orderHistory.map((val, index) =>
                  <tr className='colors1'>
                    <td>
                      {/* <span><img src={Diamond} className='diamond' alt='diamond' /> </span>  */}
                      <span> {val.symbol}</span>
                    </td>
                    <td>{val.executeQuantity.toFixed(8)}</td>
                    <td>{val.closePrice}</td>

                    <td>

                      <span>{val.entryPrice}</span>
                    </td>
                    {/* <td>{val.entryPrice}</td> */}
                    <td>{val.executeQuantity.toFixed(8)}/{val.balanceQuantity.toFixed(8)}</td>
                    <td>{val.takePrice} / {val.stopPrice}</td>
                    <td>{val.positionSide}</td>
                    <td>{val.positionSide == 'short' ? 'Sell' : 'Buy'}</td>
                    <td>{val.positionStatus}</td>
                    <td>{val.orderId}</td>
                    <td>{new Date(val.createdDate).toLocaleDateString()}</td>
                    {/* <td> <Button variant="outline-warning" onClick={() => { orderClose(val) }}>Close</Button>{' '}</td> */}
                  </tr>
                ) :
                <td colSpan={10}>
                  <p className='text-center no_font_text'>No records found</p>
                </td>
            }
          </tbody>
        </Table>
      </div>

    </div>
  );
}

export default History