import React from "react";
import { Link } from 'react-router-dom';



export const columns = [
  {
    key: "tickerRoot",
    name: "Symbols",
    selector: "id",
    // sortable: true,
    cell: record => { return record.tickerRoot }
  },
  {
    key: "marketPrice",
    name: "Price",
    selector: "market",
    // sortable: true,
    cell: record => { return record.marketPrice }
  },
  {
    key: "last",
    name: "24h %",
    selector: "managementfee",
    // sortable: true,
    cell: record => { return record.last }
  }
];


export const columns1 = [
  {
      name: "ID",
      selector: "id",
      sortable: true
    },
    {
      name: "User",
      selector: "user",
      sortable: true
    },
    {
      name: "Amount",
      selector: "amount",
      sortable: true
    },
    {
      name: "Status",
      selector: "status",
      sortable: true
    },
    {
      name: "Action",
      selector: "action",
      sortable: true
    }
];

export const columns2 = [
  {
    name: "ID",
    selector: "id",
    sortable: true
  },
  {
    name: "Address",
    selector: "address",
    sortable: true
  },
  {
    name: "Deposited",
    selector: "deposited",
    sortable: true
  },
  {
    name: "Borrowed",
    selector: "borrowed",
    sortable: true
  },
  {
    name: "Positions",
    selector: "positions",
    sortable: true
  }
];





export const data = [
  {
    id: 1,
    id: "BTCUSD",
    multiplier: "29,130",
    managementfee:"+1.40%"
   
  },
  {
    id: 2,
    id: "BTCUSD",
   multiplier: "29,130",
    managementfee:"+1.40%"
   
  },
  {
    id: 3,
    id: "BTCUSD",
   multiplier: "29,130",
    managementfee:"+1.40%"
   
  },
  {
    id: 4,
    id: "BTCUSD",
   multiplier: "29,130",
    managementfee:"+1.40%"
   
  },
  {
    id: 5,
    id: "BTCUSD",
   multiplier: "29,130",
    managementfee:"+1.40%"
   
  },
  {
    id: 6,
    id: "BTCUSD",
   multiplier: "29,130",
    managementfee:"+1.40%"
   
  },
  {
    id: 7,
    id: "BTCUSD",
   multiplier: "29,130",
    managementfee:"+1.40%"
   
  },
  
  
  
];

export const data1 = [
  {
    id: 1,
    user: "0xwe123..fc678",
    amount: "$2,000",     
    status:<span className="accept_desc">Active</span>,
    action: <button className='btn btn_grey'><i className="fa fa-trash" aria-hidden="true"></i></button>
   
  },
  {
    id: 2,
    user: "0xwe123..fc678",
    amount: "$2,000",     
    status:<span className="reject_desc">Disabled</span>,
    action: <button className='btn btn_grey'><i className="fa fa-trash" aria-hidden="true"></i></button>    
   
  },
  {
    id: 3,
    user: "0xwe123..fc678",
    amount: "$2,000",     
    status:<span className="accept_desc">Active</span>,
    action: <button className='btn btn_grey'><i className="fa fa-trash" aria-hidden="true"></i></button>    
   
  },
  {
    id: 4,
    user: "0xwe123..fc678",
    amount: "$2,000",     
    status:<span className="reject_desc">Disabled</span>,
    action: <button className='btn btn_grey'><i className="fa fa-trash" aria-hidden="true"></i></button>    
   
  },
  {
    id: 5,
    user: "0xwe123..fc678",
    amount: "$2,000",     
    status:<span className="accept_desc">Active</span>,
    action: <button className='btn btn_grey'><i className="fa fa-trash" aria-hidden="true"></i></button>
   
  }
  
];

export const data2 = [
    {
      id: 1,
      address: "2xwe3..678rt",
      deposited: "$2,000",  
      borrowed: "$1,000", 
      positions: "10"    
     
    },
    {
      id: 2,
      address: "2xwe3..678rt",
      deposited: "$2,000",  
      borrowed: "$1,000", 
      positions: "10"    
     
    },
    {
      id: 3,
      address: "2xwe3..678rt",
      deposited: "$2,000",  
      borrowed: "$1,000", 
      positions: "10"    
     
    },
  
    
  ];


 


