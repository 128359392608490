import React, { useState, useEffect, useRef, useContext, useMemo }from 'react';
import { Link } from 'react-router-dom';


import { Dropdown } from 'react-bootstrap';
import eth from "../images/eth.png"
import usdt from "../images/usdt.png"
import Walletmodal from './Walletmodal';
import { useDispatch } from 'react-redux';
import { WALLETS, WALLET_ADDRESS } from '../redux/action';




const Innerheader = (props) => {


  const [walletModal, setWalletModal] = useState(false)
const dispatch = useDispatch()
  const showMobile = () => {

    document.getElementById("mobile_nav_item").classList.toggle("left_stye");
    document.getElementById("mobileLayer").classList.toggle('active');
    document.getElementById("burger").classList.toggle('clicked');
    document.getElementById("burger").classList.toggle("burger_stye")

  }

  useEffect(() => {
    window.addEventListener('scroll', function () {
      if (window.pageYOffset > 100) {
        document.getElementById("header").classList.add('header-scrolled');
      }
      else {
        document.getElementById("header").classList.remove('header-scrolled');
      }
    });
  }, [])

  const onDismiss = () => { setWalletModal(false) }

  const dissconnectWallet = () =>  {
    localStorage.removeItem('accountInfo');
    localStorage.removeItem('accountBalance');
    dispatch({
      type: WALLET_ADDRESS,
      payload : '' 
    })
    dispatch({
      type: WALLETS,
      payload: [],
  })
    props.onDismiss()
  }

    return (

      <div>
        <div id="mobileLayer">
          <div id="header" className="fixed-top-navbar d-none d-md-block innerheader">
            <div className="container-fluid" id="mobileshow">
              <header className='py-1'>
                <div className='d-flex headerleft'>
                  <Link to="/" className='mr-lg-5'><span className='logo_img_inner' /></Link>
                </div>
                <div className='d-block d-lg-block web_menu'>
                  <div className="d-flex align-items-center justify-content-between py-0 header_flex">
                    <div className='d-flex align-items-center nav_parnt'>
                      <div className='nav_parnt_1'>
                      </div>
                      <div className='nav_parnt_2'>
                        <div className="nav-menu">
                          <ul className="pl-0 mb-0 header_ul_hor">
                            {/* <li className='mr-2'>
                              <img src={eth} className="img-fluid img_token" /><span className='coin_value'>{
                                  localStorage.getItem("accountBalance") == undefined ? 0 : localStorage.getItem("accountBalance")}</span>
                            </li> */}
                            {/* <li className='mr-5'>
                              <img src={usdt} className="img-fluid img_token" /><span className='coin_value'>5213</span>
                            </li> */}
                            <li>
                              <Dropdown className='btn_outline_dd get-started-btn custom_header_burger'>
                                {
                                  localStorage.getItem("accountInfo") == undefined ?
                                    <Dropdown.Toggle variant="success" id="dropdown-basic" onClick={() => { setWalletModal(true) }}>
                                      Connect Wallet
                                    </Dropdown.Toggle> :
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                      {localStorage.getItem("accountInfo").substring(0, 10)+"......"}
                                    </Dropdown.Toggle>
                                }
                                {
                                  localStorage.getItem("accountInfo") == undefined ? <></> :
                                    <Dropdown.Menu className='dd_menu_yellow'>
                                      {/* <Dropdown.Item><Link to="/position">
                                        Position</Link></Dropdown.Item>

                                      <Dropdown.Item><Link to="/lending">
                                        Lending</Link></Dropdown.Item> */}

                                      <Dropdown.Item onClick={() => { dissconnectWallet() }}>
                                        {/* <i className="fa fa-power-off mr-2" aria-hidden="true"></i> */}
                                        Logout</Dropdown.Item>

                                    </Dropdown.Menu>
                                }

                              </Dropdown>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
            </div>
          </div>
          <div id="header1" className="fixed-top-navbar d-block d-md-none">
            <div className="container-fluid  h-100" id="mobileshow1">
              <header className="h-100">
                <Link to="/" className='mr-3 logo_mob_mar'><span className='logo_img_sm' /></Link>
                <div className='d-flex align-items-center'>
                  <div className=''>
                    <div className="nav-menu">
                      <ul className="pl-0 mb-0 header_ul_hor">
                        {/* <li className='mr-2'>
                          <img src={eth} className="img-fluid img_token" /><span className='coin_value'>20</span>
                        </li>
                        <li className='mr-5'>
                          <img src={usdt} className="img-fluid img_token" /><span className='coin_value'>5213</span>
                        </li> */}
                        <li>
                          <Dropdown className='btn_outline_dd get-started-btn'>
                            {
                              localStorage.getItem("accountInfo") == undefined ?
                                <Dropdown.Toggle variant="success" id="dropdown-basic" onClick={() => { setWalletModal(true) }}>Connect Wallet</Dropdown.Toggle> :
                                <Dropdown.Toggle variant="success" id="dropdown-basic">  {localStorage.getItem("accountInfo").substring(0, 10)+"......"}</Dropdown.Toggle>
                            }

                            {

                              localStorage.getItem("accountInfo") == undefined ? <></> :
                                <Dropdown.Menu className='dd_menu_yellow'>
                                  {/* <Dropdown.Item><Link to="/position">
                                    Position</Link></Dropdown.Item>

                                  <Dropdown.Item><Link to="/lending">
                                    Lending</Link></Dropdown.Item> */}
                                  <Dropdown.Item onClick={() => { dissconnectWallet() }}>
                                    {/* <i className="fa fa-power-off mr-2" aria-hidden="true"></i> */}
                                    Logout</Dropdown.Item>

                                </Dropdown.Menu>
                            }

                          </Dropdown>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

              </header>
            </div>
          </div>
        </div>
        {walletModal && <Walletmodal connect={"string"} onDismiss={() => { props.onDismiss(); onDismiss() }} />}
      </div>
    );
  }



export default Innerheader