import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Tabs, Tab } from 'react-bootstrap';
import '../../css/styles.css';

import Adminheader from '../Adminheader';
import Adminsidebar from '../Adminsidebar';
import Footer from '../Footer';

import Featuremodal from "./Featuremodal";


import whitelist from "../../images/whitelist.png"
import users from "../../images/users.png"
import bur from "../../images/bur.png"


import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';

import { columns3, data3 } from "./data";

class Feature extends Component 
{ 
    
   
  constructor(props)
   {
       super(props);
       this.state = {
        featureModal: false
    }
   }

   onDismissfeature(){
    this.setState({ featureModal: false });
}




    render() {

       const columns3 = [
        {
          name: "Image",
          selector: "image",
          sortable: true
        },
        {
            name: "Title",
            selector: "title",
            sortable: true
          },
          {
            name: "Updated Time",
            selector: "updatedtime",
            sortable: true
          },
          {
            name: "Action",
            selector: "action",
            sortable: true
          }
      ];

       const data3 = [
        {
          id: 1,
          image:<img src={bur} className="img-fluid" />,
          title: "Title 1",
          updatedtime: "04.09.2020 23:45",     
          action:<span className='d-flex align-items-center justify-content-center'><button onClick={() => this.setState({ featureModal: true })} className='btn btn_grey mr-2 edit_btn'><i className="fa fa-pencil-square-o" aria-hidden="true"></i></button><button className='btn btn_grey'><i className="fa fa-trash" aria-hidden="true"></i></button></span>    
         
        },
        {
            id: 2,
            image:<img src={bur} className="img-fluid" />,
            title: "Title 2",
            updatedtime: "04.09.2020 23:45",     
            action:<span className='d-flex align-items-center justify-content-center'><button onClick={() => this.setState({ featureModal: true })} className='btn btn_grey mr-2 edit_btn'><i className="fa fa-pencil-square-o" aria-hidden="true"></i></button><button className='btn btn_grey'><i className="fa fa-trash" aria-hidden="true"></i></button></span>    
           
          },
      
        
      ];
      const {featureModal } = this.state
		return (
           <div>
              
              <Adminheader />
              <div className='row main_view mx-0 w-100'>
                  <div className='col-1 col_sidebar'>
                    <Adminsidebar />
                  </div>
                  <div className='col-11 main_dash_sec pb-4 col_11_admin'>
                  <div className='row'>
                      <div className='col-12 col-md-10 col-lg-9 mx-auto'>
                      <div className='mt_main_sec text-center'>
                  <button type="button" className="get-started-btn btn_head mb-4 ml-3">
                       Features</button>
                        </div>
                      <div className='mt_main_sec'>
                      <div className='text-right mt-5 mb-5'>
                    <button className="get-started-btn text-uppercase" onClick={() => this.setState({ featureModal: true })}>
                Add Feature</button>
                </div>
                      <div className='mt-3 pb-5'>
                                <div className='table-responsive'>

                                <DataTable className='table table_user table_admin mb-0'
                                noHeader
                                sortable = {false}
                                columns={columns3}
                                data={data3}
                                pagination
                                
                            
                            />
                                  
                            
</div>

                            </div>
                     
                         
                      </div>
                </div>
                </div>
                </div>
              
              </div>

{featureModal && <Featuremodal connect={"string"} onDismiss={()=>this.onDismissfeature()} /> }

           </div>
        );
    }
}

export default Feature