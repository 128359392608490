import { combineReducers } from "redux";

import authReducer from "./reducer";
import adminReducer from "./adminReducer";
import tradeReducer from "./tradeReducer";

export default combineReducers({
    Auth: authReducer,
    Admin: adminReducer,
    trade:tradeReducer
})