import React, { Component } from 'react';

import '../../css/styles.css';

import Chart from 'react-apexcharts'

class Graph extends Component {
  constructor(props) {
    super(props);

    this.state = {

      series: [{
        data: [{
          x: "20",
          y: [6629.81, 6650.5, 6623.04, 6633.33]
        },
        {
          x: "22",
          y: [6632.01, 6643.59, 6620, 6630.11]
        },
        {
          x: "24",
          y: [6630.71, 6648.95, 6623.34, 6635.65]
        },
        {
          x: "26",
          y: [6635.65, 6651, 6629.67, 6638.24]
        },
        {
          x: "28",
          y: [6638.24, 6640, 6620, 6624.47]
        },
        {
          x: "30",
          y: [6624.53, 6636.03, 6621.68, 6624.31]
        },
        {
          x: "32",
          y: [6624.61, 6632.2, 6617, 6626.02]
        },
        {
          x: "34",
          y: [6627, 6627.62, 6584.22, 6603.02]
        },
        {
          x: "36",
          y: [6605, 6608.03, 6598.95, 6604.01]
        },
        {
          x: "38",
          y: [6604.5, 6614.4, 6602.26, 6608.02]
        },
        {
          x: "40",
          y: [6608.02, 6610.68, 6601.99, 6608.91]
        },
        {
          x: "42",
          y: [6608.91, 6618.99, 6608.01, 6612]
        },
        {
          x: "44",
          y: [6612, 6615.13, 6605.09, 6612]
        },
        {
          x: "46",
          y: [6612, 6624.12, 6608.43, 6622.95]
        },
        {
          x: "48",
          y: [6623.91, 6623.91, 6615, 6615.67]
        },
        {
          x: "50",
          y: [6618.69, 6618.74, 6610, 6610.4]
        },
        {
          x: "52",
          y: [6611, 6622.78, 6610.4, 6614.9]
        },
        {
          x: "54",
          y: [6614.9, 6626.2, 6613.33, 6623.45]
        },
        {
          x: "56",
          y: [6623.48, 6627, 6618.38, 6620.35]
        },
        {
          x: "58",
          y: [6619.43, 6620.35, 6610.05, 6615.53]
        },
        {
          x: "60",
          y: [6615.53, 6617.93, 6610, 6615.19]
        },
        {
          x: "62",
          y: [6615.19, 6621.6, 6608.2, 6620]
        },
        {
          x: "64",
          y: [6619.54, 6625.17, 6614.15, 6620]
        },
        {
          x: "66",
          y: [6620.33, 6634.15, 6617.24, 6624.61]
        },
        {
          x: "68",
          y: [6625.95, 6626, 6611.66, 6617.58]
        },
        {
          x: "70",
          y: [6619, 6625.97, 6595.27, 6598.86]
        },
        {
          x: "72",
          y: [6598.86, 6598.88, 6570, 6587.16]
        },
        {
          x: "74",
          y: [6588.86, 6600, 6580, 6593.4]
        },
        {
          x: "76",
          y: [6593.99, 6598.89, 6585, 6587.81]
        },
        {
          x: "78",
          y: [6587.81, 6592.73, 6567.14, 6578]
        },
        {
          x: "80",
          y: [6578.35, 6581.72, 6567.39, 6579]
        },
        {
          x: "82",
          y: [6579.38, 6580.92, 6566.77, 6575.96]
        },
        {
          x: "84",
          y: [6575.96, 6589, 6571.77, 6588.92]
        },
        {
          x: "86",
          y: [6588.92, 6594, 6577.55, 6589.22]
        },
        {
          x: "88",
          y: [6589.3, 6598.89, 6589.1, 6596.08]
        },
        {
          x: "90",
          y: [6597.5, 6600, 6588.39, 6596.25]
        },
        {
          x: "92",
          y: [6598.03, 6600, 6588.73, 6595.97]
        },
        {
          x: "94",
          y: [6595.97, 6602.01, 6588.17, 6602]
        },
        {
          x: "96",
          y: [6602, 6607, 6596.51, 6599.95]
        },
        {
          x: "98",
          y: [6600.63, 6601.21, 6590.39, 6591.02]
        }

        ]
      }],
      
      options: {
        chart: {
          type: 'candlestick',
          height: 300
        },
        plotOptions: {
          candlestick: {
            colors: {
              upward: '#45df0f',
              downward: '#fb021a'
            }
          }
        },
        title: {
          text: 'ETH / USDT',
          align: 'left'
        },
        // xaxis: {
        //   type: 'datetime'
        // },
        yaxis:
        {
          labels: {
            show: true,
            align: 'right',
          }
        },

        tooltip: {
          enabled: true
        }

      },


    };
  }

  render() {
    return (
      <div>

        <div id="chart">
          <Chart options={this.state.options} series={this.state.series} type="candlestick" height={300} width="100%" />
        </div>
      </div>
    );
  }
}

export default Graph;