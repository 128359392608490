import React, { Component, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Tabs, Tab } from 'react-bootstrap';
import '../../css/styles.css';

import Innerheader from '../Innerheader';
import Sidebar from '../Sidebar';
import Footer from '../Footer';

import Position from './Adminpairposition';


import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';

import { columns3, data3 } from "./data";

import Announcementmodal2 from "../../components/Announcementmodal2";
import { getPerpatualPairsData } from '../action/user';
import { getPair } from '../action/admin';
import Viewmodal from '../yamada/Viewmodal';
import Adminsidebar from '../Adminsidebar';
import PairAddModel from './PairAddModal';
import PairEditModal from './PairEditModal';
import Adminheader from '../Adminheader';
const AdminPairManagment = (props) => {
    const [announcementModal2, setAnnouncementModal2] = useState(false)
    const [pairs, setPairs] = useState([])
    const [EditRecord, setEditRecord] = useState({})
    const [EditModal, SetEditModal] = useState(false)
    const [AddModal, SetAddModal] = useState(false)

    useEffect(() => {
        getPairDatas()
    }, [])

    const onDismiss1 = () => {
        setAnnouncementModal2(false)
    }
    // useEffect(() => {
    //     getPairDatas()
    // }, [viewModal])

    const getPairDatas = async () => {
        const getData = await getPerpatualPairsData();
        setPairs(getData)
    }
    const columns = [
        {
            name: "S.No",
            selector: "id",
            sortable: true,
            cell: (record, i) => { return i + 1 }
        },
        {
            name: "Base Currency",
            selector: "firstCurrency",
            sortable: true,
        },
        {
            name: "Quote Currency",
            selector: "secondCurrency",
            sortable: true,
        },
        {
            name: "Pair name",
            selector: "tickerRoot",
            sortable: true,
        },
        {
            name: "Action",
            selector: "action",
            sortable: true,
            cell: (record) => (
                <>
                    <button type="button" className="get-started-btn my-2 ml-0"
                        onClick={() => {
                            setEditRecord(record)
                            SetEditModal(true)
                        }}
                    >
                        Edit
                    </button>
                </>
            )

        }
    ];
    return (
        <div>

            {/* <Innerheader /> */}
            <Adminheader />
            {AddModal && <PairAddModel onDismiss={() => { SetAddModal(false); getPairDatas() }} />}
            {EditModal && <PairEditModal onDismiss={() => { SetEditModal(false); getPairDatas() }} record={EditRecord} />}
            <div className='row main_view mx-0'>
                <div className='col-1 col_sidebar'>
                    <Adminsidebar />
                </div>

                <div className='col-11 main_dash_sec pb-4 col_11_admin'>
                    <div className='row'>
                        <div className='col-12 col-md-10 col-lg-9 mx-auto'>
                            <div className='mt_main_sec text-center'>
                                <button type="button" className="get-started-btn btn_head mb-4 ml-3">
                                    Pair Management</button>
                            </div>
                            <div className='mt_main_sec'>
                                <div className='pair_manage_felx'>
                                    <div className="input-group input_grp_dash mt-2 input_grp_search">
                                        <input type="text" className="form-control" placeholder="Find by pair name" aria-label="Commitment" aria-describedby="basic-addon2" />
                                        <div className="input-group-append">
                                            <span className="input-group-text" id="basic-addon2">

                                                <i className="fa fa-search" aria-hidden="true"></i>

                                            </span>
                                        </div>
                                    </div>
                                    <div className='mt-5 mb-5'>
                                        <button className="get-started-btn text-uppercase" onClick={() => { SetAddModal(true) }}>
                                            Add Pair</button>
                                    </div>
                                </div>
                                {/* <div className='mt-5 mb-5'>
                                    <button className="get-started-btn text-uppercase" onClick={() => { SetAddModal(true) }}>
                                        Add Pair</button>
                                </div> */}
                            </div>

                            <div className='mt-3 pb-5'>
                                <div className='table-responsive'>

                                    <DataTable className='table table_user table_admin mb-0'
                                        noHeader
                                        sortable={false}
                                        columns={columns}
                                        data={pairs}
                                        pagination
                                    />


                                </div>

                            </div>


                            {/* <div className='mt-3 pb-5'>
                  {pairs.length > 0 && pairs.map((item, index) =>
                            <div>
                                <div className='card card_table'>
                                    <div className='card-body'>
                                        <div className='row'>
                                        
                                            <div className='col-6 col-md-4 col-lg-3 col-xl-custom'>
                                                <p className='text_yellow text-uppercase font_12 mb-0'>Asset</p>
                                                <p className='text-white text-uppercase font_12'>{item.tickerRoot}</p>
                                            </div>
                                            <div className='col-6 col-md-4 col-lg-3 col-xl-custom'>
                                                <p className='text_yellow text-uppercase font_12 mb-0'>Leverage</p>
                                                <p className='text-white text-uppercase font_12'>{item.leverage}X</p>
                                            </div>
                                            <div className='col-6 col-md-4 col-lg-3 col-xl-custom'>
                                                <p className='text_yellow text-uppercase font_12 mb-0'>CommissionFee</p>
                                                <p className='text-white text-uppercase font_12'>
                                                    {item.maintananceMargin} %
                                                  
                                                </p>
                                            </div>
                                            <div className='col-6 col-md-4 col-lg-3 col-xl-custom'>
                                                <p className='text_yellow text-uppercase font_12 mb-0'>Slippage Tolerance</p>
                                                <p className='text-white text-uppercase font_12'>{item.slippage} %</p>
                                            </div>
                                        
                                           
                                           
                                          
                                            <div className='col-12 col-md-6 col-lg-6 col-xl-custom'>
                                                <p className='text_yellow text-uppercase font_12 mb-0 d-flex'>
                                                    <a href="javascript:void(0)" onClick={() => { SetviewModal(true); Setpairdata(item) }}>Edit </a>
                                                  
                                                </p>
                                            </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <p className='text-center no_item_text d-none'>You currently have no positions.</p>

                                    {viewModal && <Viewmodal connect={"string"} data={pairData} onDismiss={() => onDismissviewmodal()} />}

                  </div> */}


                        </div>
                    </div>
                </div>
            </div>
            {/* {announcementModal2 && <Announcementmodal2 connect={"string"} onDismiss={() => onDismiss1()} />} */}
        </div>
    );
}


export default AdminPairManagment