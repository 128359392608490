import React, { useState, useEffect, useRef, useContext, useMemo }from 'react';
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Dropdown } from 'react-bootstrap';

import Walletmodal from "./Walletmodal";
import referral from "../images/referal.png";
import { WALLETS, WALLET_ADDRESS } from '../redux/action';

const  Header = (propd) =>  {
  const showMobile = ()=> {
    document.getElementById("mobile_nav_item").classList.toggle("left_stye");
    document.getElementById("mobileLayer").classList.toggle("active");
    document.getElementById("burger").classList.toggle("clicked");
    document.getElementById("burger").classList.toggle("burger_stye");
  }
  const dispatch = useDispatch()
  const [walletModal, setWalletModal] = useState(false)

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     walletModal: false,
  //   };
  // }

  useEffect(() => {
    window.addEventListener("scroll", function () {
      if (window.pageYOffset > 100) {
        document.getElementById("header").classList.add("header-scrolled");
      } else {
        document.getElementById("header").classList.remove("header-scrolled");
      }
    });
  },[])

  const onDismiss = () => {
    // this.setState({ walletModal: false });
    setWalletModal(false)
  }

  const dissconnectWallet = () => {
    localStorage.removeItem('accountInfo');
    localStorage.removeItem('accountBalance');
    dispatch({
      type: WALLET_ADDRESS,
      payload: ''
    })
    dispatch({
      type: WALLETS,
      payload: [],
    })
    // props.onDismiss()
  }
  

    return (
      <div>
        <div id="mobileLayer">
          <div id="header" className="fixed-top-navbar d-none d-md-block">
            <div className="container container_custom" id="mobileshow">
              <header className="py-3">
                <div className="d-flex headerleft">
                  <Link to="/" className="mr-lg-5">
                    <span className="logo_img_sm" />
                  </Link>
                </div>
                <div className="d-block d-lg-block web_menu">
                  <div className="d-flex align-items-center justify-content-between py-0 header_flex">
                    <div>
                      <button className="btn btn_red mr-4 header_read_more_btn">
                        READ MORE
                      </button>
                    </div>
                    <div className="d-flex align-items-center nav_parnt">
                      <div className="nav_parnt_1"></div>
                      <div className="nav_parnt_2">
                        <div className="nav-menu">
                          <ul className="pl-0 mb-0">
                            {/* {localStorage.getItem("accountInfo") ==
                            undefined ? (
                              <button
                                className="get-started-btn text-uppercase custom_header_burger"
                                onClick={() =>
                                  // this.setState({ walletModal: true })
                                  setWalletModal(true)
                                }
                              >
                                Connect Wallet
                              </button>
                            ) : (
                              <button className="get-started-btn text-uppercase">
                                {localStorage
                                  .getItem("accountInfo")
                                  .substring(0, 10) + "........"}
                              </button>
                            )} */}

<Dropdown className='btn_outline_dd get-started-btn custom_header_burger'>
                                {
                                  localStorage.getItem("accountInfo") == undefined ?
                                    <Dropdown.Toggle variant="success" id="dropdown-basic" onClick={() => { setWalletModal(true) }}>
                                      Connect Wallet
                                    </Dropdown.Toggle> :
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                      {localStorage.getItem("accountInfo").substring(0, 10)+"......"}
                                    </Dropdown.Toggle>
                                }
                                {
                                  localStorage.getItem("accountInfo") == undefined ? <></> :
                                    <Dropdown.Menu className='dd_menu_yellow'>
                                      <Dropdown.Item onClick={() => { dissconnectWallet() }}>
                                        {/* <i className="fa fa-power-off mr-2" aria-hidden="true"></i> */}
                                        Logout</Dropdown.Item>

                                    </Dropdown.Menu>
                                }

                              </Dropdown>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
            </div>
          </div>
          <div id="header1" className="fixed-top-navbar d-block d-md-none">
            <div className="container container_custom  h-100" id="mobileshow1">
              <header className="h-100">
                <Link to="/" className="mr-3 logo_mob_mar">
                  <span className="logo_img_sm" />
                </Link>
                <div className="d-flex align-items-center">
                  <div>
                    {/* <button className="btn btn_red mr-3 header_read_more_btn mbl_header_read_more_btn">
                      READ MORE
                    </button> */}
                  </div>
                  {/* <div className="">
                    <div className="nav-menu">
                      <ul className="pl-0 mb-0">
                        {localStorage.getItem("accountInfo") == undefined ? (
                          <button
                            className="get-started-btn text-uppercase"
                            onClick={() => this.setState({ walletModal: true })}
                          >
                            Connect
                          </button>
                        ) : (
                          <button className="get-started-btn text-uppercase">
                            {localStorage
                                  .getItem("accountInfo")
                                  .substring(0, 10) + "..."}
                          </button>
                        )}
                      </ul>
                    </div>
                  </div> */}
                  <Dropdown className='btn_outline_dd get-started-btn custom_header_burger'>
                                {
                                  localStorage.getItem("accountInfo") == undefined ?
                                    <Dropdown.Toggle variant="success" id="dropdown-basic" onClick={() => { setWalletModal(true) }}>
                                      Connect Wallet
                                    </Dropdown.Toggle> :
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                      {localStorage.getItem("accountInfo").substring(0, 10)+"......"}
                                    </Dropdown.Toggle>
                                }
                                {
                                  localStorage.getItem("accountInfo") == undefined ? <></> :
                                    <Dropdown.Menu className='dd_menu_yellow'>
                                      <Dropdown.Item onClick={() => { dissconnectWallet() }}>
                                        {/* <i className="fa fa-power-off mr-2" aria-hidden="true"></i> */}
                                        Logout</Dropdown.Item>

                                    </Dropdown.Menu>
                                }

                              </Dropdown>
                </div>
                {walletModal && (
                  <Walletmodal
                    connect={"string"}
                    onDismiss={() => onDismiss()}
                  />
                )}
              </header>
            </div>
          </div>
        </div>
      </div>
    )
}

export default Header;
