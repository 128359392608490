/** Packages */
import BigNumber from "bignumber.js";
import Web3 from "web3"
import toast, { Toaster } from 'react-hot-toast';

/** Files */
import { useWeb3 } from "./useWeb3";
import { EXCHANGE_MASTERCHEF_ABI, EXCHANGEMASTERCHIEF } from "../config/exchangeMasterChef";
import { UseERC20 } from "./useUserContract";



/** Exchange-Contracts-Calls */
export const UseExchangeMasterChef = async () => {
    try {
        const web3 = await useWeb3();
        const contract = new web3.eth.Contract(EXCHANGE_MASTERCHEF_ABI, EXCHANGEMASTERCHIEF);
        console.log("UseExchangeMasterChef------------>", contract.methods)
        return contract;
    } catch (e) {
        console.log('UseMasterChef_err', e);
    }
}

export const placeOrders = async (encryptData, account) => {
    try {
        const contract = await UseExchangeMasterChef();
        const data = contract.methods.openOrder(encryptData).send({ from: account })
        await toast.promise(data, {
            loading: ' Order Placing....',
            success: 'Order Placed',
            error: 'Try Again',
        }, {
            style: {
                minWidth: '300px',
                minHeight: '55px'
            }
        })
        console.log('gggggggg', data);
        return data
    } catch (e) {
        console.log('placeOrdersContractCall_err', e);
    }
}

export const closePosition = async (encryptData, account)=> {
    try {
        const contract = await UseExchangeMasterChef();
        const closePositionOrder = await contract.methods.closePostion(encryptData).send({ from: account })
        console.log('closePositionOrder_contract_call', closePositionOrder);
        return closePositionOrder
    } catch (e) {
        console.log('closePosition',e);
    }
}

/** Token-Contracts-Calls */

export const approveToken = async (account, token) => {
    try {
        console.log('approveContract', { account: account, token: token });
        const erc20Contract = await UseERC20(token);
        const data = erc20Contract.methods.approve(EXCHANGEMASTERCHIEF, "115792089237316195423570985008687907853269984665640564039457584007913129639935").send({ from: account })
        await toast.promise(data, {
            loading: 'Approving...',
            success: 'Approved Successfully',
            error: 'Try Again',
        }, {
            style: {
                minWidth: '300px',
                minHeight: '55px'
            }
        })
        return data
    } catch (e) {
        console.log('approveContract_err', e);
    }
}

export const checkIsApproved = async (account, token) => {
    try {
        const erc20contract = await UseERC20(token);
        const balance_tokensssss = await erc20contract.methods.balanceOf(account).call();
        const allow = await erc20contract.methods.allowance(account, EXCHANGEMASTERCHIEF).call();
        console.log('checkIsApproved', { account: account, token: token, allow: allow, balance_tokensssss: balance_tokensssss });
        return (parseInt(allow) > 0) ? true : false;
    } catch (e) {
        console.log('checkIsApproved_err', e);
    }
}







/** Conversions */
/** Convert Params For Contract*/
export const contractParams = async (parameterValues, parameters, type) => {
    try {
        console.log('contractParams_parms_values', parameterValues, parameters, type);
        const web3 = new Web3("https://binance.llamarpc.com");
        const accountAddress = localStorage.getItem("accountInfo")
        // Encode the parameters
        const encodedParameters = web3.eth.abi.encodeParameters(parameters, parameterValues);
        console.log('encodedParameters', encodedParameters);
        if (type == 'order') {
            const placing = await placeOrders(encodedParameters, accountAddress);
            console.log("Placing_data", placing)
            return placing != undefined ? true : false
        } else if (type == 'closePosition') {
            const close = await closePosition(encodedParameters, accountAddress);
            console.log("close_data", close)
            return close != undefined ? true : false
        }
    } catch (e) {
        console.log("contractParams_err : ", e)
    }
}

export const toFixedNumber = (x) => {
    if (Math.abs(x) < 1.0) {
        var e = parseInt(x.toString().split('e-')[1]);
        if (e) {
            x *= Math.pow(10, e - 1);
            x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
        }
    } else {
        var e = parseInt(x.toString().split('+')[1]);
        if (e > 20) {
            e -= 20;
            x /= Math.pow(10, e);
            x += (new Array(e + 1)).join('0');
        }
    }
    return x;
}

export const getWholeNumber = (value, decimal) => {
    try {
        const balance = new BigNumber(value).times(new BigNumber(10).pow(decimal));
        let finalval = balance.toNumber();
        finalval = toFixedNumber(Math.abs(finalval));
        console.log('getWholeNumber', { finalval, balance: balance.toFixed()});
        return finalval.toString();
    } catch (e) {
        console.log('getWholeNumber_err', e);
    }
}

export const getWholeNumberQuantity = (value, decimal) => {
    try {
        const balance = new BigNumber(value).times(new BigNumber(10).pow(decimal));
        let finalval = balance.toNumber();
        finalval = toFixedNumber(parseInt(finalval));
        console.log('getWholeNumber_quantity', { finalval, balance: balance.toFixed() });
        return finalval.toString();
    } catch (e) {
        console.log('getWholeNumber_err', e);
    }
}

export const randomFixedInteger = async () => {
    return "" + Math.floor(Math.pow(10, 6 - 1) + Math.random() * (Math.pow(10, 6) - Math.pow(10, 6 - 1) - 1)) + Date.now();
}