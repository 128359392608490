import React, { useState, useEffect, useRef, useContext, useMemo }from 'react';
import { Link } from 'react-router-dom';

import '../css/footer.css';
import presentation from "../images/presentation.png"
import pie from "../images/pie.png"
import info from "../images/info.png"
import docs from "../images/google-docs.png"
import deposit from "../images/deposit.png"

import Depositmodal from "../components/margintrading/Depositmodal";


const Sidebar = (props) => {
    const [depositModal, setDepositModal] = useState(false)
    
    const onDismissorder = () => { setDepositModal(false) }

        return (
            <div className='sidebar_sec' id="sidebar_scroll">

                <ul className='sidebar_ul'>
                    <li>
                        <Link to="/trade/:tickerRoot?">
                            <div>
                                <span className='icon_bg'>
                                    <img src={presentation} className="icon_sidebar" />
                                </span>
                                <span className='text_side px-2'>
                                    Margin Trading
                                </span>
                                <span className='text_side'>
                                    <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                </span>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="/lending">
                            <div>
                                <span className='icon_bg'>
                                    <img src={pie} className="icon_sidebar" />
                                </span>
                                <span className='text_side px-2'>
                                    Lending
                                </span>
                                <span className='text_side'>
                                    <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                </span>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="/position">
                            <div>
                                <span className='icon_bg'>
                                    <img src={docs} className="icon_sidebar" />
                                </span>
                                <span className='text_side px-2'>
                                    Position
                                </span>
                                <span className='text_side'>
                                    <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                </span>
                            </div>
                        </Link>

                    </li>
                    <li>
                        <Link to="/adminpairmanagment">
                            <div>
                                <span className='icon_bg'>
                                    <img src={docs} className="icon_sidebar" />
                                </span>
                                <span className='text_side px-2'>
                                    Pairmanagment
                                </span>
                                <span className='text_side'>
                                    <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                </span>
                            </div>
                        </Link>

                    </li>
                    <li className='info_li'>
                        <div className='div_first'>
                            <span className='icon_bg'>
                                <img src={info} className="icon_sidebar" />
                            </span>
                            <span className='text_side px-2'>
                                Info
                            </span>
                            <span className='text_side'>
                                <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                            </span>
                        </div>
                        <div>
                            <div className='sidebar_inner'>
                                <span className='text_sub px-2'>
                                    <a href="#" target="_blank"><img src={docs} className="icon_sidebar_new" />Lorem ipsum</a>
                                    <a href="#" target="_blank"><img src={docs} className="icon_sidebar_new" />Lorem ipsum</a>
                                    <a href="#" target="_blank"><img src={docs} className="icon_sidebar_new" />Lorem ipsum</a>
                                </span>
                            </div>
                        </div>

                    </li>
                    <li>
                        <button onClick={() => this.setState({ depositModal: true })}>
                            <div>
                                <span className='icon_bg'>
                                    <img src={deposit} className="icon_sidebar" />
                                </span>
                                <span className='text_side px-2'>
                                    Deposit
                                </span>
                                <span className='text_side'>
                                    <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                </span>
                            </div>
                        </button>
                    </li>
                </ul>

                {depositModal && <Depositmodal connect={"string"} onDismiss={() => onDismissorder()} />}

            </div>
        );
    }

export default Sidebar