/** Packages */
import React, { Component, useEffect } from 'react';
import { Link } from 'react-router-dom';

/** Files */
import useradmin from "../images/user.png"




const Adminheader = () => {

  const logout = async () => {
    try {
      localStorage.removeItem("adminLogin");
      window.location.href = window.location.origin + "/adminlogin"
    } catch (e) {
      console.log("logout_err", e);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', function () {
      if (window.pageYOffset > 100) {
        document.getElementById("header").classList.add('header-scrolled');

      }
      else {
        document.getElementById("header").classList.remove('header-scrolled');

      }
    });
  }, [])

  useEffect(() => {
    const getToken = localStorage.getItem("adminLogin");
    // console.log("getTokengetTokengetToken", getToken)
    if (getToken == "" || getToken == null || getToken == undefined) {
      window.location.href = window.location.origin + "/adminlogin"
    }
  }, [localStorage.getItem("adminLogin")])



  return (

    <div>
      <div id="mobileLayer">
        <div id="header" className="fixed-top-navbar d-none d-md-block innerheader">
          <div className="container-fluid" id="mobileshow">
            <header className='py-1'>
              <div className='d-flex headerleft'>
                <Link to="/" className='mr-lg-5'><span className='logo_img_inner' /></Link>
              </div>
              <div className='d-block d-lg-block web_menu'>
                <div className="d-flex align-items-center justify-content-between py-0 header_flex">
                  <div className='d-flex align-items-center nav_parnt'>
                    <div className='nav_parnt_1'>
                    </div>
                    <div className='nav_parnt_2'>
                      <div className="nav-menu">
                        <ul className="pl-0 mb-0 header_ul_hor pr-2">

                          <li>
                            <div className="media">
                              <img className="align-self-center mr-2 user_admin" src={useradmin} alt="User" />
                              <div className="media-body">
                                <p className="mt-0 user_name_header mb-0">Lorem Ipsum</p>
                                <p className="mb-0 logout_header mt-0 text-left"><a onClick={() => { logout() }}>Logout</a></p>
                              </div>
                            </div>

                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </header>
          </div>
        </div>
        <div id="header1" className="fixed-top-navbar d-block d-md-none">
          <div className="container-fluid  h-100" id="mobileshow1">
            <header className="h-100">
              <Link to="/" className='mr-3 logo_mob_mar'><span className='logo_img_sm' /></Link>
              <div className='d-flex align-items-center'>
                <div className=''>
                  <div className="nav-menu">
                    <ul className="pl-0 mb-0 header_ul_hor">

                      <li>
                        <div className="media">
                          <img className="align-self-center mr-2 user_admin" src={useradmin} alt="User" />
                          <div className="media-body">
                            <p className="mt-0 user_name_header mb-0">Lorem Ipsum</p>
                            <p className="mb-0 logout_header mt-0 text-left"><a href="#">Logout</a></p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

            </header>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Adminheader