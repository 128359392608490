import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

import wall1 from "../../images/metamask_wallet.png";
import wall2 from "../../images/connect_wallet.png";

import { Scrollbars } from 'react-custom-scrollbars';

class Featuremodal extends Component
{
    constructor(props) {
        super(props);
        this.state = {          
            featureModal: true,
           
        };
    }



    render() {
        
  
        const {featureModal} = this.state

        
      return (
<Modal className="wallet-modal" show={featureModal} centered size="sm" id="feature_modal">
                        <Modal.Header className="pb-0">
                            <div>
                                <h3 className="sec-head ">Add Feature</h3>
                            </div>
                            <button type="button" class="close" onClick={() => this.props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

                        </Modal.Header>
                        <Modal.Body className="select-wallet pt-0">
                        <Scrollbars className="modal_scroll_sm" style={{ height: 100 }}>
                        <form className="dashboard_form mt-4 mb-4 pad_right_das">
                                       <label>Feature Title:</label>
                                        <div className="input-group mb-3 input_grp_dash mt-2">
                                        <input type="text" className="form-control border_full" placeholder="Enter Feature Title" aria-label="featuretitle" aria-describedby="basic-addon2" />
                                        
                                        </div>

                                        <label>Feature Description:</label>
                                        <div className="input-group mb-3 input_grp_dash mt-2">
                                        <textarea rows="5" className="form-control border_full" placeholder="Enter Feature Description" aria-label="featuredescription" aria-describedby="basic-addon2" />
                                        
                                        </div>

                                        <label>Add Image:</label>
                                        <div className="custom-file mt-2">
                                        <input type="file" className="custom-file-input" id="customFile" />
                                        <label className="custom-file-label" for="customFile">Choose file</label>
                                        </div>
                                        
                                            <div className='text-center mt-5 pb-3'>
                                            <button className="get-started-btn text-uppercase">
                                        Add</button>
                                        </div>
                                        </form>
                                        </Scrollbars>
                           
                        </Modal.Body>
                    </Modal>
      )
    }

}


export default Featuremodal