import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Tabs, Tab } from 'react-bootstrap';
import '../../css/styles.css';

import Adminheader from '../Adminheader';
import Adminsidebar from '../Adminsidebar';
import Footer from '../Footer';

import Leveragemodal from "./Leveragemodal";


import Announcementmodal2 from "../../components/Announcementmodal2";

import whitelist from "../../images/whitelist.png"
import users from "../../images/users.png"

import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';

import { columns, data } from "./data";

class Leverage extends Component {



  constructor(props) {
    super(props);
    this.state = {
      leverageModal: false,
      announcementModal2: true,
    }
  }

  onDismissleverage() {
    this.setState({ leverageModal: false });
  }

 
  onDismiss1() {
    this.setState({ announcementModal2: false });
  }

  render() {
    const { leverageModal,announcementModal2 } = this.state


    return (
      <div>

        <Adminheader />
        <div className='row main_view mx-0 w-100'>
          <div className='col-1 col_sidebar'>
            <Adminsidebar />
          </div>
          <div className='col-11 main_dash_sec pb-4 col_11_admin'>
            <div className='row'>
              <div className='col-12 col-md-10 col-lg-9 mx-auto'>
                <div className='mt_main_sec text-center'>
                  <button type="button" className="get-started-btn btn_head mb-4 ml-3">
                    Leverage</button>
                </div>
                <div className='mt_main_sec'>
                  <div className='text-right mt-5 mb-5'>
                    <button className="get-started-btn text-uppercase" onClick={() => this.setState({ leverageModal: true })}>
                      Add Leverage</button>
                  </div>
                  <div className='mt-3 pb-5'>
                    <div className='table-responsive'>

                      <DataTable className='table table_user table_admin mb-0'
                        noHeader
                        sortable={false}
                        columns={columns}
                        data={data}
                        pagination


                      />


                    </div>

                  </div>


                </div>
              </div>
            </div>
          </div>

        </div>

        {leverageModal && <Leveragemodal connect={"string"} onDismiss={() => this.onDismissleverage()} />}

        {announcementModal2 && <Announcementmodal2 connect={"string"} onDismiss={() => this.onDismiss1()} />}

      </div>
    );
  }
}

export default Leverage